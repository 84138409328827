import { IconChevronLeftLine, IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';

type Props = {
  direction: 'prev' | 'next';
};

const SwiperNavigation = ({ direction }: Props) => {
  const swiper = useSwiper();

  const [navigation, setNavigation] = useState<{ prev: boolean; next: boolean }>({
    prev: false,
    next: false,
  });

  useEffect(() => {
    if (swiper) {
      const setNaviationState = () => {
        if (!swiper.slides) return;

        const lastIndex = swiper.slides.length - 1;
        const currentIndex = swiper.realIndex;

        setNavigation({
          prev: currentIndex > 0,
          next: currentIndex < lastIndex,
        });
      };

      swiper.on('slideChange', () => {
        setNaviationState();
      });

      setNaviationState();
    }

    return () => {
      swiper.off('slideChange');
    };
  }, [swiper]);

  const handleClick = () => {
    if (direction === 'prev') {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  if (!navigation[direction]) {
    return null;
  }

  return (
    <button className="flex h-full items-center justify-center" onClick={handleClick}>
      {direction === 'prev' ? (
        <IconChevronLeftLine size={16} className="text-fg-neutralSubtle flex-shrink-0" />
      ) : (
        <IconChevronRightLine size={16} className="text-fg-neutralSubtle flex-shrink-0" />
      )}
    </button>
  );
};

export default SwiperNavigation;
