import { Stack } from '@daangn/carotene';

const BenefitSection = () => {
  return (
    <Stack gap={2.5}>
      <p className="heading-small text-fg-positive">집주인 인증되면 이런 점이 좋아요!</p>
      <Stack gap={3}>
        {[
          '집주인이 직접 확인하고 인증한 매물이라 신뢰할 수 있어요',
          '집주인이 직접 인증한 매물이라 허위 매물이 아닌 점이 확인돼요',
        ].map((benefit, index) => (
          <li className="flex gap-2" key={benefit}>
            <p className="bg-bg-neutral text-fg-neutralMuted rounded-1.5 body-small-strong flex h-[24px] w-[24px] shrink-0 items-center justify-center">
              {index + 1}
            </p>
            <p className="body-medium-default text-fg-neutral">{benefit}</p>
          </li>
        ))}
      </Stack>
    </Stack>
  );
};

export default BenefitSection;
