import { LoggerEventParams } from '@/logger/eventSchema';
import logger from '@/utils/Logger';

type Props<T extends FieldNames> = {
  name: T;
  children: React.ReactNode;
  eventParams: LoggerEventParams<`${T}_field`>;
};

const FieldLogger = <T extends FieldNames>({ name, children, eventParams }: Props<T>) => {
  const handleClick = () => {
    logger.track(`${name}_field`, eventParams);
  };

  return (
    <div className="w-full" onClickCapture={handleClick}>
      {children}
    </div>
  );
};

export default FieldLogger;

export type FieldNames =
  | 'address'
  | 'address_info'
  | 'building_approval_date'
  | 'area'
  | 'supply_area'
  | 'building_usage'
  | 'content'
  | 'image'
  | 'include_manage_cost_pay_option'
  | 'include_manage_cost_option_fixed_cost'
  | 'manage_cost'
  | 'etc_manage_cost'
  | 'move_in_date'
  | 'can_move_in_now'
  | 'option'
  | 'building_orientation'
  | 'price'
  | 'monthly_pay'
  | 'adjustable'
  | 'trade_description'
  | 'qualitative'
  | 'required_option'
  | 'parking_spots'
  | 'room'
  | 'bathroom'
  | 'top_floor'
  | 'floor'
  | 'semi_basement'
  | 'sales_type'
  | 'trade_type'
  | 'video'
  | 'premium_money'
  | 'premium_money_description'
  | 'land_type'
  | 'land_purpose';
