import InfiniteLoading from '@/components/InfinteLoading';
import { config } from '@/config';
import useSearchJusoAddress, { type JusoAddressNode } from '@/hooks/useSearchJusoAddress';
import type { Coordinate } from '@/types/map';
import { Badge, Button, Divider, LinkButton } from '@daangn/carotene';
import { Spacer } from '@daangn/realty-react/ui';
import { CoordinateFinder } from '@daangn/realty-sdk';
import React from 'react';

export type JusoAddressResult = JusoAddressNode & { coordinate: Coordinate };

type Props = {
  query: string;
  onSelectItem: (address: JusoAddressResult) => void;
  onClickCorMode: () => void;
};

const coordinateFinder = new CoordinateFinder({
  kakaoMapKey: config.kakaoRestKey,
  googleMapKey: config.googleMapKey,
});

const SearchJusoAddressResult = ({ query, onSelectItem, onClickCorMode }: Props) => {
  const { data, hasNext, loadNext } = useSearchJusoAddress({
    query,
  });

  const items = data.searchAddress.edges;

  const handleSelectItem = async (node: JusoAddressNode) => {
    const coordinate = await coordinateFinder.findByAddress(node.roadAddress);

    onSelectItem({
      ...node,
      coordinate: {
        lon: coordinate[0].substring(0, 12),
        lat: coordinate[1].substring(0, 12),
      },
    });
  };

  if (items.length === 0) {
    return (
      <div className="flex flex-col gap-4 py-4">
        <div className="flex flex-col gap-1">
          <div className="heading-xsmall">검색 결과가 존재하지 않아요</div>
          <div className="body-xsmall-default text-fg-neutralMuted">
            도로명 + 건물번호 또는 동/읍/면/리 + 번지로 검색해보세요.
            <br />
            (예: 테헤란로 4길 14 또는 역삼동 825-22)
          </div>
        </div>
        <div>
          <LinkButton
            className="text-blue700"
            variant="neutral"
            size="small"
            onClick={onClickCorMode}
          >
            인터넷 등기소에서 주소 찾아보기
          </LinkButton>
        </div>
      </div>
    );
  }

  return (
    <div className="py-4">
      {hasNext && (
        <div>
          <div className="flex flex-col gap-1.5 pb-4">
            <div className="heading-xsmall">검색 결과가 너무 많아요</div>
            <div className="body-xsmall-default text-fg-neutralMuted">
              지역명/도로명 + 건물 번호(건물명)으로 검색해보세요.
            </div>
          </div>
          <Divider />
        </div>
      )}
      <div>
        {items.map(({ node }, i) => {
          return (
            <React.Fragment key={i}>
              <div className="flex gap-8 py-4">
                <div className="flex flex-col gap-3">
                  <div className="heading-xsmall">{node.zipNo}</div>
                  <div className="flex flex-col gap-2">
                    {[
                      {
                        label: '도로명',
                        value: node.roadAddress,
                      },
                      {
                        label: '지번',
                        value: node.regionAddress,
                      },
                    ].map(({ label, value }) => (
                      <div className="flex gap-2">
                        <Badge
                          tone="neutral"
                          variant="solid"
                          size="xsmall"
                          className="bg-bg-neutral text-fg-neutral min-w-[37px] shrink-0"
                        >
                          {label}
                        </Badge>
                        <div className="body-small-default">{value}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <Spacer />
                <Button
                  className="shrink-0"
                  variant="neutral"
                  onClick={() => handleSelectItem(node)}
                >
                  선택
                </Button>
              </div>
              <Divider className="block last-of-type:hidden" />
            </React.Fragment>
          );
        })}
      </div>
      <div className="py-4">
        <InfiniteLoading hasNext={hasNext} onLoadMore={() => loadNext(20)} />
      </div>
    </div>
  );
};

export default SearchJusoAddressResult;
