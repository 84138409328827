import Field from '@/routes/_protected/ceo/articles/-components/Field';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { useController } from 'react-hook-form';
import { Radio, RadioGroup } from '@daangn/carotene';
import { LandPurposes, getLandPurposeText } from '@daangn/realty-sdk';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';

const LandPurposeField = () => {
  const { control } = useArticleForm();
  const { field, fieldState } = useController({
    name: 'landPurpose',
    control,
  });

  return (
    <Field label="용도지역" {...fieldState}>
      <RadioGroup {...field} value={field.value ?? undefined} onValueChange={field.onChange}>
        <div className="grid grid-cols-3 gap-6">
          {LandPurposes.map((type) => (
            <FieldLogger key={type} name="land_purpose" eventParams={{ type }}>
              <Radio value={type} label={getLandPurposeText(type)} />
            </FieldLogger>
          ))}
        </div>
      </RadioGroup>
    </Field>
  );
};

export default LandPurposeField;
