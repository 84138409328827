import {
  useImageUpload as useCommonImageUpload,
  type UseImageUploadParams,
} from '@daangn/realty-react/hooks';
import { ChangeEventHandler } from 'react';
import { BigPictureUploader } from '@daangn/realty-sdk/services';
import { STAGE } from '@/config';
import { getAuthTokens } from '@/utils/cookie';
import { actionToast } from '@/components/DefaultToaster';
import logger from '@/utils/Logger';
import { captureException } from '@sentry/react';

type Params = Omit<UseImageUploadParams, 'bigPictureUploader' | 'uploadTarget'>;

const bigPictureUploader = new BigPictureUploader({
  stage: STAGE,
  authProvider: () => {
    const { accessToken, refreshToken } = getAuthTokens();

    return Promise.resolve({
      accessToken: accessToken ?? '',
      refreshToken: refreshToken ?? '',
    });
  },
});

const useImageUpload = (params: Params) => {
  const { handleUploadImageFiles, previewImages, isUploading } = useCommonImageUpload({
    ...params,
    bigPictureUploader,
    events: {
      ...params.events,
      onError: (error) => {
        params.events?.onError?.(error);

        const e = error.originalError ?? new Error(error.message);
        e.name = `ImageUploadError ${error.type}`;

        captureException(e, {
          level: 'log',
          extra: {
            errorType: error.type,
            errorMessage: error.message,
            errorCause: error.originalError?.cause,
          },
        });

        if (error.type === 'UNSUPPORTED_FILE_TYPE') {
          logger.track('show_image_upload_toast_error', {});
          actionToast({
            message: '지원하지 않는 파일 형식이에요.',
            action: {
              label: '가이드 보기',
              onClick: () => {
                logger.track('click_image_upload_toast_error_guide', {});
                window.open('https://www.daangn.com/wv/faqs/34');
              },
            },
          });
          return;
        }

        window.alert(error.message);
      },
    },
  });

  const handleInputFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const files = e.target.files;
    if (!files) return;
    const filesArray = Array.from(files);

    await handleUploadImageFiles(filesArray);
    e.target.value = '';
  };

  return { handleInputFileChange, previewImages, isUploading };
};

export default useImageUpload;
