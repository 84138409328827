import CommonStorage from '@/utils/storage';

const SessionStorageKey = {
  referrer: 'referrer',
} as const;

class SessionStorage extends CommonStorage<typeof SessionStorageKey> {
  constructor() {
    super('realty-web', window.sessionStorage);
  }
  setReferrer(referrer: string) {
    this.setItem('referrer', referrer);
  }
  getReferrer() {
    return this.getItem<string>('referrer');
  }
}

export const realtySessionStorage = new SessionStorage();
