// src/utils/highlight.tsx
import { Fragment } from "react";
import { escapeStringRegexp } from "@daangn/realty-sdk/utils";
import { jsx } from "react/jsx-runtime";
function replaceRegex(text, regex, option) {
  const splitted = text.split(regex);
  const template = typeof option === "function" ? option : (matchedText) => {
    const Tag = option.tag;
    return /* @__PURE__ */ jsx(Tag, { className: option.className, children: matchedText });
  };
  for (let i = 1; i < splitted.length; i += 2) {
    splitted[i] = template(splitted[i]);
  }
  return splitted.map((comp, index) => /* @__PURE__ */ jsx(Fragment, { children: comp }, index));
}
var highlightText = ({
  fullText,
  boldText,
  className,
  tag = "b"
}) => {
  if (!boldText) {
    return fullText;
  }
  const bold = boldText instanceof RegExp ? boldText : new RegExp(`(${escapeStringRegexp(boldText)})`, "gi");
  return replaceRegex(fullText, bold, {
    tag,
    className
  });
};

// src/utils/cn.ts
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export {
  replaceRegex,
  highlightText,
  cn
};
