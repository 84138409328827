import {
  VerificationStep_resendSmsMutation,
  VerificationStep_resendSmsMutation$data,
} from '@/__generated__/VerificationStep_resendSmsMutation.graphql';
import {
  VerificationStep_verifySmsMutation,
  VerificationStep_verifySmsMutation$data,
} from '@/__generated__/VerificationStep_verifySmsMutation.graphql';
import Button from '@/components/base/Button';
import { getRelayNetworkErrorHandler } from '@/relay/utils';
import { FormControl, TextField } from '@daangn/carotene';
import { secondToDuration } from '@daangn/realty-sdk';
import { intervalToDuration } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { graphql, useMutation } from 'react-relay';
import { Section, Step, VerificationData } from '../$articleId.verify';

const DEFAULT_INTERVAL = 60 * 5;
const VERIFICATION_LENGTH = 6;

type VerificationStepProps = {
  onResend: (
    response: VerificationStep_resendSmsMutation$data['resendIdentificationOtpSms']
  ) => void;
  onVerify: (
    response: VerificationStep_verifySmsMutation$data['verifyArticleByKoreaIdentification']
  ) => void;
  verificationData: VerificationData;
  articleId: string;
};

const VerificationStep = ({
  onResend,
  onVerify,
  verificationData,
  articleId,
}: VerificationStepProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [duration, setDuration] = useState(DEFAULT_INTERVAL);

  useEffect(() => {
    try {
      const { minutes = 0, seconds = 0 } = intervalToDuration({
        start: new Date(),
        end: new Date(verificationData.otpValidDuration),
      });

      setDuration(minutes * 60 + seconds || DEFAULT_INTERVAL);
    } catch (e) {
      setDuration(DEFAULT_INTERVAL);
    }

    const interval = setInterval(() => {
      setDuration((prev) => Math.max(prev - 1, 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [verificationData.otpValidDuration]);

  const [verifySms, verifySmsInFlight] = useMutation<VerificationStep_verifySmsMutation>(graphql`
    mutation VerificationStep_verifySmsMutation($input: VerifyArticleByKoreaIdentificationInput!) {
      verifyArticleByKoreaIdentification(input: $input) {
        __typename
        ... on VerifyArticleByKoreaIdentificationOutput_Result {
          result {
            article {
              id
              isWriterVerify
            }
          }
        }
        ... on VerifyArticleByKoreaIdentificationUnmatch {
          _DO_NOT_USE_THIS
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  const [resendSms, resendSmsInFlight] = useMutation<VerificationStep_resendSmsMutation>(graphql`
    mutation VerificationStep_resendSmsMutation($input: ResendIdentificationOtpSmsInput!) {
      resendIdentificationOtpSms(input: $input) {
        ... on ResendIdentificationOtpSms_Result {
          result {
            identificationVerifyRequestId
            otpValidDuration
          }
        }
        ... on ToastError {
          toastError {
            message
          }
        }
        ... on PopupError {
          popupError {
            message
          }
        }
      }
    }
  `);

  const handleChangeVerificationNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNaN(Number(value))) return;

    setError('');

    if (value.length === VERIFICATION_LENGTH) {
      handleVerify();
    }
  };

  const handleResend = () => {
    resendSms({
      variables: {
        input: {
          identificationVerifyRequestId: verificationData.identificationVerifyRequestId,
        },
      },
      onCompleted: (response) => onResend(response.resendIdentificationOtpSms),
      onError: getRelayNetworkErrorHandler(),
    });
  };

  const handleVerify = () => {
    const value = inputRef.current?.value;

    if (!value || value.length < VERIFICATION_LENGTH) {
      setError('필수 항목이에요.');
      return;
    }

    verifySms({
      variables: {
        input: {
          requestId: verificationData.identificationVerifyRequestId,
          smsOtp: value,
          articleId: articleId,
        },
      },
      onCompleted: (response) => {
        onVerify(response.verifyArticleByKoreaIdentification);
      },
      onError: getRelayNetworkErrorHandler(),
    });
  };

  return (
    <Section.Container stepId={`${Step.SmsIdentification}-verify`} articleId={articleId}>
      <Section.Header title="인증번호를 입력해 주세요" />

      <Section.Content gap={7} className="items-center">
        <FormControl
          className="w-full"
          description="어떤 경우에도 타인에게 공유하지 마세요!"
          invalid={!!error}
          errorMessage={error}
        >
          <TextField
            ref={inputRef}
            autoFocus
            placeholder="인증번호 6자리"
            type="number"
            suffix={<span className="body-medium-default">{secondToDuration(duration)}</span>}
            maxLength={VERIFICATION_LENGTH}
            onChange={handleChangeVerificationNumber}
          />
        </FormControl>

        <div className="body-small-default text-fg-neutralMuted flex gap-1 [&_button]:underline">
          <span>인증 문자를 못 받으셨나요?</span>
          <button onClick={handleResend} disabled={resendSmsInFlight}>
            다시 보내기
          </button>
        </div>
      </Section.Content>

      <Section.BottomFixed>
        <Button
          variant="brand"
          size="xlarge"
          className="w-full"
          onClick={handleVerify}
          loading={verifySmsInFlight}
        >
          확인
        </Button>
      </Section.BottomFixed>
    </Section.Container>
  );
};

export default VerificationStep;
