import type { useArticleForFormFragment_article$key } from '@/__generated__/useArticleForFormFragment_article.graphql';
import { graphql, useFragment } from 'react-relay';

const useArticleForFormFragment = (ref: useArticleForFormFragment_article$key) => {
  return useFragment<useArticleForFormFragment_article$key>(
    graphql`
      fragment useArticleForFormFragment_article on Article {
        id
        address
        addressInfo
        fullAddress
        area
        supplyArea
        bathroomCnt
        content
        coordinate {
          lat
          lon
        }
        floor
        images {
          id
          originalId
          url
        }
        floorPlanImages {
          id
          originalId
          url
        }
        moveInDate
        options {
          name
          value
        }
        corRealtyId
        roomCnt
        salesTypeV2 {
          type
          ... on OneRoomSalesType {
            subType
          }
          ... on EtcSalesType {
            etcType
          }
        }
        topFloor
        manageCost
        manageCostDescription
        includeManageCostOption
        excludeManageCostOption
        premiumMoney
        premiumMoneyDescription
        landType
        landPurpose
        trades {
          __typename
          preferred
          type

          ... on MonthTrade {
            adjustable
            deposit
            description
            monthlyPay
          }

          ... on ShortTrade {
            deposit
            description
            monthlyPay
          }

          ... on YearTrade {
            adjustable
            deposit
            description
            yearlyPay
          }

          ... on BorrowTrade {
            adjustable
            deposit
            description
          }

          ... on BuyTrade {
            price
          }
        }
        writerType
        qualitativeItems
        buildingName
        buildingOrientation
        buildingApprovalDate
        videos {
          id
          originalVideoId
          bigStreamId
          thumbnailUrl
          filename
          status {
            ... on CompleteVideoStatus {
              __typename
            }
            ... on EncodingVideoStatus {
              __typename
            }
            ... on InvalidUploadVideoStatus {
              __typename
            }
            ... on NotfoundVideoStatus {
              __typename
            }
          }
        }
        availableParkingSpotsV2
        availableTotalParkingSpots
        contactSafePhoneNumber
        contactTargetPhoneNumber
        buildingUsage
        etcManageCost
        includeManageCostOptionV2 {
          fixedCost
          option
          payOption
        }
        isUnknownManageCost
      }
    `,
    ref
  );
};

export default useArticleForFormFragment;
