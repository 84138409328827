import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { AreaUnit, formatArea, 평당_제곱미터 } from '@daangn/realty-sdk';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { IconArrowRightArrowLeftLine } from '@daangn/react-monochrome-icon';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { Button, FormControl, TextField } from '@daangn/carotene';
import { checkIsLandSalesType } from '@daangn/realty-sdk/services';

const AreaInfoField = () => {
  const { control, watch } = useArticleForm();
  const [areaUnit, setAreaUnit] = useState<AreaUnit>('m²');

  const area = useController({ control, name: 'area' });
  const supplyArea = useController({ control, name: 'supplyArea' });
  const salesType = watch('salesType');
  const etcSalesType = watch('etcSalesType');

  const isLand = checkIsLandSalesType({ salesType, etcSalesType });

  const controllers = isLand
    ? ([{ name: 'area', label: '대지 면적', controller: area }] as const)
    : ([
        { name: 'area', label: '전용 면적', controller: area },
        { name: 'supply_area', label: '공급 면적', controller: supplyArea },
      ] as const);

  const handleAreaUnitChange = () => {
    setAreaUnit(areaUnit === 'm²' ? '평' : 'm²');
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-2 gap-6">
        {controllers.map(({ name, label, controller }) => (
          <FieldLogger key={name} name={name} eventParams={{}}>
            <FormControl
              label={label}
              invalid={!!controller.fieldState.error}
              errorMessage={controller.fieldState.error?.message}
            >
              <TextField
                {...controller.field}
                value={
                  areaUnit === 'm²'
                    ? (controller.field.value ?? '')
                    : formatArea(Number(controller.field.value ?? 0) / 평당_제곱미터, '평')
                }
                onChange={(e) => {
                  const v = e.target.value;
                  if (areaUnit === 'm²') {
                    controller.field.onChange(v);
                  } else {
                    controller.field.onChange(formatArea(Number(v) * 평당_제곱미터, 'm²'));
                  }
                }}
                suffix={areaUnit}
                placeholder="0"
                type="number"
                inputMode="decimal"
              />
            </FormControl>
          </FieldLogger>
        ))}
      </div>
      <div className="flex">
        <Button
          type="button"
          size="medium"
          icon={<IconArrowRightArrowLeftLine width={16} height={16} />}
          variant="neutral"
          onClick={handleAreaUnitChange}
        >
          {areaUnit === 'm²' ? '평으로 입력하기' : '제곱미터로 입력하기'}
        </Button>
      </div>
    </div>
  );
};

export default AreaInfoField;
