import Field from '@/routes/_protected/ceo/articles/-components/Field';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { TradeTypeEnum } from '@/types/schemaEnums';
import { Checkbox } from '@daangn/carotene';
import { getArticleTradeTypeText } from '@daangn/realty-sdk';
import { useController } from 'react-hook-form';

const TradeTypes = [
  TradeTypeEnum.Month,
  TradeTypeEnum.Buy,
  TradeTypeEnum.Borrow,
  TradeTypeEnum.Year,
  TradeTypeEnum.Short,
];

const TradesField = () => {
  const { control, watch } = useArticleForm();
  const { field, fieldState } = useController({ control, name: 'trades' });

  const toggleTradeType = (type: TradeTypeEnum) => {
    const prevTradeTypes = watch('trades') ?? [];
    const isAlreadySelected = prevTradeTypes.some((t) => t.tradeType === type);

    if (isAlreadySelected) {
      field.onChange(prevTradeTypes.filter((t) => t.tradeType !== type));
    } else {
      field.onChange([...prevTradeTypes, { tradeType: type }]);
    }
  };

  return (
    <Field label="거래 방식" {...fieldState}>
      <div className="grid w-full grid-cols-6 gap-6">
        {TradeTypes.map((type) => {
          const isSelected = field.value?.map((f) => f.tradeType).includes(type);

          return (
            <FieldLogger key={type} name="trade_type" eventParams={{ type }}>
              <Checkbox
                name={field.name}
                checked={!!isSelected}
                onCheckedChange={() => toggleTradeType(type)}
                label={getArticleTradeTypeText(type)}
              />
            </FieldLogger>
          );
        })}
      </div>
    </Field>
  );
};

export default TradesField;
