import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@daangn/carotene';
import type { AddressFormState } from '@/routes/_protected/ceo/articles/-components/AddressField';
import CorAddressForm from '@/components/Article/Field/Address/CorAddressForm';
import { useState } from 'react';
import JusoAddressForm from '@/components/Article/Field/Address/JusoAddressForm';
import { SalesTypeEnum } from '@/types/schemaEnums';
import { checkIsLandSalesType } from '@daangn/realty-sdk';

type Props = {
  salesType: SalesTypeEnum;
  etcSalesType: string | null;
  onComplete: (address: AddressFormState) => void;
  onClose: () => void;
};

const SearchAddressDialog = ({ salesType, etcSalesType, onComplete, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [mode, setMode] = useState<'juso' | 'cor'>(() =>
    checkIsLandSalesType({ salesType, etcSalesType }) ? 'cor' : 'juso'
  );

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleComplete = (addressForm: AddressFormState) => {
    onComplete(addressForm);
    handleClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="flex max-h-[700px] min-h-[354px] w-[560px] flex-col overflow-auto">
        <DialogHeader className="border-b-1 border-stroke-decorative border-solid px-5">
          <div className="flex items-center justify-between">
            <DialogTitle>매물 주소</DialogTitle>
          </div>
        </DialogHeader>
        {mode === 'juso' && (
          <JusoAddressForm onComplete={handleComplete} onClickCorMode={() => setMode('cor')} />
        )}
        {mode === 'cor' && (
          <CorAddressForm
            selectedSalesType={salesType}
            onComplete={handleComplete}
            onMaintenance={handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SearchAddressDialog;
