import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { FormControl, TextField } from '@daangn/carotene';
import { useController } from 'react-hook-form';

const BuildingApprovalDate = () => {
  const { control } = useArticleForm();

  const { field, fieldState } = useController({
    control,
    name: 'buildingApprovalDate',
  });

  return (
    <div className="grid grid-cols-2 gap-6">
      <FieldLogger name="building_approval_date" eventParams={{}}>
        <FormControl
          label="사용승인일 (연식)"
          invalid={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        >
          <TextField {...field} type="date" />
        </FormControl>
      </FieldLogger>
    </div>
  );
};

export default BuildingApprovalDate;
