/**
 * @generated SignedSource<<91e8fc07da524a7a9e9a8d2355587b77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleTradeTypeAndPriceText_article$data = {
  readonly " $fragmentSpreads": FragmentRefs<"articleMainTrade_article">;
  readonly " $fragmentType": "articleTradeTypeAndPriceText_article";
};
export type articleTradeTypeAndPriceText_article$key = {
  readonly " $data"?: articleTradeTypeAndPriceText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleTradeTypeAndPriceText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "articleTradeTypeAndPriceText_article"
};

(node as any).hash = "1ae674abdd01387ef6cc0eafaa883d76";

export default node;
