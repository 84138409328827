import { IconCheckmarkFatLine } from '@daangn/react-monochrome-icon';
import { cn } from '@daangn/realty-react';

type Props = {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  label: string;
};

const CheckLabel = ({ label, checked, onCheckedChange }: Props) => {
  return (
    <label
      className="body-medium-strong text-fg-neutral flex gap-2"
      onClick={() => onCheckedChange(!checked)}
    >
      <span
        className={cn(
          'border-gray300 flex h-6 w-6 items-center justify-center rounded-full border bg-transparent p-[5px]',
          checked && 'bg-bg-brandSolid'
        )}
      >
        {checked && <IconCheckmarkFatLine width={14} height={14} className="text-staticWhite" />}
      </span>
      {label}
    </label>
  );
};

export default CheckLabel;
