/**
 * @generated SignedSource<<fc23e0057a1cdf2aed4ea24750b91a11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ArticleBuildingOrientationEnum = "EAST_FACING" | "NORTH_EAST_FACING" | "NORTH_FACING" | "NORTH_WEST_FACING" | "SOUTH_EAST_FACING" | "SOUTH_FACING" | "SOUTH_WEST_FACING" | "WEST_FACING";
export type ArticleManageCostOptionEnum = "BOILER" | "CLEANING" | "ELECTRIC" | "ELEVATOR" | "GAS" | "INTERNET" | "PARKING" | "TV" | "WATERWORKS";
export type ArticleOptionNameEnum = "AIRCON" | "BED" | "ELEC_RANGE" | "ELEVATOR" | "FRIDGE" | "GAS_RANGE" | "INDUCTION" | "LOFT" | "MORTGAGE" | "PARKING" | "PET" | "ROOFTOP" | "WASHER";
export type ArticleOptionValueEnum = "DONT_KNOW" | "NO" | "YES";
export type ArticleQualitativeItemEnum = "AFFORDABLE_PRICE" | "CLEAN_CONDITION" | "CONVENIENT_TRANSPORT" | "GOOD_SOUNDPROOFING" | "GOOD_SUNLIGHT" | "MANY_AMENITIES" | "NICE_VIEW" | "NO_PESTS" | "SAFE_FOR_SOLO" | "WITH_TERRACE";
export type BuildingUsageEnum = "ACCOMMODATION_FACILITY" | "BROADCASTING_AND_COMMUNICATION_FACILITY" | "CAR_RELATED_FACILITY" | "CEMETERY_RELATED_FACILITY" | "CORRECTION_AND_MILITARY_FACILITY" | "CULTURAL_AND_ASSEMBLY_FACILITIES" | "DOOR_AND_PLANT_RELATED_FACILITY" | "EDUCATION_AND_RESEARCH_FACILITY" | "ELDERLY_FACILITY" | "EXERCISE_FACILITY" | "FACTORY" | "FUNERAL_HOME" | "HAZARDOUS_MATERIAL_FACILITY" | "MEDICAL_FACILITY" | "OFFICE_FACILITY" | "POWER_GENERATION_FACILITY" | "PUBLIC_HOUSING" | "RECREATION_FACILITY" | "RELIGIOUS_FACILITY" | "SALE_FACILITY" | "SEWAGE_AND_WASTE_PROCESSING_FACILITY" | "SINGLE_FAMILY_HOUSING" | "STUDY_FACILITY" | "TEMPORARY_BUILDING" | "TOURISM_AND_RECREATION_FACILITY" | "TRANSPORTATION_FACILITY" | "TYPE_1_NEIGHBORHOOD_LIVING_FACILITY" | "TYPE_2_NEIGHBORHOOD_LIVING_FACILITY" | "WAREHOUSE_FACILITY";
export type LandPurposeEnum = "AGRICULTURAL_AND_FOREST_AREA" | "CONTROL_AREA" | "NATURAL_CONSERVATION_AREA" | "URBAN_AREA";
export type LandTypeEnum = "BANK" | "DITCH" | "DRY_PADDY_FIELD" | "FACTORY_SITE" | "FISH_FARM" | "FORESTRY" | "GAS_STATION_SITE" | "GRAVEYARD" | "GYMNASTIC_SITE" | "HISTORIC_SITE" | "MARSH" | "MINERAL_SPRING_SITE" | "MISCELLANEOUS_LAND" | "ORCHARD" | "PADDY_FIELD" | "PARK" | "PARKING_LOT" | "PASTURE" | "RAILWAY_SITE" | "RECREATION_AREA" | "RELIGION_SITE" | "RIVER" | "ROAD" | "SALTERN" | "SCHOOL_SITE" | "SITE" | "WAREHOUSE_SITE" | "WATER_SUPPLY_SITE";
export type ManageCostPayOptionEnum = "FIXED" | "NEED_TO_CHECK" | "NONE" | "USED";
export type OneRoomSubSalesType = "OPEN_ROOM" | "SPLIT_ROOM";
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
export type TradeTypeEnum = "BORROW" | "BUY" | "MONTH" | "SHORT" | "YEAR";
export type WriterTypeEnum = "BROKER" | "LESSOR" | "TENANT";
import { FragmentRefs } from "relay-runtime";
export type useArticleForFormFragment_article$data = {
  readonly address: string;
  readonly addressInfo: string;
  readonly area: string | null;
  readonly availableParkingSpotsV2: number | null;
  readonly availableTotalParkingSpots: number | null;
  readonly bathroomCnt: number | null;
  readonly buildingApprovalDate: string | null;
  readonly buildingName: string | null;
  readonly buildingOrientation: ArticleBuildingOrientationEnum | null;
  readonly buildingUsage: BuildingUsageEnum | null;
  readonly contactSafePhoneNumber: string | null;
  readonly contactTargetPhoneNumber: string | null;
  readonly content: string;
  readonly coordinate: {
    readonly lat: string;
    readonly lon: string;
  };
  readonly corRealtyId: string | null;
  readonly etcManageCost: number | null;
  readonly excludeManageCostOption: ReadonlyArray<ArticleManageCostOptionEnum>;
  readonly floor: string | null;
  readonly floorPlanImages: ReadonlyArray<{
    readonly id: string;
    readonly originalId: string;
    readonly url: string;
  }>;
  readonly fullAddress: string | null;
  readonly id: string;
  readonly images: ReadonlyArray<{
    readonly id: string;
    readonly originalId: string;
    readonly url: string;
  }>;
  readonly includeManageCostOption: ReadonlyArray<ArticleManageCostOptionEnum>;
  readonly includeManageCostOptionV2: ReadonlyArray<{
    readonly fixedCost: number | null;
    readonly option: ArticleManageCostOptionEnum;
    readonly payOption: ManageCostPayOptionEnum | null;
  }>;
  readonly isUnknownManageCost: boolean | null;
  readonly landPurpose: LandPurposeEnum | null;
  readonly landType: LandTypeEnum | null;
  readonly manageCost: number | null;
  readonly manageCostDescription: string | null;
  readonly moveInDate: string | null;
  readonly options: ReadonlyArray<{
    readonly name: ArticleOptionNameEnum;
    readonly value: ArticleOptionValueEnum;
  }>;
  readonly premiumMoney: number | null;
  readonly premiumMoneyDescription: string | null;
  readonly qualitativeItems: ReadonlyArray<ArticleQualitativeItemEnum>;
  readonly roomCnt: number | null;
  readonly salesTypeV2: {
    readonly etcType?: string;
    readonly subType?: OneRoomSubSalesType;
    readonly type: SalesTypeEnum;
  };
  readonly supplyArea: string | null;
  readonly topFloor: string | null;
  readonly trades: ReadonlyArray<{
    readonly __typename: string;
    readonly adjustable?: boolean;
    readonly deposit?: number;
    readonly description?: string | null;
    readonly monthlyPay?: number;
    readonly preferred: boolean;
    readonly price?: number;
    readonly type: TradeTypeEnum;
    readonly yearlyPay?: number;
  }>;
  readonly videos: ReadonlyArray<{
    readonly bigStreamId: string | null;
    readonly filename: string;
    readonly id: string;
    readonly originalVideoId: string;
    readonly status: {
      readonly __typename: "CompleteVideoStatus";
    } | {
      readonly __typename: "EncodingVideoStatus";
    } | {
      readonly __typename: "InvalidUploadVideoStatus";
    } | {
      readonly __typename: "NotfoundVideoStatus";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
    readonly thumbnailUrl: string;
  }>;
  readonly writerType: WriterTypeEnum | null;
  readonly " $fragmentType": "useArticleForFormFragment_article";
};
export type useArticleForFormFragment_article$key = {
  readonly " $data"?: useArticleForFormFragment_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"useArticleForFormFragment_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "originalId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adjustable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deposit",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthlyPay",
  "storageKey": null
},
v8 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useArticleForFormFragment_article",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressInfo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "area",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplyArea",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bathroomCnt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Coordinate",
      "kind": "LinkedField",
      "name": "coordinate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lon",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BigPictureImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BigPictureImage",
      "kind": "LinkedField",
      "name": "floorPlanImages",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "moveInDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleOption",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "corRealtyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roomCnt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "salesTypeV2",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subType",
              "storageKey": null
            }
          ],
          "type": "OneRoomSalesType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "etcType",
              "storageKey": null
            }
          ],
          "type": "EtcSalesType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topFloor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageCostDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeManageCostOption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludeManageCostOption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "premiumMoney",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "premiumMoneyDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landPurpose",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "trades",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferred",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "type": "MonthTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "type": "ShortTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yearlyPay",
              "storageKey": null
            }
          ],
          "type": "YearTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "type": "BorrowTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            }
          ],
          "type": "BuyTrade",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "writerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qualitativeItems",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildingName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildingOrientation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildingApprovalDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Video",
      "kind": "LinkedField",
      "name": "videos",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalVideoId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bigStreamId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": (v8/*: any*/),
              "type": "CompleteVideoStatus",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v8/*: any*/),
              "type": "EncodingVideoStatus",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v8/*: any*/),
              "type": "InvalidUploadVideoStatus",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v8/*: any*/),
              "type": "NotfoundVideoStatus",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableParkingSpotsV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableTotalParkingSpots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactSafePhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactTargetPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildingUsage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "etcManageCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleManageCostOptionDetail",
      "kind": "LinkedField",
      "name": "includeManageCostOptionV2",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fixedCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "option",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payOption",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnknownManageCost",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "4a2d69ad070fe8bc567b168d8e787724";

export default node;
