import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { useController } from 'react-hook-form';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { Checkbox, FormControl, TextField } from '@daangn/carotene';
import { replaceEmoji } from '@/utils/validate';

const PremiumMoneyField = () => {
  const { control } = useArticleForm();

  const premiumMoney = useController({ control, name: 'premiumMoney' });
  const premiumMoneyDescription = useController({ control, name: 'premiumMoneyDescription' });

  const handleNoPremiumMoney = (checked: boolean) => {
    premiumMoney.field.onChange(checked ? null : 0);
    premiumMoneyDescription.field.onChange(checked ? null : '');
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-4">
        <div className="flex gap-6">
          <FieldLogger name="premium_money" eventParams={{}}>
            <FormControl
              label="권리금"
              invalid={!!premiumMoney.fieldState.error}
              errorMessage={premiumMoney.fieldState.error?.message}
            >
              <TextField
                {...premiumMoney.field}
                suffix="만원"
                value={String(premiumMoney.field.value ?? '')}
                onChange={(e) => premiumMoney.field.onChange(Number(e.target.value))}
                type="number"
                placeholder="0"
                disabled={premiumMoney.field.value === null}
              />
            </FormControl>
          </FieldLogger>
          <FieldLogger name="premium_money_description" eventParams={{}}>
            <FormControl
              label="권리금 설명"
              indicator="(선택)"
              invalid={!!premiumMoneyDescription.fieldState.error}
              errorMessage={premiumMoneyDescription.fieldState.error?.message}
            >
              <TextField
                {...premiumMoneyDescription.field}
                value={String(premiumMoneyDescription.field.value ?? '')}
                onChange={(e) =>
                  premiumMoneyDescription.field.onChange(replaceEmoji(e.target.value))
                }
                placeholder="권리금 설명을 입력해주세요."
                disabled={premiumMoney.field.value === null}
              />
            </FormControl>
          </FieldLogger>
        </div>
        <Checkbox
          onCheckedChange={handleNoPremiumMoney}
          label="권리금 없음"
          checked={premiumMoney.field.value === null}
          invalid={!!premiumMoney.fieldState.error}
        />
      </div>
    </div>
  );
};

export default PremiumMoneyField;
