/**
 * @generated SignedSource<<7747db194b35544df73a12c5aa91346f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleIdVerifyFailStep_article$data = {
  readonly address: string;
  readonly originalId: string;
  readonly " $fragmentType": "ArticleIdVerifyFailStep_article";
};
export type ArticleIdVerifyFailStep_article$key = {
  readonly " $data"?: ArticleIdVerifyFailStep_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleIdVerifyFailStep_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleIdVerifyFailStep_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalId",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "4a4e8c7ab24435b0e2e4ebb8c1a378d8";

export default node;
