import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { useController } from 'react-hook-form';
import { FormControl, MultilineTextField } from '@daangn/carotene';

const ContentField = () => {
  const { control } = useArticleForm();
  const { field, fieldState } = useController({ control, name: 'content' });

  return (
    <FieldLogger name="content" eventParams={{}}>
      <FormControl
        label="매물 설명"
        indicator="(선택)"
        invalid={fieldState.invalid}
        errorMessage={fieldState.error?.message}
      >
        <MultilineTextField {...field} className="min-h-[200px]" />
      </FormControl>
    </FieldLogger>
  );
};

export default ContentField;
