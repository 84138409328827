import { IconCheckmarkLine, IconChevronDownLine } from '@daangn/react-monochrome-icon';
import { forwardRef, useRef, useState } from 'react';
import Field, { FieldProperty } from './Field';
import { CommonRegex } from '@daangn/realty-sdk';
import BottomSheet from '@/components/BottomSheet';
import { cn } from '@daangn/realty-react/utils';
import { SmsVerifyCarrierEnum } from '@/types/schemaEnums';
import { Divider } from '@daangn/carotene';

const TelecomLabel = {
  SKT: 'SKT',
  KT: 'KT',
  LGU: 'LG U+',
  SKT_MVNO: 'SKT 알뜰폰',
  KT_MVNO: 'KT 알뜰폰',
  LGU_MVNO: 'LG U+ 알뜰폰',
} as Record<SmsVerifyCarrierEnum, string>;

type Props = FieldProperty & {
  phone: string;
  telecom: SmsVerifyCarrierEnum | '';
  onChangePhone: (phone: string) => void;
  onChangeTelecom: (telecom: SmsVerifyCarrierEnum) => void;
  onComplete?: (phone: string) => void;
};

const PHONE_MAX_LENGTH = 11;

const PhoneField = forwardRef<HTMLButtonElement, Props>(
  (
    { disabled, phone, invalid, errorMessage, onChangePhone, telecom, onChangeTelecom, onComplete },
    ref
  ) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const input = useRef<HTMLInputElement>(null);

    const handleChangeTelecom = (telecom: SmsVerifyCarrierEnum) => {
      onChangeTelecom(telecom);
      setIsBottomSheetOpen(false);

      setTimeout(() => {
        !phone && input.current?.focus();
      }, 100);
    };

    const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value && !CommonRegex.OnlyNumber.test(e.target.value)) {
        return;
      }

      onChangePhone(e.target.value);

      if (e.target.value.length === PHONE_MAX_LENGTH) {
        input.current?.blur();
        onComplete?.(e.target.value);
      }
    };

    return (
      <Field
        label="휴대폰 번호"
        invalid={invalid}
        errorMessage={errorMessage}
        disabled={disabled}
        className="w-full px-[14px] py-[13px]"
      >
        <div className="flex items-center gap-2">
          <button
            ref={ref}
            className="flex-shrink-0 p-0 text-inherit"
            onClick={() => setIsBottomSheetOpen(true)}
          >
            <div className="flex items-center gap-1">
              <span>{telecom ? TelecomLabel[telecom] : '통신사'}</span>
              <IconChevronDownLine width={18} height={18} />
            </div>
          </button>
          <input
            disabled={disabled}
            type="tel"
            ref={input}
            onChange={handleChangePhone}
            value={phone}
            placeholder="01012341234"
            maxLength={PHONE_MAX_LENGTH}
            className="w-full bg-transparent p-0"
          />
        </div>

        <TelecomBottomSheet
          isOpen={isBottomSheetOpen}
          onClose={() => setIsBottomSheetOpen(false)}
          telecom={telecom}
          onChangeTelecom={handleChangeTelecom}
        />
      </Field>
    );
  }
);

export default PhoneField;

const TelecomBottomSheet = ({
  isOpen,
  onClose,
  telecom,
  onChangeTelecom,
}: {
  isOpen: boolean;
  onClose: () => void;
  telecom: SmsVerifyCarrierEnum | '';
  onChangeTelecom: (telecom: SmsVerifyCarrierEnum) => void;
}) => {
  const handleChangeTelecom = (telecom: SmsVerifyCarrierEnum) => {
    onChangeTelecom(telecom);
    onClose();
  };

  return (
    <BottomSheet title="통신사 선택" isOpen={isOpen} onClose={onClose}>
      <div className="px-4">
        <Divider color="bg-gray300" />
      </div>

      <ul className="pt-[6px]">
        {Object.entries(TelecomLabel).map(([value, label]) => {
          const isSelected = value === telecom;

          return (
            <li
              key={value}
              className={cn(
                'body-medium-default text-fg-neutral flex justify-between px-4 py-3',
                isSelected && 'text-fg-brand body-medium-strong'
              )}
              onClick={() => handleChangeTelecom(value as SmsVerifyCarrierEnum)}
            >
              <span>{label}</span>

              {isSelected && <IconCheckmarkLine width={18} height={18} />}
            </li>
          );
        })}
      </ul>
    </BottomSheet>
  );
};
