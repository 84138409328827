import type {
  SearchJusoAddressAllUnitsQuery,
  SearchJusoAddressAllUnitsQuery$data,
} from '@/__generated__/SearchJusoAddressAllUnitsQuery.graphql';
import MainEnvironment from '@/relay/environment';
import type { JusoAddressResult } from '@/components/Article/Field/Address/SearchJusoAddressResult';
import SearchJusoAddressResult from '@/components/Article/Field/Address/SearchJusoAddressResult';
import { type JusoUnit } from '@/components/Article/Field/Address/SelectJusoUnit';
import { Badge, FormControl, LinkButton, ProgressCircle, TextField } from '@daangn/carotene';
import { IconMagnifyingglassLine, IconXmarkLine } from '@daangn/react-monochrome-icon';
import { useSearch } from '@daangn/realty-react';
import { captureException } from '@sentry/react';
import { Suspense, useState } from 'react';
import toast from 'react-hot-toast';
import { fetchQuery, graphql } from 'relay-runtime';
import SearchJusoDetailAddress from '@/components/Article/Field/Address/SearchJusoDetailAddress';
import { AddressGuide } from '@/components/Article/Field/Address/AddressGuide';

const Loading = () => (
  <div className="flex items-center justify-center py-5">
    <ProgressCircle />
  </div>
);

export type JusoAddressForm = {
  baseAddress: JusoAddressResult;
  detailAddress?:
    | {
        type: 'unit';
        unit?: JusoUnit;
      }
    | {
        type: 'manual';
        isRequired?: boolean;
        address?: string;
      };
};

type Props = {
  jusoAddress: JusoAddressForm | null;
  errors:
    | {
        address?: string;
        detailAddress?: string;
      }
    | undefined;
  onClickCorMode: () => void;
  onJusoAddressChange: (jusoAddress: JusoAddressForm | null) => void;
  onComplete: (form: JusoAddressForm) => void;
  setIsFetchingUnit: (isFetchingUnit: boolean) => void;
};

const SearchJusoAddress = ({
  errors,
  setIsFetchingUnit,
  jusoAddress,
  onClickCorMode,
  onJusoAddressChange,
  onComplete,
}: Props) => {
  const { input, setInput, query, reset } = useSearch('');
  const [allUnits, setAllUnits] = useState<
    SearchJusoAddressAllUnitsQuery$data['searchBuildingAllUnits']
  >([]);

  const handleOnSelectAddress = async (address: JusoAddressResult) => {
    setInput('');

    onJusoAddressChange({
      baseAddress: address,
      detailAddress: undefined,
    });
    setIsFetchingUnit(true);
    setAllUnits([]);
    try {
      const response = await fetchQuery<SearchJusoAddressAllUnitsQuery>(
        MainEnvironment,
        graphql`
          query SearchJusoAddressAllUnitsQuery($data: SearchAddressesBaseDataInput!) {
            searchBuildingAllUnits(data: $data) {
              dongNm
              floorNm
              hoNm
            }
          }
        `,
        {
          data: {
            admCd: address.admCd,
            buldMnnm: address.buldMnnm,
            buldSlno: address.buldSlno,
            rnMgtSn: address.rnMgtSn,
            udrtYn: address.udrtYn,
          },
        }
      ).toPromise();

      if (!response || !response.searchBuildingAllUnits.length) {
        onJusoAddressChange({
          baseAddress: address,
          detailAddress: {
            type: 'manual',
            isRequired: false,
            address: '',
          },
        });
        return;
      }

      setAllUnits(response.searchBuildingAllUnits);
      onJusoAddressChange({
        baseAddress: address,
        detailAddress: {
          type: 'unit',
        },
      });
    } catch (error: unknown) {
      toast.error('동,호수를 가져오는데 실패했어요. 주소를 다시 선택해주세요.');
      if (error instanceof Error) {
        error.message = `[juso unit 전체 가져오기 Error] ${error.message}`;
        captureException(error, {
          extra: {
            jusoAddress: address,
          },
        });
      }
      return;
    } finally {
      setIsFetchingUnit(false);
    }
  };

  const handleDetailAddressChange = (detailAddress: JusoAddressForm['detailAddress']) => {
    if (!jusoAddress) {
      toast.error('주소를 선택해주세요.');
      return;
    }

    if (detailAddress?.type === 'unit' && !!detailAddress.unit) {
      onComplete({
        ...jusoAddress,
        detailAddress,
      });
      return;
    }

    onJusoAddressChange({
      ...jusoAddress,
      detailAddress,
    });
  };

  return (
    <div className="flex flex-col gap-5">
      <div>
        {!!jusoAddress ? (
          <div className="flex flex-col gap-2">
            <div className="flex">
              <div className="body-medium-strong">주소</div>
              <div className="spacer" />
              <LinkButton size="small" variant="neutral" onClick={() => onJusoAddressChange(null)}>
                수정하기
              </LinkButton>
            </div>
            <div className="text-fg-neutral bg-bg-neutral body-small-default flex flex-col gap-1 rounded-md px-4 py-3">
              <div>{jusoAddress.baseAddress.roadAddress}</div>
              {jusoAddress.baseAddress.regionAddress && (
                <div className="flex items-center gap-1 rounded-sm">
                  <Badge size="small" tone="neutral">
                    지 번
                  </Badge>
                  <div>{jusoAddress.baseAddress.regionAddress}</div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <FormControl invalid={!!errors?.address} errorMessage={errors?.address}>
            <TextField
              placeholder="예) 한누리대로 411, 상암동 1595"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              autoFocus={true}
              suffix={
                input ? (
                  <IconXmarkLine size={22} onMouseDown={() => reset()} />
                ) : (
                  <IconMagnifyingglassLine size={22} />
                )
              }
            />
          </FormControl>
        )}

        {input && (
          <Suspense fallback={<Loading />}>
            {input !== query ? (
              <Loading />
            ) : (
              <SearchJusoAddressResult
                query={query}
                onSelectItem={handleOnSelectAddress}
                onClickCorMode={onClickCorMode}
              />
            )}
          </Suspense>
        )}
        {!input && !jusoAddress && (
          <div className="mt-5">
            <AddressGuide />
          </div>
        )}
      </div>
      {jusoAddress?.detailAddress && (
        <SearchJusoDetailAddress
          errorMessage={errors?.detailAddress}
          units={allUnits}
          detailAddress={jusoAddress.detailAddress}
          onDetailAddressChange={handleDetailAddressChange}
        />
      )}
    </div>
  );
};

export default SearchJusoAddress;
