import React, { useState } from 'react';
import { graphql, useMutation } from 'react-relay';
import { getVideoDuration, validateVideoFileType } from '@/utils/video';
import { useVideoUpload_CreateVideoUploadInfoMutation } from '__generated__/useVideoUpload_CreateVideoUploadInfoMutation.graphql';
import { VideoType } from '@/scheme/article';
import streamApi from '@/api/stream';

class VideoValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = `[VideoValidationError] ${message}`;
  }
}

const MAX_VIDEO_SIZE_MB = 200;
const MAX_VIDEO_DURATION_HOURS = 1;

const byteToMegaByte = (byte: number) => {
  return byte / 1024 / 1024;
};

export type VideoUploadState = {
  loading: boolean;
  progress: number;
};

const InitialUploadState = {
  loading: false,
  progress: 0,
};

type Params = {
  videos: VideoType[];
  onChangeVideos: (videos: VideoType[]) => void;
  maxVideoSizeMB?: number;
  maxVideoDurationHours?: number;
  events?: {
    onUploadStart?: () => void;
    onUploadEnd?: () => void;
  };
};

const useVideoUpload = ({
  videos,
  onChangeVideos,
  maxVideoSizeMB = MAX_VIDEO_SIZE_MB,
  maxVideoDurationHours = MAX_VIDEO_DURATION_HOURS,
  events,
}: Params) => {
  const [uploadState, setUploadState] = useState<VideoUploadState>(InitialUploadState);

  const [createVideoUploadInfo] = useMutation<useVideoUpload_CreateVideoUploadInfoMutation>(graphql`
    mutation useVideoUpload_CreateVideoUploadInfoMutation($input: CreateVideoUploadInfoInput!) {
      createVideoUploadInfo(input: $input) {
        ... on VideoUploadInfo {
          originalVideoId
          uploadUrl
          originalBigStreamId
        }
      }
    }
  `);

  const handleInputFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file || uploadState.loading) {
      return;
    }
    try {
      if (videos.length > 0) {
        throw new VideoValidationError('영상은 1개까지만 첨부할 수 있어요');
      }

      if (!validateVideoFileType(file.type)) {
        throw new VideoValidationError('지원하지 않는 포맷이에요');
      }

      if (byteToMegaByte(file.size) >= maxVideoSizeMB) {
        throw new VideoValidationError(`동영상 크기는 ${200}mb 까지 가능해요`);
      }

      const duration = await getVideoDuration(file);

      if (duration && duration > maxVideoDurationHours * 60 * 60) {
        throw new VideoValidationError(
          `동영상 재생시간은 최대 ${maxVideoDurationHours}시간 까지 가능해요`
        );
      }

      events?.onUploadStart?.();
      setUploadState({ loading: true, progress: 0 });

      createVideoUploadInfo({
        variables: {
          input: {
            originalVideoName: file.name,
          },
        },
        onCompleted: (data) => {
          streamApi
            .uploadVideo(data.createVideoUploadInfo.uploadUrl, file, (progress) => {
              setUploadState((prev) => ({ ...prev, progress }));
            })
            .then(() => {
              window.alert('영상 업로드가 완료되었어요.');
              onChangeVideos([
                ...videos,
                {
                  id: data.createVideoUploadInfo.originalVideoId,
                  filename: file.name,
                  bigStreamId: data.createVideoUploadInfo.originalBigStreamId,
                },
              ]);
            })
            .catch(() => {
              window.alert(`업로드에 실패하였어요. 다시 시도해주세요`);
            })
            .finally(() => {
              setUploadState(InitialUploadState);
              events?.onUploadEnd?.();
              e.target.value = '';
            });
        },
        onError: () => {
          events?.onUploadEnd?.();
          setUploadState(InitialUploadState);
          window.alert(`업로드에 실패하였어요. 다시 시도해주세요`);
          e.target.value = '';
        },
      });
    } catch (error) {
      if (error instanceof VideoValidationError) {
        window.alert(error.message);
      }
      e.target.value = '';
    }
  };

  return {
    handleInputFileChange,
    uploadState,
  };
};

export default useVideoUpload;
