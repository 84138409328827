/**
 * @generated SignedSource<<a2fdfa87ea9873fe74c6644dbaf5b559>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleStatusEnum = "ON_GOING" | "RESERVED" | "TRADED";
export type ArticleIdVerifyQuery$variables = {
  articleId: string;
};
export type ArticleIdVerifyQuery$data = {
  readonly articleByOriginalArticleId: {
    readonly isHide: boolean;
    readonly isWriterVerify: boolean;
    readonly originalId: string;
    readonly status: ArticleStatusEnum;
    readonly " $fragmentSpreads": FragmentRefs<"ArticleIdVerifyFailStep_article" | "ArticleSection_article">;
  };
};
export type ArticleIdVerifyQuery = {
  response: ArticleIdVerifyQuery$data;
  variables: ArticleIdVerifyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "originalArticleId",
    "variableName": "articleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWriterVerify",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHide",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deposit",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyPay",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticleIdVerifyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByOriginalArticleId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArticleSection_article"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArticleIdVerifyFailStep_article"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticleIdVerifyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByOriginalArticleId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressWithDong",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageThumbnailUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "salesTypeV2",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "etcType",
                    "storageKey": null
                  }
                ],
                "type": "EtcSalesType",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "trades",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferred",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v10/*: any*/),
                "type": "ShortTrade",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v10/*: any*/),
                "type": "MonthTrade",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "yearlyPay",
                    "storageKey": null
                  }
                ],
                "type": "YearTrade",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "BorrowTrade",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  }
                ],
                "type": "BuyTrade",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd839e27afcb85f382a3a5d2c4fa2b60",
    "id": null,
    "metadata": {},
    "name": "ArticleIdVerifyQuery",
    "operationKind": "query",
    "text": "query ArticleIdVerifyQuery(\n  $articleId: String!\n) {\n  articleByOriginalArticleId(originalArticleId: $articleId) {\n    isWriterVerify\n    isHide\n    status\n    originalId\n    ...ArticleSection_article\n    ...ArticleIdVerifyFailStep_article\n    id\n  }\n}\n\nfragment ArticleIdVerifyFailStep_article on Article {\n  address\n  originalId\n}\n\nfragment ArticleSection_article on Article {\n  id\n  addressWithDong\n  imageThumbnailUrl\n  ...articleSalesTypeText_article\n  ...articleTradeTypeAndPriceText_article\n}\n\nfragment articleMainTrade_article on Article {\n  trades {\n    __typename\n    type\n    preferred\n    ... on ShortTrade {\n      deposit\n      description\n      monthlyPay\n    }\n    ... on MonthTrade {\n      deposit\n      description\n      monthlyPay\n    }\n    ... on YearTrade {\n      deposit\n      description\n      yearlyPay\n    }\n    ... on BorrowTrade {\n      deposit\n      description\n    }\n    ... on BuyTrade {\n      price\n    }\n  }\n}\n\nfragment articleSalesTypeText_article on Article {\n  salesTypeV2 {\n    __typename\n    type\n    ... on EtcSalesType {\n      etcType\n    }\n  }\n}\n\nfragment articleTradeTypeAndPriceText_article on Article {\n  ...articleMainTrade_article\n}\n"
  }
};
})();

(node as any).hash = "f5a872cd19bdc46b7dead7a4eb070730";

export default node;
