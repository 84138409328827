/**
 * @generated SignedSource<<601ea24e6e60dbd49db8de68ff16136b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SmsVerifyCarrierEnum = "KT" | "KT_MVNO" | "LGU" | "LGU_MVNO" | "SKT" | "SKT_MVNO";
export type SendIdentificationOtpSmsInput = {
  carrier: SmsVerifyCarrierEnum;
  first7Jumin: string;
  phoneNumber: string;
  realName: string;
};
export type FormStep_sendSmsMutation$variables = {
  input: SendIdentificationOtpSmsInput;
};
export type FormStep_sendSmsMutation$data = {
  readonly sendIdentificationOtpSms: {
    readonly popupError?: {
      readonly message: string;
    };
    readonly result?: {
      readonly identificationVerifyRequestId: string;
      readonly otpValidDuration: string;
    };
    readonly toastError?: {
      readonly message: string;
    };
  };
};
export type FormStep_sendSmsMutation = {
  response: FormStep_sendSmsMutation$data;
  variables: FormStep_sendSmsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SendIdentificationOtpSms_ResultPayload",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identificationVerifyRequestId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otpValidDuration",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SendIdentificationOtpSms_Result",
  "abstractKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ToastErrorPayload",
      "kind": "LinkedField",
      "name": "toastError",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ToastError",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupErrorPayload",
      "kind": "LinkedField",
      "name": "popupError",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "PopupError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormStep_sendSmsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendIdentificationOtpSms",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormStep_sendSmsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendIdentificationOtpSms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31df0c2f9a46a31621d599568938864e",
    "id": null,
    "metadata": {},
    "name": "FormStep_sendSmsMutation",
    "operationKind": "mutation",
    "text": "mutation FormStep_sendSmsMutation(\n  $input: SendIdentificationOtpSmsInput!\n) {\n  sendIdentificationOtpSms(input: $input) {\n    __typename\n    ... on SendIdentificationOtpSms_Result {\n      result {\n        identificationVerifyRequestId\n        otpValidDuration\n      }\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n    ... on PopupError {\n      popupError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a161b0f1464f6e202fede7ab187bee0";

export default node;
