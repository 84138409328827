/**
 * @generated SignedSource<<3457db1c13aa274e6e1965d475218682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SearchAddressesBaseDataInput = {
  admCd: string;
  buldMnnm: number;
  buldSlno: number;
  rnMgtSn: string;
  udrtYn: string;
};
export type SearchJusoAddressAllUnitsQuery$variables = {
  data: SearchAddressesBaseDataInput;
};
export type SearchJusoAddressAllUnitsQuery$data = {
  readonly searchBuildingAllUnits: ReadonlyArray<{
    readonly dongNm: string;
    readonly floorNm: string;
    readonly hoNm: string;
  }>;
};
export type SearchJusoAddressAllUnitsQuery = {
  response: SearchJusoAddressAllUnitsQuery$data;
  variables: SearchJusoAddressAllUnitsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "BuildingUnits",
    "kind": "LinkedField",
    "name": "searchBuildingAllUnits",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dongNm",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "floorNm",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hoNm",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchJusoAddressAllUnitsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchJusoAddressAllUnitsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6f7d52caf8c9c18cb857b9e6004b84e8",
    "id": null,
    "metadata": {},
    "name": "SearchJusoAddressAllUnitsQuery",
    "operationKind": "query",
    "text": "query SearchJusoAddressAllUnitsQuery(\n  $data: SearchAddressesBaseDataInput!\n) {\n  searchBuildingAllUnits(data: $data) {\n    dongNm\n    floorNm\n    hoNm\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b270eab2ee9fbc234ea83ea1ce7111f";

export default node;
