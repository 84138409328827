import { Label } from '@/components/ui/Label';
import { Stack } from '@daangn/carotene';
import { IconExclamationmarkCircleFill } from '@daangn/react-monochrome-icon';
import { cn } from '@daangn/realty-react';
import { ComponentProps, PropsWithChildren } from 'react';

export type FieldProperty = {
  disabled?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  label?: string;
};

const Field = ({
  disabled,
  children,
  label,
  invalid,
  errorMessage,
  className,
  ...props
}: PropsWithChildren<FieldProperty & ComponentProps<typeof Stack>>) => {
  return (
    <Stack gap={3}>
      {label && <Label>{label}</Label>}

      <Stack gap={2}>
        <div
          className={cn(
            'relative rounded-[4px] border border-solid px-3.5 py-[13px]',
            'bg-bg-layerDefault border-stroke-field focus-within:border-stroke-fieldFocused',
            invalid && '!bg-bg-negativeWeak !border-stroke-negative',
            disabled && '!bg-bg-disabled !text-fg-disabled',
            className
          )}
          {...props}
        >
          {children}
        </div>
      </Stack>

      {invalid && errorMessage && (
        <span className="body-xsmall-default text-fg-negative flex items-center gap-1">
          <IconExclamationmarkCircleFill width={14} height={14} />
          <span>{errorMessage}</span>
        </span>
      )}
    </Stack>
  );
};

export default Field;
