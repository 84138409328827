import { ArticleItem_article$key } from '@/__generated__/ArticleItem_article.graphql';
import { graphql, useFragment } from 'react-relay';
import ArticleThumbnail from './ArticleThumbnail';
import ArticleTitle from './ArticleTitle';
import { Badge } from '@daangn/carotene';
import { formatDistanceKo } from '@daangn/realty-sdk';
import { readArticleSummaryInfo } from '@/utils/article';
import { IconDot3HorizontalChatbubbleLeftFill, IconHeartFill } from '@daangn/react-monochrome-icon';
import logger from '@/utils/Logger';
import { config } from '@/config';

type Props = {
  articleRef: ArticleItem_article$key;
  index: number;
  showRealtyId?: boolean;
  action?: React.ReactNode;
  searchKeyword?: string;
};
const ArticleItem = ({ articleRef, showRealtyId, action, searchKeyword }: Props) => {
  const articleItemRef = useFragment(
    graphql`
      fragment ArticleItem_article on Article {
        id
        originalId
        publicId
        publishedAt
        region {
          name
        }
        chatRoomCount
        watchCount
        ...ArticleThumbnail_article
        ...ArticleTitle_article
        ...articleSummaryInfo_article
      }
    `,
    articleRef
  );

  const summary = readArticleSummaryInfo(articleItemRef);
  const hasChatRoom = articleItemRef.chatRoomCount > 0;
  const hasWatch = articleItemRef.watchCount > 0;

  const handleOnClick = () => {
    logger.track('click_article_item', {
      article_id: articleItemRef.originalId,
      search_keyword: searchKeyword,
    });

    const url = `${config.publicWebUrl}/kr/realty-posts/${articleItemRef.publicId}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="rounded-3 border-1 border-stroke-control bg-bg-layerDefault p-5">
        <div className="flex w-full cursor-pointer gap-4" onClick={handleOnClick}>
          <ArticleThumbnail articleRef={articleItemRef} />

          <div className="flex flex-1 flex-col gap-1">
            <div className="flex flex-col items-start gap-1.5">
              {showRealtyId && <Badge size="xsmall">매물번호 {articleItemRef.originalId}</Badge>}
              <ArticleTitle
                articleRef={articleItemRef}
                hideBadge={{
                  hide: true,
                }}
              />
            </div>

            <div>
              <div className="body-small-default text-fg-neutral mt-0.5">{summary}</div>
              <div className="body-xsmall-default text-fg-neutralSubtle mt-1">
                {formatDistanceKo(articleItemRef.publishedAt)} · {articleItemRef.region.name}
              </div>
            </div>

            {(hasChatRoom || hasWatch) && (
              <div className="flex justify-end gap-1">
                {hasChatRoom && (
                  <div className="flex items-center gap-0.5">
                    <IconDot3HorizontalChatbubbleLeftFill className="text-gray400 h-[15px] w-[15px]" />
                    <span className="body-xsmall-default text-fg-neutralSubtle">
                      {articleItemRef.chatRoomCount}
                    </span>
                  </div>
                )}

                {hasWatch && (
                  <div className="flex items-center gap-0.5">
                    <IconHeartFill className="text-gray400 h-[15px] w-[15px]" />
                    <span className="body-xsmall-default text-fg-neutralSubtle">
                      {articleItemRef.watchCount}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {action && <>{action}</>}
      </div>
    </>
  );
};

export default ArticleItem;
