// src/ui/Spacer.tsx
import { jsx } from "react/jsx-runtime";
var Spacer = () => {
  return /* @__PURE__ */ jsx("div", { className: "ru-flex-1 ru-self-stretch ru-justify-self-stretch" });
};

// src/ui/Image.tsx
import "react-lazy-load-image-component/src/effects/opacity.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { jsx as jsx2 } from "react/jsx-runtime";
var EMPTY_IMAGE = "https://assetstorage.krrt.io/1082534123824870175/b29f0966-b161-4615-a591-79f739f75d5d/width=840,height=840.webp";
var Image = ({
  lazy = false,
  src,
  alt = "",
  fallback = EMPTY_IMAGE,
  className,
  ...props
}) => {
  const handleError = (e) => {
    if (fallback && e.currentTarget.src !== fallback) {
      e.currentTarget.src = fallback;
    }
  };
  return /* @__PURE__ */ jsx2(
    LazyLoadImage,
    {
      visibleByDefault: !lazy,
      effect: "opacity",
      src,
      alt,
      className,
      onError: (e) => {
        var _a;
        handleError(e);
        (_a = props.onError) == null ? void 0 : _a.call(props, e);
      },
      ...props
    }
  );
};
export {
  Image,
  Spacer
};
