import MainEnvironment from '@/relay/environment';
import { createFileRoute, useLoaderData } from '@tanstack/react-router';
import { Suspense, useEffect, useState } from 'react';
import { loadQuery, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { graphql } from 'relay-runtime';
import ChipList, { SalesHistoryStatus, SalesHistoryTabs } from '@/components/Sales/ChipList';
import MyHistoryArticles from '@/components/Sales/MyHistoryArticles';
import ArticleListSkeleton from '@/components/Skeleton/ArticleListSkeleton';
import ReportBanner from '@/components/Banner/ReportBanner';
import { useSearch } from '@daangn/realty-react/hooks';
import { Button, TextField } from '@daangn/carotene';
import logger from '@/utils/Logger';
import {
  salesMyHistoryListQuery,
  salesMyHistoryListQuery$variables,
} from '@/__generated__/salesMyHistoryListQuery.graphql';

export const Route = createFileRoute('/_protected/sales')({
  loader: async ({}) => {
    return loadQuery<salesMyHistoryListQuery>(MainEnvironment, SalesMyHistoryListQuery, {});
  },
  component: Page,
});

const SalesMyHistoryListQuery = graphql`
  query salesMyHistoryListQuery($input: ViewerWriteArticleStatusGroupByCountInput) {
    viewerWriteArticleStatusGroupByCount(input: $input) {
      count
      status
    }
  }
`;

function Page() {
  const appQueryRef = useLoaderData({ from: Route.id });
  const [queryRef, loadQuery] = useQueryLoader<salesMyHistoryListQuery>(
    SalesMyHistoryListQuery,
    appQueryRef
  );

  if (!queryRef) throw new Error('게시글 정보를 불러오지 못했어요.');

  return <PageContent queryRef={queryRef} loadQuery={loadQuery} />;
}

type PageContentProps = {
  queryRef: PreloadedQuery<salesMyHistoryListQuery>;
  loadQuery: (variables: salesMyHistoryListQuery$variables) => void;
};
const PageContent = ({ queryRef, loadQuery }: PageContentProps) => {
  const { viewerWriteArticleStatusGroupByCount } = usePreloadedQuery(
    SalesMyHistoryListQuery,
    queryRef
  );

  const { input, setInput, query, forceSearch } = useSearch('');
  const searchKeyword = query.length >= 2 ? query : undefined;
  const isSearching = input.length >= 2 && input !== searchKeyword;

  const [selectedStatus, setSelectedStatus] = useState<SalesHistoryStatus>('ON_SALES');
  const handleChangeTab = (status: SalesHistoryStatus) => {
    setSelectedStatus(status);
    loadQuery({ input: { addressKeyword: searchKeyword } });
  };

  const handleClickSearchField = () => {
    logger.track('click_sales_history_search_field', { keyword: input });
  };

  useEffect(() => {
    loadQuery({ input: { addressKeyword: searchKeyword } });
  }, [searchKeyword]);

  const currentTabCount =
    viewerWriteArticleStatusGroupByCount.find((item) => item.status === selectedStatus)?.count ?? 0;

  if (searchKeyword && currentTabCount === 0) {
    const firstNonEmptyTab = SalesHistoryTabs.find(
      (tab) =>
        (viewerWriteArticleStatusGroupByCount.find((v) => v.status === tab.status)?.count ?? 0) > 0
    );

    firstNonEmptyTab && setSelectedStatus(firstNonEmptyTab.status);
  }

  return (
    <div className="flex flex-col gap-12 pb-36 pt-12">
      <div className="flex flex-col gap-4">
        <div className="heading-large">나의 매물</div>

        {/* Chip */}
        <ChipList
          selectedStatus={selectedStatus}
          statusGroupByCount={viewerWriteArticleStatusGroupByCount}
          onSelectedStatusChange={handleChangeTab}
        />

        <div className="flex items-center gap-3">
          <div className="w-full">
            <TextField
              value={input}
              onClick={handleClickSearchField}
              onChange={(e) => setInput(e.target.value)}
              placeholder="지번, 도로명으로 매물을 검색할 수 있어요"
            />
          </div>
          <div className="shrink-0 self-stretch">
            <Button
              className="h-full"
              size="large"
              variant="neutral"
              onClick={() => {
                forceSearch(input);
              }}
            >
              검색
            </Button>
          </div>
        </div>
      </div>

      {isSearching ? (
        <ArticleListSkeleton />
      ) : (
        <Suspense fallback={<ArticleListSkeleton />}>
          <MyHistoryArticles searchKeyword={searchKeyword} selectedStatus={selectedStatus} />
        </Suspense>
      )}

      <ReportBanner section="sales_my" />
    </div>
  );
};
