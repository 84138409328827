import CheckLabel from '@/components/base/CheckLabel';
import BottomSheet from '@/components/BottomSheet';
import { config } from '@/config';
import { Button, Divider, Stack, VisuallyHidden } from '@daangn/carotene';
import { IconCheckmarkFill, IconChevronRightFill } from '@daangn/react-monochrome-icon';
import { cn } from '@daangn/realty-react/utils';
import { useState } from 'react';

const TERMS_LIST = [
  {
    title: '(필수) 개인정보 수집 및 이용 동의',
    url: `${config.webUrl}/terms/index.html`,
  },
  {
    title: '(필수) 본인확인서비스 이용약관',
    url: 'https://safe.ok-name.co.kr/eterms/agreement002.jsp',
  },
  {
    title: '(필수) 통신사 이용 약관',
    url: 'https://safe.ok-name.co.kr/eterms/agreement004.jsp',
  },
  {
    title: '(필수) 고유식별정보처리 동의',
    url: 'https://safe.ok-name.co.kr/eterms/agreement003.jsp',
  },
  {
    title: '(필수) 개인정보 제3자 동의',
    url: 'https://safe.ok-name.co.kr/eterms/agreement005.jsp',
  },
  {
    title: '(필수) 개인정보 수집/이용/취급 위탁동의',
    url: 'https://safe.ok-name.co.kr/eterms/agreement001.jsp',
  },
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAgree: () => void;
};

const TermsBottomSheet = ({ isOpen, onClose, onAgree }: Props) => {
  const [checked, setChecked] = useState(() =>
    Object.fromEntries(TERMS_LIST.map((term) => [term.title, true]))
  );
  const canConfirm = Object.values(checked).every((v) => v);

  const handleAgree = () => {
    onClose();
    onAgree();
  };

  const handleAllAgree = () => {
    setChecked(
      Object.fromEntries(TERMS_LIST.map((term) => [term.title, canConfirm ? false : true]))
    );
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <Button size="xlarge" className="w-full" disabled={!canConfirm} onClick={handleAgree}>
          확인
        </Button>
      }
    >
      <h3 className="heading-medium px-5 py-2 pt-8">개인정보 수집 이용에 동의해주세요</h3>
      <Stack className="p-5" gap={4}>
        <CheckLabel label="전체동의" checked={canConfirm} onCheckedChange={handleAllAgree} />
        <Divider color="bg-gray300" />
        {TERMS_LIST.map((term) => (
          <div key={term.title} className="flex items-center gap-3">
            <label
              htmlFor={term.title}
              aria-checked={checked[term.title]}
              className="flex flex-1 items-center gap-2"
            >
              <IconCheckmarkFill
                width={18}
                height={18}
                className={cn('text-gray500', checked[term.title] && 'text-fg-brand')}
              />
              <span>{term.title}</span>
              <VisuallyHidden>
                <input
                  id={term.title}
                  type="checkbox"
                  checked={checked[term.title]}
                  onChange={() =>
                    setChecked((prev) => ({
                      ...prev,
                      [term.title]: !prev[term.title],
                    }))
                  }
                />
              </VisuallyHidden>
            </label>

            <button onClick={() => window.open(term.url, '_blank')} className="p-1">
              <IconChevronRightFill width={16} height={16} className="text-fg-neutralMuted" />
            </button>
          </div>
        ))}
      </Stack>
    </BottomSheet>
  );
};

export default TermsBottomSheet;
