import type { mainQuery$data } from '@/__generated__/mainQuery.graphql';
import { APP_VERSION, config, STAGE, IS_PROD } from '@/config';
import { LoggerEventParams, LoggerEventProperties, LoggerEventType } from '@/logger/eventSchema';
import posthog from 'posthog-js';
import { realtySessionStorage } from './sessionStorage';
import * as amplitude from '@amplitude/analytics-browser';
import { filterNil } from './misc';

class Logger {
  init() {
    amplitude.init(config.amplitudeKey, {
      minIdLength: 1,
      defaultTracking: true,
      logLevel:
        STAGE !== 'production' ? amplitude.Types.LogLevel.Debug : amplitude.Types.LogLevel.Warn,
      appVersion: APP_VERSION,
    });
  }
  setUser(user: {
    id: string;
    isDebugUser: boolean;
    bizProfileBroker?: Exclude<mainQuery$data['viewer'], null | undefined>['bizProfileBroker'];
  }) {
    const commonProperties = {
      is_debug_user: user.isDebugUser,
      biz_profile_broker: user.bizProfileBroker && filterNil(user.bizProfileBroker),
      custom_referrer: realtySessionStorage.getReferrer(),
    };

    amplitude.setUserId(String(user.id));
    const identifyEvent = new amplitude.Identify();
    Object.entries(commonProperties).forEach(([key, value]) => {
      value && identifyEvent.set(key, value);
    });
    amplitude.identify(identifyEvent);

    posthog.identify(user.id, commonProperties);
    if (user.bizProfileBroker) {
      posthog.group('biz_profile', user.bizProfileBroker.bizProfile.originalId);
    }
  }

  track = <TEventName extends LoggerEventType>(
    name: TEventName,
    params: LoggerEventParams<TEventName>
  ) => {
    const event = LoggerEventProperties[name];

    amplitude.logEvent(`[${event.type}] ${name}`, { ...params });
    posthog.capture(`[${event.type}] ${name}`, { ...params, app_version: APP_VERSION });

    if (!IS_PROD) {
      /* eslint-disable no-console */
      console.groupCollapsed(`%c(${event.type}) ${name}`, `color: #ff6e1d;`);
      console.log(`설명: ${event.description}`);
      console.table({ ...params });
      console.groupEnd();
    }
  };
}

const logger = new Logger();

export default logger;
