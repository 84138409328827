import type { SearchJusoAddressAllUnitsQuery$data } from '@/__generated__/SearchJusoAddressAllUnitsQuery.graphql';
import type { JusoAddressForm } from '@/components/Article/Field/Address/SearchJusoAddress';
import SelectJusoUnit from '@/components/Article/Field/Address/SelectJusoUnit';
import { FormControl, TextField } from '@daangn/carotene';

export type JusoUnit = { dongNm: string; floorNm: string; hoNm: string };

type Props = {
  errorMessage?: string;
  units: SearchJusoAddressAllUnitsQuery$data['searchBuildingAllUnits'];
  detailAddress: JusoAddressForm['detailAddress'];
  onDetailAddressChange: (detailAddress: JusoAddressForm['detailAddress']) => void;
};

const SearchJusoDetailAddress = ({
  errorMessage,
  units,
  detailAddress,
  onDetailAddressChange,
}: Props) => {
  const handleSelectUnit = (unit: JusoUnit) => {
    onDetailAddressChange({
      type: 'unit',
      unit,
    });
  };

  const handleSelectManual = () => {
    onDetailAddressChange({
      type: 'manual',
      isRequired: true,
      address: '',
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-[2px]">
        <div className="body-medium-strong">
          상세 주소{' '}
          {detailAddress?.type === 'manual' && !detailAddress.isRequired && (
            <span className="body-medium-default text-fg-neutralMuted">(선택)</span>
          )}
        </div>
        <span className="body-xsmall-default text-fg-neutralSubtle">
          호수는 게시글에 표시되지 않아요
        </span>
      </div>
      {detailAddress?.type === 'unit' && (
        <SelectJusoUnit
          errorMessage={errorMessage}
          units={units}
          onSelectUnit={handleSelectUnit}
          onSelectManual={handleSelectManual}
        />
      )}
      {detailAddress?.type === 'manual' && (
        <FormControl invalid={!!errorMessage} errorMessage={errorMessage}>
          <TextField
            autoFocus
            value={detailAddress.address}
            onChange={(e) =>
              onDetailAddressChange({
                type: 'manual',
                isRequired: detailAddress.isRequired,
                address: e.target.value,
              })
            }
            placeholder="예시) A-1101 또는 A동 1101호"
          />
        </FormControl>
      )}
    </div>
  );
};

export default SearchJusoDetailAddress;
