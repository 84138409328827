import classNames from 'classnames';
import { useEffect, useLayoutEffect, useState } from 'react';
import type { SwiperClass } from 'swiper/react';
import 'swiper/css/pagination';

type Props = {
  swiperRef: React.MutableRefObject<SwiperClass | undefined>;
  omitLastIndex?: boolean;
};

const DOT_SIZE = 6;

const SwiperPagination = ({ swiperRef, omitLastIndex }: Props) => {
  const [count, setCount] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleDotClick = (index: number) => {
    swiperRef.current?.slideTo(index);
  };

  useLayoutEffect(() => {
    const swiper = swiperRef.current;

    if (swiper && !swiper.destroyed) {
      setCount(omitLastIndex ? swiper.slides.length - 1 : swiper.slides.length);
    }
  }, [swiperRef.current]);

  useEffect(() => {
    const swiper = swiperRef.current;

    if (swiper) {
      swiper.on('slideChange', () => {
        const isLast = omitLastIndex
          ? swiper.realIndex === swiper.slides.length - 1
          : swiper.realIndex === swiper.slides.length;

        setActiveIndex(isLast ? swiper.slides.length - 1 : swiper.realIndex);
      });
    }
  }, [swiperRef.current]);

  return (
    <div style={{ minHeight: DOT_SIZE }} className="flex justify-center gap-2">
      {[...Array(count)].map((_, i) => (
        <div
          key={i}
          className={classNames('rounded-full', i === activeIndex ? 'bg-fg-brand' : 'bg-gray400')}
          style={{ width: DOT_SIZE, height: DOT_SIZE }}
          onClick={() => handleDotClick(i)}
        />
      ))}
    </div>
  );
};

export default SwiperPagination;
