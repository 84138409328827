/**
 * @generated SignedSource<<540010ad8f3361658cc30db3b536d024>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ResendIdentificationOtpSmsInput = {
  identificationVerifyRequestId: string;
};
export type VerificationStep_resendSmsMutation$variables = {
  input: ResendIdentificationOtpSmsInput;
};
export type VerificationStep_resendSmsMutation$data = {
  readonly resendIdentificationOtpSms: {
    readonly popupError?: {
      readonly message: string;
    };
    readonly result?: {
      readonly identificationVerifyRequestId: string;
      readonly otpValidDuration: string;
    };
    readonly toastError?: {
      readonly message: string;
    };
  };
};
export type VerificationStep_resendSmsMutation = {
  response: VerificationStep_resendSmsMutation$data;
  variables: VerificationStep_resendSmsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResendIdentificationOtpSms_ResultPayload",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identificationVerifyRequestId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otpValidDuration",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ResendIdentificationOtpSms_Result",
  "abstractKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ToastErrorPayload",
      "kind": "LinkedField",
      "name": "toastError",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ToastError",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupErrorPayload",
      "kind": "LinkedField",
      "name": "popupError",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "PopupError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerificationStep_resendSmsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "resendIdentificationOtpSms",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerificationStep_resendSmsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "resendIdentificationOtpSms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e13f42a432f06707c8a87e4b1a14468",
    "id": null,
    "metadata": {},
    "name": "VerificationStep_resendSmsMutation",
    "operationKind": "mutation",
    "text": "mutation VerificationStep_resendSmsMutation(\n  $input: ResendIdentificationOtpSmsInput!\n) {\n  resendIdentificationOtpSms(input: $input) {\n    __typename\n    ... on ResendIdentificationOtpSms_Result {\n      result {\n        identificationVerifyRequestId\n        otpValidDuration\n      }\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n    ... on PopupError {\n      popupError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b39b7cda8fa199e9d4915e24d2d64a8";

export default node;
