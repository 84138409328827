import { ArticleThumbnail_article$key } from '@/__generated__/ArticleThumbnail_article.graphql';
import { graphql, useFragment } from 'react-relay';
import { Image } from '@daangn/realty-react/ui';

type Props = {
  articleRef: ArticleThumbnail_article$key;
  lazy?: boolean;
};

const ArticleThumbnail = ({ articleRef, lazy = true }: Props) => {
  const article = useFragment(
    graphql`
      fragment ArticleThumbnail_article on Article {
        id
        imageThumbnailUrl
        videoThumbnailUrl
        images {
          id
        }
        videos {
          id
        }
      }
    `,
    articleRef
  );

  const hasImage = article.images.length > 0;

  const thumbnailUrl =
    !hasImage && article.videoThumbnailUrl ? article.videoThumbnailUrl : article.imageThumbnailUrl;

  return (
    <div className="rounded-1.5 h-[118px] w-[118px] flex-shrink-0 overflow-hidden">
      <Image className="h-full w-full object-cover" src={thumbnailUrl} lazy={lazy} />
    </div>
  );
};

export default ArticleThumbnail;
