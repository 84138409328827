/**
 * @generated SignedSource<<ebe00eee62c300daa556e0d8473291f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelListDialog_article$data = {
  readonly id: string;
  readonly originalId: string;
  readonly viewChatChannel: ReadonlyArray<{
    readonly unreadCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"ChannelListDialog_chatChannel">;
  }>;
  readonly " $fragmentType": "ChannelListDialog_article";
};
export type ChannelListDialog_article$key = {
  readonly " $data"?: ChannelListDialog_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelListDialog_article">;
};

import ChannelListDialogRefetchQuery_graphql from './ChannelListDialogRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": ChannelListDialogRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ChannelListDialog_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannel",
      "kind": "LinkedField",
      "name": "viewChatChannel",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChannelListDialog_chatChannel"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "7f1f739327ae8cb8f18f6e0872846a00";

export default node;
