import logger from '@/utils/Logger';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Divider,
} from '@daangn/carotene';
import toast from 'react-hot-toast';

const OLD_HOST = 'https://realty-ceo.daangn.com/';
const NEW_HOST = 'https://realty.daangn.com/';

const RedirectDialog = () => {
  const handleCopy = () => {
    logger.track('redirect_dialog_copy', { href: window.location.href });
    navigator.clipboard
      .writeText(NEW_HOST)
      .then(() => {
        toast.success('주소가 복사되었어요.');
      })
      .catch(() => {
        toast.error('주소 복사에 실패했어요.');
      });
  };

  const handleClose = () => {
    logger.track('redirect_dialog_close', { href: window.location.href });
    window.location.href = NEW_HOST;
  };

  return (
    <Dialog open={true}>
      <DialogContent hideCloseButton>
        <DialogHeader>
          <DialogTitle>URL을 확인해주세요</DialogTitle>
        </DialogHeader>
        <Divider />
        <DialogDescription className="text-fg-neutral flex flex-col items-center justify-center px-4 py-16 text-center">
          <span className="body-medium-default">{OLD_HOST} 주소는 더 이상 사용하지 않아요.</span>
          <span className="body-medium-strong">
            <span className="text-fg-brand">{NEW_HOST}</span> 주소로 접속해 주세요.
          </span>
        </DialogDescription>

        <div className="flex justify-end px-7 pb-5 pt-4">
          <div className="flex gap-2">
            <Button variant="neutral" size="large" onClick={handleCopy}>
              신규 주소 복사
            </Button>
            <Button variant="brand" size="large" onClick={handleClose}>
              확인
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RedirectDialog;
