var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);

// src/apis/realtyClient/index.ts
var BASE_URL = "https://realty.krrt.io";
var RealtyClientAPI = class {
  async fetch(path, options) {
    const response = await fetch(`${BASE_URL}${path}`, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...options == null ? void 0 : options.headers
      }
    });
    if (!response.ok) {
      throw new Error(`API request failed: ${response.status} ${response.statusText}`);
    }
    return response.json();
  }
  async getBuildingLedgerTitle(address) {
    const searchParams = new URLSearchParams({ address });
    return this.fetch(
      `/building-ledger/title?${searchParams.toString()}`
    );
  }
  async getFacilities(params) {
    const searchParams = new URLSearchParams({
      lat: params.coordinate.lat.toString(),
      lon: params.coordinate.lon.toString(),
      minutes: params.minutes.toString()
    });
    return this.fetch(`/facility?${searchParams.toString()}`);
  }
  async postShortenUrl(longUrl) {
    return this.fetch(`/shorten/new`, {
      method: "POST",
      body: JSON.stringify({ longUrl })
    });
  }
};

// src/apis/kakao/index.ts
var KakaoAPI = class {
  constructor(apiKey) {
    __publicField(this, "apiKey");
    this.apiKey = apiKey;
  }
  formatArrayParams(params) {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) searchParams.set(key, value.join(","));
      else searchParams.set(key, String(value));
    });
    return searchParams.toString();
  }
  async fetchAPI(path, options) {
    if (!this.apiKey) {
      throw new Error("Kakao API key is not set");
    }
    const response = await fetch(`https://dapi.kakao.com${path}`, {
      ...options,
      headers: {
        ...options == null ? void 0 : options.headers,
        Authorization: `KakaoAK ${this.apiKey}`
      }
    });
    return response.json();
  }
  async searchAddress({
    query,
    page = 1,
    size = 1,
    analyze_type = "exact"
  }) {
    const result = await this.fetchAPI(
      `/v2/local/search/address.json?${this.formatArrayParams({
        page,
        size,
        analyze_type,
        query
      })}`
    );
    return result;
  }
  async getCoordInfo(keyword) {
    let coordInfo = [];
    const data = await this.searchAddress({
      query: keyword,
      page: 1,
      size: 1,
      analyze_type: "exact"
    });
    if (data.documents[0]) coordInfo = [data.documents[0].x, data.documents[0].y];
    return coordInfo;
  }
  async searchByKeyword(request) {
    const params = {
      page: 1,
      ...request
    };
    const data = await this.fetchAPI(
      `/v2/local/search/keyword.json?${this.formatArrayParams(params)}`
    );
    return data;
  }
  async searchByCategory(request) {
    const data = await this.fetchAPI(
      `/v2/local/search/category.json?${this.formatArrayParams(request)}`
    );
    return data;
  }
};

export {
  __commonJS,
  __toESM,
  __publicField,
  RealtyClientAPI,
  KakaoAPI
};
