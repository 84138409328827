import { ComponentProps } from 'react';
import {
  ArticleTitle_article$data,
  ArticleTitle_article$key,
} from '@/__generated__/ArticleTitle_article.graphql';
import { ArticleStatusEnum } from '@/types/schemaEnums';
import { getArticleStatusText } from '@daangn/realty-sdk';
import { graphql, useFragment } from 'react-relay';
import { Badge } from '@daangn/carotene';
import { readArticleTradeTypeAndPriceText } from '@/utils/article';

enum TitleBadgeType {
  Status = 'status',
  Deny = 'deny',
  Block = 'block',
  HIDE = 'hide',
}

type BadgeListItem = {
  type: TitleBadgeType;
  item: ComponentProps<typeof Badge>;
};

type Props = {
  articleRef: ArticleTitle_article$key;
  hideBadge?: Partial<Record<TitleBadgeType, boolean>>;
};

const ArticleTitle = ({ articleRef, hideBadge }: Props) => {
  const article = useFragment(
    graphql`
      fragment ArticleTitle_article on Article {
        originalId
        invisibleStatus
        status
        isHide
        ...articleTradeTypeAndPriceText_article
      }
    `,
    articleRef
  );

  const badges = getBadgeItems(article, hideBadge);

  return (
    <div className="flex flex-wrap items-center gap-1.5">
      {badges.map(({ item }, i) => (
        <Badge key={i} size="xsmall" {...item} />
      ))}

      <div className="heading-small text-fg-neutral">
        {readArticleTradeTypeAndPriceText(article)}
      </div>
    </div>
  );
};

export default ArticleTitle;

const getArticleStatusBadgeProps = (
  status: `${ArticleStatusEnum}`
): ComponentProps<typeof Badge> | null => {
  if (!['RESERVED', 'TRADED'].includes(status)) {
    return null;
  }

  return {
    tone: status === 'RESERVED' ? 'positive' : 'neutral',
    variant: 'solid',
    children: getArticleStatusText(status),
  };
};

const getBadgeItems = (
  article: ArticleTitle_article$data,
  hideBadge?: Partial<Record<TitleBadgeType, boolean>>
) => {
  return (
    [
      {
        type: TitleBadgeType.Status,
        item: getArticleStatusBadgeProps(article.status),
      },
      article.invisibleStatus === 'CHECK_DENY' && {
        type: TitleBadgeType.Deny,
        item: {
          tone: 'negative',
          children: '승인거절',
        },
      },
      article.invisibleStatus === 'BLOCKED' && {
        type: TitleBadgeType.Block,
        item: {
          tone: 'negative',
          children: '차단',
        },
      },
      article.isHide && {
        type: TitleBadgeType.HIDE,
        item: {
          tone: 'neutral',
          children: '숨김',
        },
      },
    ] as BadgeListItem[]
  ).filter((badge) => !hideBadge?.[badge.type] && badge.item);
};
