import type { SearchJusoAddressAllUnitsQuery$data } from '@/__generated__/SearchJusoAddressAllUnitsQuery.graphql';
import { Button, FormControl, ProgressCircle, TextField } from '@daangn/carotene';
import { IconMagnifyingglassLine, IconXmarkLine } from '@daangn/react-monochrome-icon';
import { highlightText, useSearch } from '@daangn/realty-react';
import { Spacer } from '@daangn/realty-react/ui';
import { searchByFrequencyScore } from '@daangn/realty-sdk';
import { useMemo } from 'react';

export type JusoUnit = { dongNm: string; floorNm: string; hoNm: string };

type Props = {
  errorMessage?: string;
  units: SearchJusoAddressAllUnitsQuery$data['searchBuildingAllUnits'];
  onSelectUnit: (unit: JusoUnit) => void;
  onSelectManual: () => void;
};

export const getUnitText = (unit: JusoUnit) => {
  return [unit.dongNm, unit.floorNm, unit.hoNm].filter(Boolean).join(' ');
};

const SelectJusoUnit = ({ errorMessage, units, onSelectUnit, onSelectManual }: Props) => {
  const { input, setInput, query, reset } = useSearch('');

  const unitsWithDisplayText = useMemo(() => {
    return units.map((unit) => ({
      displayText: getUnitText(unit),
      unit,
    }));
  }, [units]);

  const matchingUnits = useMemo(() => {
    if (!query) {
      return unitsWithDisplayText;
    }

    return searchByFrequencyScore(query, unitsWithDisplayText, (unit) => unit.displayText).slice(
      0,
      20
    );
  }, [query, unitsWithDisplayText]);

  const handleSelectUnit = (unit: JusoUnit) => {
    onSelectUnit(unit);
    setInput(getUnitText(unit));
  };

  return (
    <div className="group">
      <FormControl invalid={!!errorMessage} errorMessage={errorMessage}>
        <TextField
          autoFocus
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="예시) A-1101 또는 A동 1101호"
          suffix={
            input ? (
              <IconXmarkLine size={22} onMouseDown={() => reset()} />
            ) : (
              <IconMagnifyingglassLine size={22} />
            )
          }
        />
      </FormControl>
      <div className="relative hidden group-has-[:focus]:block">
        <div className="rounded-1.5 bg-bg-layerDefault relative top-2 max-h-60 w-full overflow-auto shadow-[0px_4px_6px_0px_rgba(0,0,0,0.15),0px_0px_3px_0px_rgba(0,0,0,0.08)]">
          {input !== query ? (
            <div className="flex h-60 items-center justify-center">
              <ProgressCircle />
            </div>
          ) : (
            <div className="flex flex-col">
              {matchingUnits.map((unit, i) => (
                <p
                  key={i}
                  className="px-4 py-2"
                  onMouseDown={() => {
                    handleSelectUnit(unit.unit);
                  }}
                >
                  {highlightText({
                    fullText: unit.displayText,
                    boldText: query,
                    className: 'text-blue600 font-bold',
                  })}
                </p>
              ))}
              <div className="flex items-center gap-8 px-4 py-2">
                <div className="flex flex-col gap-1">
                  <div className="text-fg-neutral heading-xsmall">
                    찾고 있는 상세 주소가 없으신가요?
                  </div>
                  <div className="body-small-default text-fg-neutralMuted">
                    직접 입력할 수 있어요
                  </div>
                </div>
                <Spacer />
                <Button variant="neutral" size="medium" onMouseDown={onSelectManual}>
                  직접 입력
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectJusoUnit;
