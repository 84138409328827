import { ProgressCircle } from '@daangn/carotene';
import { PropsWithChildren, Suspense } from 'react';

export const SearchResultLoading = () => (
  <div className="flex justify-center py-20">
    <ProgressCircle />
  </div>
);

export const SearchResultDropdown = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className="scrollbar-hide bg-bg-layerDefault relative left-0 right-0 top-0 z-10 flex max-h-60 items-stretch justify-center overflow-auto rounded-md py-2">
      {children}
    </div>
  );
};

export const SearchResultWarning = ({ children }: PropsWithChildren<unknown>) => (
  <div className="text-fg-neutralSubtle body-small-default py-20 text-center">{children}</div>
);

type Props = {
  input: string;
  query: string;
  minInputLength: number;
  children: React.ReactNode;
};

export const SearchResultRenderer = ({ input, query, minInputLength, children }: Props) => {
  return (
    <SearchResultDropdown>
      <Suspense fallback={<SearchResultLoading />}>
        {input.length < minInputLength ? (
          <SearchResultWarning>{minInputLength}글자 이상 입력해주세요</SearchResultWarning>
        ) : input !== query ? (
          <SearchResultLoading />
        ) : (
          children
        )}
      </Suspense>
    </SearchResultDropdown>
  );
};
