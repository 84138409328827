import { CacheConfig, RequestParameters, Variables } from 'relay-runtime';
import type { AxiosRequestConfig } from 'axios';
import { realtyInstance } from '@/api/realty';
import type { GraphQLError } from '@/relay/types';
import { captureException, withScope } from '@sentry/react';

type FetchQueryParams = {
  requestParams: RequestParameters;
  variables: Variables;
  axiosConfig: AxiosRequestConfig;
};

export enum RelayErrorCode {
  /** '오류가 발생했습니다.' */
  Default = 'defaultMessage',
  /** '해당 내용을 찾을 수 없습니다.' */
  RecordNotFound = 'recordNotFound',
  /** '이미 존재하는 내용입니다.' */
  RecordAlreadyExists = 'recordAlreadyExists',
  /** '권한이 없어요.' */
  NoPermission = 'noPermission',
  /** '유저 정보를 찾을 수 없어요.' */
  UserNotFound = 'userNotFound',
  /** '잘못된 요청이에요.' */
  BadRequest = 'badRequest',
}

const fetchBaseQuery = ({ requestParams, variables, axiosConfig }: FetchQueryParams) => {
  return realtyInstance.post(
    '/graphql',
    {
      query: requestParams?.text,
      variables,
    },
    axiosConfig
  );
};

async function fetchGraphQL(
  requestParams: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig
) {
  const axiosConfig = {
    signal: cacheConfig.metadata?.['signal'] as AbortSignal,
    headers: {
      'Content-Type': 'application/json',
    },
    realtyCustomConfig: {
      graphqlRequestParams: requestParams,
    },
  };

  // Fetch data from GitHub's GraphQL API:
  const { data } = await fetchBaseQuery({
    requestParams,
    variables,
    axiosConfig,
  });

  const hasAuthError = data.errors?.some(
    (error: GraphQLError) => error.extensions?.code === RelayErrorCode.UserNotFound
  );

  data.errors?.forEach((error: GraphQLError) => {
    withScope((scope) => {
      scope.setTag('kind', requestParams.operationKind);
      scope.setTag('operationName', requestParams.name);
      scope.setExtra('query', requestParams.text);
      scope.setExtra('variables', variables);
      scope.setExtra('errorResponse', error);
      scope.setExtra('headers', axiosConfig.headers);

      const exception = new Error(error?.message || '');
      exception.name = `Relay Error - [${requestParams.operationKind}][${requestParams.name}]`;

      captureException(exception);

      if (error.extensions) {
        error.extensions.sentryHandled = true;
      }
    });
  });

  if (hasAuthError) {
    window.location.href = '/auth/login';
  }

  return data;
}

export default fetchGraphQL;
