import { cn, useCombinedRefs } from '@daangn/realty-react';
import { CommonRegex } from '@daangn/realty-sdk';
import React, { forwardRef, InputHTMLAttributes, useRef } from 'react';
import Field, { FieldProperty } from './Field';

export interface IdCard {
  first: string;
  last: string;
}

type IdCardFieldProps = FieldProperty & {
  value: IdCard;
  onChange: (value: IdCard) => void;
  onComplete?: () => void;
};

interface DotInputProps extends InputHTMLAttributes<HTMLInputElement> {
  preFilled?: boolean;
}

const DotInput = forwardRef<HTMLInputElement, DotInputProps>(
  ({ value, preFilled, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const combineRef = useCombinedRefs(inputRef, ref);
    const showText = value && !preFilled;

    return (
      <div className="relative flex items-center justify-center">
        <input
          ref={combineRef}
          className={cn('w-full bg-transparent p-0 text-center', !showText && 'text-transparent')}
          type="tel"
          value={value}
          disabled={preFilled}
          maxLength={1}
          {...(preFilled && { tabIndex: -1 })}
          {...props}
        />
        {!showText && (
          <div
            className={cn(
              'absolute left-1/2 top-1/2 h-[10px] w-[10px] -translate-x-1/2 -translate-y-1/2 rounded-full',
              preFilled && 'bg-gray900',
              !preFilled && 'bg-gray600'
            )}
            onClick={() => !preFilled && inputRef.current?.focus()}
          />
        )}
      </div>
    );
  }
);

const IdCardField = React.forwardRef<HTMLInputElement, IdCardFieldProps>(
  ({ value, onChange, invalid, errorMessage, disabled, onComplete, ...props }, ref) => {
    const inputs = useRef<(HTMLInputElement | null)[]>([]);
    const { first, last } = value;

    const validate = (value: string) => {
      return value && !CommonRegex.OnlyNumber.test(value);
    };

    const handleChangeFirst = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (validate(e.target.value)) {
        return;
      }

      onChange({
        first: e.target.value,
        last: value?.last || '',
      });

      if (e.target.value.length >= 6) {
        requestAnimationFrame(() => {
          inputs.current[0]?.focus();
        });
      }
    };

    const handleChangeLast = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (validate(e.target.value)) {
        return;
      }

      onChange({
        first: value?.first || '',
        last: e.target.value.slice(-1),
      });
      onComplete?.();
    };

    const handleDotFocus = () => {
      onChange({
        first: value?.first || '',
        last: '',
      });
    };

    return (
      <Field label="주민등록번호" invalid={invalid} errorMessage={errorMessage} disabled={disabled}>
        <div className="flex items-center gap-6">
          <div className="flex-[1] flex-grow">
            <input
              className="w-full bg-transparent p-0"
              type="tel"
              value={first}
              disabled={disabled}
              placeholder="생년월일 6자리"
              maxLength={6}
              onChange={handleChangeFirst}
              ref={ref}
              {...props}
            />
          </div>
          <span className="text-current">-</span>
          <div className="flex-[1] flex-grow">
            <div className="flex justify-between">
              {[...Array(7)].map((_, i) => (
                <DotInput
                  key={i}
                  ref={(ref) => {
                    inputs.current[i] = ref;
                  }}
                  value={last[i] || ''}
                  preFilled={disabled || i > 0}
                  onChange={handleChangeLast}
                  onFocus={handleDotFocus}
                />
              ))}
            </div>
          </div>
        </div>
      </Field>
    );
  }
);

export default IdCardField;
