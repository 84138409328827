import { z } from 'zod';
import { isNil } from 'lodash-es';
import {
  ArticleBuildingOrientationEnum,
  ArticleManageCostOptionEnum,
  ArticleOptionNameEnum,
  ArticleOptionValueEnum,
  ArticleQualitativeItemEnum,
  BuildingUsageEnum,
  LandPurposeEnum,
  LandTypeEnum,
  ManageCostPayOptionEnum,
  SalesTypeEnum,
  TradeTypeEnum,
} from '@/types/schemaEnums';
import {
  MAX_IMAGE_COUNT,
  MAX_QUALITATIVE_ITEM_COUNT,
  MIN_IMAGE_COUNT,
  MonthlyPayableTypes,
} from '@/constants/article';
import { getRequiredOptionNames } from '@daangn/realty-sdk';

export const MAX_ADDRESS_INFO_LENGTH = 30;
export const MAX_TRADE_DESCRIPTION_LENGTH = 50;

z.setErrorMap((issue) => {
  switch (issue.code) {
    case 'invalid_type': {
      if (issue.received === 'undefined') {
        return {
          message: '필수 항목이에요',
        };
      }
      return {
        message: '잘못된 형식이에요',
      };
    }
    case 'too_big': {
      return {
        message: `최대 ${issue.maximum}까지 입력할 수 있어요.`,
      };
    }
    case 'too_small': {
      return {
        message:
          issue.type === 'string' ? '필수 항목이에요' : `최소 ${issue.minimum}개는 입력해야 해요.`,
      };
    }
  }

  return {
    message: '잘못된 형식이에요.',
  };
});

// Image, Video, and Broker types
const Image = z.object({
  id: z.string(),
  thumbnail: z.string(),
  file: z.string().optional(),
  url: z.string().optional(),
  medium: z.string().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
});

export type ImageType = z.infer<typeof Image>;

const Video = z.object({
  id: z.string(),
  filename: z.string(),
  bigStreamId: z.string(),
});

export type VideoType = z.infer<typeof Video>;

// Coordinate input type
const CoordinateInput = z.object({
  lat: z.string(),
  lon: z.string(),
});

// Additional input types
const ArticleManageCostOptionDetailInput = z.object({
  fixedCost: z.number().int('관리비 고정금액 소수점은 제외해주세요.').optional().nullable(),
  option: z.nativeEnum(ArticleManageCostOptionEnum),
  payOption: z.nativeEnum(ManageCostPayOptionEnum).optional().nullable(),
});

const ArticleOptionInput = z.object({
  name: z.nativeEnum(ArticleOptionNameEnum),
  value: z.nativeEnum(ArticleOptionValueEnum),
});

const ArticleTradeInputV2 = z
  .object({
    adjustable: z.boolean().optional().nullable(),
    description: z.string().max(255, '가격 설명은 255자 이내로 입력해주세요.').nullish(),
    monthlyPay: z.number().int('가격 소수점은 제외해주세요.').nullish(),
    price: z.number().int('가격 소수점은 제외해주세요.').optional(),
    tradeType: z.nativeEnum(TradeTypeEnum),
  })
  .superRefine((trade, ctx) => {
    if (!trade.price) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['price'],
        message: '가격/보증금을 입력해주세요.',
      });
    }

    if (trade.tradeType && MonthlyPayableTypes.includes(trade.tradeType) && !trade.monthlyPay) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['monthlyPay'],
        message: '월세를 입력해주세요.',
      });
    }
  })
  .transform((trade) => ({
    ...trade,
    price: trade.price ?? 0,
  }));

const refineRequiredOptions = (
  {
    salesType,
    requiredOptions,
    availableTotalParkingSpots,
    availableParkingSpotsV2,
  }: z.infer<typeof storeArticleSchema> | z.infer<typeof regularArticleSchema>,
  ctx: z.RefinementCtx
) => {
  const allRequiredOptions = getRequiredOptionNames({ salesType });

  if (
    allRequiredOptions.some(
      (requiredOption) => !requiredOptions?.find((option) => option.name === requiredOption)
    )
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['requiredOptions'],
      message: '대출, 애완동물, 주차 옵션은 필수에요.',
    });
  }

  if (
    requiredOptions?.some((option) => option.name === 'PARKING' && option.value === 'YES') &&
    isNil(availableTotalParkingSpots)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['availableTotalParkingSpots'],
      message: '총 주차대수를 알려주세요.',
    });
  }

  if (
    !isNil(availableTotalParkingSpots) &&
    !isNil(availableParkingSpotsV2) &&
    availableTotalParkingSpots < availableParkingSpotsV2
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['availableParkingSpotsV2'],
      message: '세대당 주차대수는 총 주차대수를 초과할 수 없어요.',
    });
  }
};

const refineManageCost = (
  {
    includeManageCostOption,
    isUnknownManageCost,
    manageCost,
  }: z.infer<typeof regularArticleSchema>,
  ctx: z.RefinementCtx
) => {
  if (!isUnknownManageCost) {
    if (isNil(manageCost)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'number',
        received: 'undefined',
        path: ['manageCost'],
      });
    }

    if (
      includeManageCostOption?.length === 0 ||
      Object.values(ArticleManageCostOptionEnum).some(
        (option) => !includeManageCostOption?.find((o) => o.option === option)
      )
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['includeManageCostOption'],
        message: '관리비 항목을 모두 설정해주세요.',
      });
    }

    Object.values(ArticleManageCostOptionEnum).forEach((option) => {
      const manageCostOption = includeManageCostOption?.find((o) => o.option === option);

      if (!manageCostOption) {
        return;
      }

      if (
        manageCostOption.payOption === ManageCostPayOptionEnum.Fixed &&
        isNil(manageCostOption.fixedCost)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['includeManageCostOption', option, 'fixedCost'],
          message: '관리비 항목의 고정비용을 입력해주세요.',
        });
        return;
      }

      if (
        manageCostOption.payOption === ManageCostPayOptionEnum.Fixed &&
        !Number.isInteger(manageCostOption.fixedCost)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['includeManageCostOption', option, 'fixedCost'],
          message: '관리비 고정금액 소수점을 제외해주세요.',
        });
      }
    });
  }
};

// 공통 필드 스키마
const baseArticleSchema = z.object({
  address: z.string().min(1),
  addressInfo: z.string().optional().nullable(),
  area: z
    .string()
    .min(1)
    .superRefine((area, ctx) => {
      if (isNaN(Number(area))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['area'],
          message: '잘못된 형식이에요.',
        });
      }
    }),
  content: z.string().optional().default(''),
  coordinate: CoordinateInput,
  etcSalesType: z.string().optional().nullable(),
  fullAddress: z.string().optional().nullable(),
  images: z.array(Image).min(MIN_IMAGE_COUNT).max(MAX_IMAGE_COUNT),
  videos: z.array(Video).optional(),
  writerCoordinate: CoordinateInput.optional().nullable(),
  trades: z
    .array(ArticleTradeInputV2)
    .nonempty()
    .transform((trades) => trades.map((trade, i) => ({ ...trade, preferred: i === 0 }))),
  isContactTargetEnabled: z.boolean().nullish(),
  contactTargetPhoneNumber: z.string().nullish(),
});

const buildingArticleSchema = baseArticleSchema.extend({
  supplyArea: z
    .string()
    .nullish()
    .superRefine((area, ctx) => {
      if (!!area && isNaN(Number(area))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['area'],
          message: '잘못된 형식이에요.',
        });
      }
    }),
  buildingOrientation: z.nativeEnum(ArticleBuildingOrientationEnum),
  buildingUsage: z.nativeEnum(BuildingUsageEnum),
  floor: z.string().optional().nullable(),
  corRealtyId: z.string().nullish(),
  moveInDate: z.string().date(),
  topFloor: z.string().min(1),
  buildingApprovalDate: z.string().date(),
  requiredOptions: z.array(ArticleOptionInput),
  availableTotalParkingSpots: z.number().nullish(),
  availableParkingSpotsV2: z.number().nullish(),
});
// 상가 매물 스키마
const storeArticleSchema = buildingArticleSchema.extend({
  salesType: z.literal(SalesTypeEnum.Store),
  premiumMoney: z
    .number({
      required_error: '권리금을 입력해주세요.',
      invalid_type_error: '권리금을 입력해주세요.',
    })
    .min(1, '권리금을 입력해주세요.')
    .nullable(),
  premiumMoneyDescription: z.string().nullish(),
});

// 일반 매물 스키마 (상가 외)
const regularArticleSchema = buildingArticleSchema.extend({
  salesType: z.enum([
    SalesTypeEnum.OpenOneRoom,
    SalesTypeEnum.SplitOneRoom,
    SalesTypeEnum.TwoRoom,
    SalesTypeEnum.Apart,
    SalesTypeEnum.Officetel,
  ]),
  bathroomCnt: z.number().int('화장실 개수 소수점은 제외해주세요.'),
  roomCnt: z.number().int('방 수 소수점은 제외해주세요.'),
  options: z.array(ArticleOptionInput).optional().nullable(),
  excludeManageCostOption: z.array(z.nativeEnum(ArticleManageCostOptionEnum)).nullish(),
  etcManageCost: z.number().nullish(),
  includeManageCostOption: z.array(ArticleManageCostOptionDetailInput).nullish(),
  isUnknownManageCost: z.boolean().nullish(),
  manageCost: z.number().nullish(),
  qualitativeItems: z
    .array(z.nativeEnum(ArticleQualitativeItemEnum))
    .max(MAX_QUALITATIVE_ITEM_COUNT)
    .optional(),
});

// 토지 매물 스키마
const landArticleSchema = baseArticleSchema.extend({
  salesType: z.literal(SalesTypeEnum.Etc),
  landType: z.nativeEnum(LandTypeEnum),
  landPurpose: z.nativeEnum(LandPurposeEnum).nullish(),
  corRealtyId: z.string(),
});

export const articleFormScheme = z
  .discriminatedUnion('salesType', [storeArticleSchema, regularArticleSchema, landArticleSchema])
  .superRefine((v, ctx) => {
    if (v.salesType === SalesTypeEnum.Etc) {
      return;
    }

    if (v.salesType === SalesTypeEnum.Store) {
      refineRequiredOptions(v, ctx);
    } else {
      refineManageCost(v, ctx);
      refineRequiredOptions(v, ctx);
    }
  });

export type ArticleFormInputType = z.input<typeof articleFormScheme>;
export type ArticleFormType = z.infer<typeof articleFormScheme>;
