import {
  CommonRegex,
  addDays,
  billionConvert,
  checkCanMoveInNow,
  constructFrom,
  enUS,
  format,
  formatDate,
  getDefaultOptions,
  getISOWeek,
  getTimezoneOffsetInMilliseconds,
  getWeek,
  getWeekYear,
  isProtectedDayOfYearToken,
  isProtectedWeekYearToken,
  ko,
  loadScript,
  longFormatters,
  millisecondsInHour,
  millisecondsInMinute,
  millisecondsInSecond,
  normalizeDates,
  startOfISOWeek,
  startOfWeek,
  toDate,
  truthy,
  warnOrThrowProtectedError
} from "./chunk-VH26F5IY.mjs";
import {
  KakaoAPI,
  __publicField
} from "./chunk-OU5M4E7S.mjs";

// src/services/address/utils.ts
var parseAddress = (address) => {
  var _a, _b;
  const validAddress = address.replace(/\s\s+/g, " ").replace(CommonRegex.invalidAddress, " ").replace(/\s([0-9-]+번?)길([0-9-]+)\s/g, (_, p1, p2) => ` ${p1}\uAE38 ${p2} `).replace(/([0-9-]+)(\(\S+\))/g, (_, p1, p2) => `${p1} ${p2}`);
  let addressOnly = validAddress;
  if (validAddress.match(CommonRegex.roadCorToAddress)) {
    addressOnly = validAddress.replace(CommonRegex.roadCorToAddress, (_, p1) => p1);
  } else if (validAddress.match(CommonRegex.corToAddress)) {
    addressOnly = validAddress.replace(CommonRegex.corToAddress, (_, p1) => p1);
  }
  addressOnly = addressOnly.trim();
  const remainAddress = validAddress.replace(addressOnly, "");
  const possiblyBuildingName = (_b = (_a = remainAddress.match(CommonRegex.BeforeDetailAddress)) == null ? void 0 : _a[1]) == null ? void 0 : _b.trim();
  const addressWithBuilding = [addressOnly, possiblyBuildingName].filter(Boolean).join(" ");
  return {
    addressOnly,
    addressWithBuilding,
    possiblyBuildingName
  };
};
var CoordinateFinder = class {
  constructor({ kakaoMapKey, googleMapKey }) {
    __publicField(this, "coordinateFinders");
    __publicField(this, "findByAddress", async (address) => {
      for (const finder of this.coordinateFinders) {
        try {
          const result = await finder(address);
          if (result.length > 0) {
            return result;
          }
        } catch (e) {
        }
      }
      return [];
    });
    const kakaoAPI = new KakaoAPI(kakaoMapKey);
    this.coordinateFinders = [
      async (address) => {
        let coordInfo = [];
        const data = await kakaoAPI.searchAddress({
          query: address,
          page: 1,
          size: 1,
          analyze_type: "exact"
        });
        if (data.documents[0]) coordInfo = [data.documents[0].x, data.documents[0].y];
        return coordInfo;
      },
      async (address) => {
        await loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}`);
        return new google.maps.Geocoder().geocode({ address }).then(({ results }) => {
          if (results == null ? void 0 : results[0]) {
            const { lat, lng } = results[0].geometry.location;
            return [String(lng()), String(lat())];
          }
          return [];
        });
      }
    ];
  }
};
var parseAddressDetail = ({ address }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const eupmyeonMatched = (_a = address.match(/([^ []+읍|[^ []+면)/)) == null ? void 0 : _a[0];
  const dongleeMatched = (_b = address.match(
    /([^ []+동|[^ []+리|[^ []+가)(?= \d+-?\d*([\]|\s외])?|$)/
  )) == null ? void 0 : _b[0];
  const match = address.match(/(동 |리 |가 )(\d+-?\d*)([\]|\s외]|$)/);
  const bunjiMatched = match && match[2] ? match[2] : "";
  const bunjiSplited = bunjiMatched.split("-");
  const parsedAddressNames = {
    sigunguName: (_d = (_c = address.match(/[가-힣]+[도시군구]\s(?:[가-힣]+[시군구]\s)?(?:[가-힣]+[시군구]\s)?/g)) == null ? void 0 : _c[0].trim()) != null ? _d : "",
    eupmyeondongName: (_e = eupmyeonMatched != null ? eupmyeonMatched : dongleeMatched) != null ? _e : "",
    leeName: eupmyeonMatched ? dongleeMatched != null ? dongleeMatched : "" : "",
    bun: (_f = bunjiSplited[0]) != null ? _f : "",
    ji: (_g = bunjiSplited[1]) != null ? _g : ""
  };
  return parsedAddressNames;
};
var getJibunAddress = (address) => {
  const { sigunguName, eupmyeondongName, leeName, bun, ji } = parseAddressDetail({
    address
  });
  const mainAddress = [sigunguName, eupmyeondongName, leeName].filter(Boolean).join(" ");
  const detailAddress = [bun, ji].filter(Boolean).join("-");
  return [mainAddress, detailAddress].filter(Boolean).join(" ");
};
var removeDetailFromAddress = (address) => {
  const parsedAddress = address.replace(/제(\d+(동|호|층))/g, "$1").replace(/\[.+\]$/g, "");
  return parsedAddress;
};
var checkIsDagaguRentalHouse = ({
  addressKind,
  isRentalHouse
}) => {
  return addressKind === "BUILDING" && isRentalHouse;
};

// src/services/address/kakao.ts
var KakaoCategory = /* @__PURE__ */ ((KakaoCategory2) => {
  KakaoCategory2["LargeMarket"] = "MT1";
  KakaoCategory2["ConvenienceStore"] = "CS2";
  KakaoCategory2["NurseryAndKindergarten"] = "PS3";
  KakaoCategory2["School"] = "SC4";
  KakaoCategory2["Academy"] = "AC5";
  KakaoCategory2["ParkingLot"] = "PK6";
  KakaoCategory2["FuelAndChargingStation"] = "OL7";
  KakaoCategory2["SubwayStation"] = "SW8";
  KakaoCategory2["Bank"] = "BK9";
  KakaoCategory2["CulturalFacility"] = "CT1";
  KakaoCategory2["Brokerage"] = "AG2";
  KakaoCategory2["PublicOffice"] = "PO3";
  KakaoCategory2["TouristAttraction"] = "AT4";
  KakaoCategory2["Accommodation"] = "AD5";
  KakaoCategory2["Restaurant"] = "FD6";
  KakaoCategory2["Cafe"] = "CE7";
  KakaoCategory2["Hospital"] = "HP8";
  KakaoCategory2["Pharmacy"] = "PM9";
  return KakaoCategory2;
})(KakaoCategory || {});

// src/services/article/constants.ts
var SEMI_BASEMENT_FLOOR = "0.5";
var \uD3C9\uB2F9_\uC81C\uACF1\uBBF8\uD130 = 3.305785;
var ArticleUploadType = /* @__PURE__ */ ((ArticleUploadType2) => {
  ArticleUploadType2["Direct"] = "direct";
  ArticleUploadType2["Broker"] = "broker";
  return ArticleUploadType2;
})(ArticleUploadType || {});
var TradeTypes = ["YEAR", "MONTH", "BORROW", "BUY", "SHORT"];
var MonthlyPayableTradeTypes = ["MONTH", "YEAR", "SHORT"];
var AdjustableTradeTypes = ["MONTH", "YEAR"];
var ArticleStatuses = ["ON_GOING", "RESERVED", "TRADED"];
var OneRoomSalesTypes = ["OPEN_ONE_ROOM", "SPLIT_ONE_ROOM"];
var ResidentSalesTypes = [
  ...OneRoomSalesTypes,
  "APART",
  "TWO_ROOM",
  "OFFICETEL"
];
var BuildingNameAvailableSalesTypes = ["APART", "OFFICETEL"];
var SupplyAreaFirstSalesTypes = ["APART", "STORE"];
var ArticleOptionValues = ["DONT_KNOW", "YES", "NO"];
var RequiredArticleOptionNames = [
  "MORTGAGE",
  "PET",
  "PARKING"
];
var EtcArticleOptionNames = [
  "ELEVATOR",
  "LOFT",
  "ROOFTOP",
  "WASHER",
  "FRIDGE",
  "AIRCON",
  "ELEC_RANGE",
  "GAS_RANGE",
  "INDUCTION",
  "BED"
];
var WriterTypes = ["TENANT", "LESSOR"];
var MainBuildingUsageTypes = [
  "PUBLIC_HOUSING",
  "SINGLE_FAMILY_HOUSING",
  "TYPE_1_NEIGHBORHOOD_LIVING_FACILITY",
  "TYPE_2_NEIGHBORHOOD_LIVING_FACILITY",
  "OFFICE_FACILITY",
  "ACCOMMODATION_FACILITY",
  "CULTURAL_AND_ASSEMBLY_FACILITIES"
];
var EtcBuildingUsageTypes = [
  "RELIGIOUS_FACILITY",
  "SALE_FACILITY",
  "TRANSPORTATION_FACILITY",
  "MEDICAL_FACILITY",
  "EDUCATION_AND_RESEARCH_FACILITY",
  "ELDERLY_FACILITY",
  "STUDY_FACILITY",
  "EXERCISE_FACILITY",
  "RECREATION_FACILITY",
  "FACTORY",
  "WAREHOUSE_FACILITY",
  "TEMPORARY_BUILDING",
  "SEWAGE_AND_WASTE_PROCESSING_FACILITY",
  "CORRECTION_AND_MILITARY_FACILITY",
  "BROADCASTING_AND_COMMUNICATION_FACILITY",
  "POWER_GENERATION_FACILITY",
  "CEMETERY_RELATED_FACILITY",
  "TOURISM_AND_RECREATION_FACILITY",
  "FUNERAL_HOME",
  "CAR_RELATED_FACILITY",
  "HAZARDOUS_MATERIAL_FACILITY",
  "DOOR_AND_PLANT_RELATED_FACILITY"
];
var BuildingUsageTypes = [
  ...MainBuildingUsageTypes,
  ...EtcBuildingUsageTypes
];
var ManageCostOptions = [
  "WATERWORKS",
  "ELECTRIC",
  "INTERNET",
  "GAS",
  "CLEANING",
  "TV",
  "PARKING",
  "BOILER",
  "ELEVATOR"
];
var ManageCostOptionText = {
  WATERWORKS: "\uC218\uB3C4\uB8CC",
  ELECTRIC: "\uC804\uAE30\uB8CC",
  INTERNET: "\uC778\uD130\uB137\uBE44",
  GAS: "\uAC00\uC2A4\uBE44",
  CLEANING: "\uCCAD\uC18C\uBE44",
  TV: "\uC720\uC120 TV",
  PARKING: "\uC8FC\uCC28\uBE44",
  BOILER: "\uB09C\uBC29\uBE44",
  ELEVATOR: "\uC2B9\uAC15\uAE30 \uC720\uC9C0\uBE44"
};
var LandTypes = [
  "BANK",
  "DITCH",
  "DRY_PADDY_FIELD",
  "FACTORY_SITE",
  "FISH_FARM",
  "FORESTRY",
  "GAS_STATION_SITE",
  "GRAVEYARD",
  "GYMNASTIC_SITE",
  "HISTORIC_SITE",
  "MARSH",
  "MINERAL_SPRING_SITE",
  "MISCELLANEOUS_LAND",
  "ORCHARD",
  "PADDY_FIELD",
  "PARK",
  "PARKING_LOT",
  "PASTURE",
  "RAILWAY_SITE",
  "RECREATION_AREA",
  "RELIGION_SITE",
  "RIVER",
  "ROAD",
  "SALTERN",
  "SCHOOL_SITE",
  "SITE",
  "WAREHOUSE_SITE",
  "WATER_SUPPLY_SITE"
];
var LandPurposes = [
  "AGRICULTURAL_AND_FOREST_AREA",
  "CONTROL_AREA",
  "NATURAL_CONSERVATION_AREA",
  "URBAN_AREA"
];

// src/services/location/constants.ts
var \uC81C\uC8FC\uB3C4_\uAD6C\uBD84_\uC704\uB3C4 = 34;

// ../../node_modules/date-fns/getDefaultOptions.js
function getDefaultOptions2() {
  return Object.assign({}, getDefaultOptions());
}

// ../../node_modules/date-fns/getISODay.js
function getISODay(date, options) {
  const day = toDate(date, options == null ? void 0 : options.in).getDay();
  return day === 0 ? 7 : day;
}

// ../../node_modules/date-fns/transpose.js
function transpose(date, constructor) {
  const date_ = isConstructor(constructor) ? new constructor(0) : constructFrom(constructor, 0);
  date_.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  date_.setHours(
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
  return date_;
}
function isConstructor(constructor) {
  var _a;
  return typeof constructor === "function" && ((_a = constructor.prototype) == null ? void 0 : _a.constructor) === constructor;
}

// ../../node_modules/date-fns/parse/_lib/Setter.js
var TIMEZONE_UNIT_PRIORITY = 10;
var Setter = class {
  constructor() {
    __publicField(this, "subPriority", 0);
  }
  validate(_utcDate, _options) {
    return true;
  }
};
var ValueSetter = class extends Setter {
  constructor(value, validateValue, setValue, priority, subPriority) {
    super();
    this.value = value;
    this.validateValue = validateValue;
    this.setValue = setValue;
    this.priority = priority;
    if (subPriority) {
      this.subPriority = subPriority;
    }
  }
  validate(date, options) {
    return this.validateValue(date, this.value, options);
  }
  set(date, flags, options) {
    return this.setValue(date, flags, this.value, options);
  }
};
var DateTimezoneSetter = class extends Setter {
  constructor(context, reference) {
    super();
    __publicField(this, "priority", TIMEZONE_UNIT_PRIORITY);
    __publicField(this, "subPriority", -1);
    this.context = context || ((date) => constructFrom(reference, date));
  }
  set(date, flags) {
    if (flags.timestampIsSet) return date;
    return constructFrom(date, transpose(date, this.context));
  }
};

// ../../node_modules/date-fns/parse/_lib/Parser.js
var Parser = class {
  run(dateString, token, match, options) {
    const result = this.parse(dateString, token, match, options);
    if (!result) {
      return null;
    }
    return {
      setter: new ValueSetter(
        result.value,
        this.validate,
        this.set,
        this.priority,
        this.subPriority
      ),
      rest: result.rest
    };
  }
  validate(_utcDate, _value, _options) {
    return true;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/EraParser.js
var EraParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 140);
    __publicField(this, "incompatibleTokens", ["R", "u", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      // AD, BC
      case "G":
      case "GG":
      case "GGG":
        return match.era(dateString, { width: "abbreviated" }) || match.era(dateString, { width: "narrow" });
      // A, B
      case "GGGGG":
        return match.era(dateString, { width: "narrow" });
      // Anno Domini, Before Christ
      case "GGGG":
      default:
        return match.era(dateString, { width: "wide" }) || match.era(dateString, { width: "abbreviated" }) || match.era(dateString, { width: "narrow" });
    }
  }
  set(date, flags, value) {
    flags.era = value;
    date.setFullYear(value, 0, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/constants.js
var numericPatterns = {
  month: /^(1[0-2]|0?\d)/,
  // 0 to 12
  date: /^(3[0-1]|[0-2]?\d)/,
  // 0 to 31
  dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
  // 0 to 366
  week: /^(5[0-3]|[0-4]?\d)/,
  // 0 to 53
  hour23h: /^(2[0-3]|[0-1]?\d)/,
  // 0 to 23
  hour24h: /^(2[0-4]|[0-1]?\d)/,
  // 0 to 24
  hour11h: /^(1[0-1]|0?\d)/,
  // 0 to 11
  hour12h: /^(1[0-2]|0?\d)/,
  // 0 to 12
  minute: /^[0-5]?\d/,
  // 0 to 59
  second: /^[0-5]?\d/,
  // 0 to 59
  singleDigit: /^\d/,
  // 0 to 9
  twoDigits: /^\d{1,2}/,
  // 0 to 99
  threeDigits: /^\d{1,3}/,
  // 0 to 999
  fourDigits: /^\d{1,4}/,
  // 0 to 9999
  anyDigitsSigned: /^-?\d+/,
  singleDigitSigned: /^-?\d/,
  // 0 to 9, -0 to -9
  twoDigitsSigned: /^-?\d{1,2}/,
  // 0 to 99, -0 to -99
  threeDigitsSigned: /^-?\d{1,3}/,
  // 0 to 999, -0 to -999
  fourDigitsSigned: /^-?\d{1,4}/
  // 0 to 9999, -0 to -9999
};
var timezonePatterns = {
  basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
  basic: /^([+-])(\d{2})(\d{2})|Z/,
  basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
  extended: /^([+-])(\d{2}):(\d{2})|Z/,
  extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
};

// ../../node_modules/date-fns/parse/_lib/utils.js
function mapValue(parseFnResult, mapFn) {
  if (!parseFnResult) {
    return parseFnResult;
  }
  return {
    value: mapFn(parseFnResult.value),
    rest: parseFnResult.rest
  };
}
function parseNumericPattern(pattern, dateString) {
  const matchResult = dateString.match(pattern);
  if (!matchResult) {
    return null;
  }
  return {
    value: parseInt(matchResult[0], 10),
    rest: dateString.slice(matchResult[0].length)
  };
}
function parseTimezonePattern(pattern, dateString) {
  const matchResult = dateString.match(pattern);
  if (!matchResult) {
    return null;
  }
  if (matchResult[0] === "Z") {
    return {
      value: 0,
      rest: dateString.slice(1)
    };
  }
  const sign = matchResult[1] === "+" ? 1 : -1;
  const hours = matchResult[2] ? parseInt(matchResult[2], 10) : 0;
  const minutes = matchResult[3] ? parseInt(matchResult[3], 10) : 0;
  const seconds = matchResult[5] ? parseInt(matchResult[5], 10) : 0;
  return {
    value: sign * (hours * millisecondsInHour + minutes * millisecondsInMinute + seconds * millisecondsInSecond),
    rest: dateString.slice(matchResult[0].length)
  };
}
function parseAnyDigitsSigned(dateString) {
  return parseNumericPattern(numericPatterns.anyDigitsSigned, dateString);
}
function parseNDigits(n, dateString) {
  switch (n) {
    case 1:
      return parseNumericPattern(numericPatterns.singleDigit, dateString);
    case 2:
      return parseNumericPattern(numericPatterns.twoDigits, dateString);
    case 3:
      return parseNumericPattern(numericPatterns.threeDigits, dateString);
    case 4:
      return parseNumericPattern(numericPatterns.fourDigits, dateString);
    default:
      return parseNumericPattern(new RegExp("^\\d{1," + n + "}"), dateString);
  }
}
function parseNDigitsSigned(n, dateString) {
  switch (n) {
    case 1:
      return parseNumericPattern(numericPatterns.singleDigitSigned, dateString);
    case 2:
      return parseNumericPattern(numericPatterns.twoDigitsSigned, dateString);
    case 3:
      return parseNumericPattern(numericPatterns.threeDigitsSigned, dateString);
    case 4:
      return parseNumericPattern(numericPatterns.fourDigitsSigned, dateString);
    default:
      return parseNumericPattern(new RegExp("^-?\\d{1," + n + "}"), dateString);
  }
}
function dayPeriodEnumToHours(dayPeriod) {
  switch (dayPeriod) {
    case "morning":
      return 4;
    case "evening":
      return 17;
    case "pm":
    case "noon":
    case "afternoon":
      return 12;
    case "am":
    case "midnight":
    case "night":
    default:
      return 0;
  }
}
function normalizeTwoDigitYear(twoDigitYear, currentYear) {
  const isCommonEra = currentYear > 0;
  const absCurrentYear = isCommonEra ? currentYear : 1 - currentYear;
  let result;
  if (absCurrentYear <= 50) {
    result = twoDigitYear || 100;
  } else {
    const rangeEnd = absCurrentYear + 50;
    const rangeEndCentury = Math.trunc(rangeEnd / 100) * 100;
    const isPreviousCentury = twoDigitYear >= rangeEnd % 100;
    result = twoDigitYear + rangeEndCentury - (isPreviousCentury ? 100 : 0);
  }
  return isCommonEra ? result : 1 - result;
}
function isLeapYearIndex(year) {
  return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0;
}

// ../../node_modules/date-fns/parse/_lib/parsers/YearParser.js
var YearParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 130);
    __publicField(this, "incompatibleTokens", ["Y", "R", "u", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(dateString, token, match) {
    const valueCallback = (year) => ({
      year,
      isTwoDigitYear: token === "yy"
    });
    switch (token) {
      case "y":
        return mapValue(parseNDigits(4, dateString), valueCallback);
      case "yo":
        return mapValue(
          match.ordinalNumber(dateString, {
            unit: "year"
          }),
          valueCallback
        );
      default:
        return mapValue(parseNDigits(token.length, dateString), valueCallback);
    }
  }
  validate(_date, value) {
    return value.isTwoDigitYear || value.year > 0;
  }
  set(date, flags, value) {
    const currentYear = date.getFullYear();
    if (value.isTwoDigitYear) {
      const normalizedTwoDigitYear = normalizeTwoDigitYear(
        value.year,
        currentYear
      );
      date.setFullYear(normalizedTwoDigitYear, 0, 1);
      date.setHours(0, 0, 0, 0);
      return date;
    }
    const year = !("era" in flags) || flags.era === 1 ? value.year : 1 - value.year;
    date.setFullYear(year, 0, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/LocalWeekYearParser.js
var LocalWeekYearParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 130);
    __publicField(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    const valueCallback = (year) => ({
      year,
      isTwoDigitYear: token === "YY"
    });
    switch (token) {
      case "Y":
        return mapValue(parseNDigits(4, dateString), valueCallback);
      case "Yo":
        return mapValue(
          match.ordinalNumber(dateString, {
            unit: "year"
          }),
          valueCallback
        );
      default:
        return mapValue(parseNDigits(token.length, dateString), valueCallback);
    }
  }
  validate(_date, value) {
    return value.isTwoDigitYear || value.year > 0;
  }
  set(date, flags, value, options) {
    const currentYear = getWeekYear(date, options);
    if (value.isTwoDigitYear) {
      const normalizedTwoDigitYear = normalizeTwoDigitYear(
        value.year,
        currentYear
      );
      date.setFullYear(
        normalizedTwoDigitYear,
        0,
        options.firstWeekContainsDate
      );
      date.setHours(0, 0, 0, 0);
      return startOfWeek(date, options);
    }
    const year = !("era" in flags) || flags.era === 1 ? value.year : 1 - value.year;
    date.setFullYear(year, 0, options.firstWeekContainsDate);
    date.setHours(0, 0, 0, 0);
    return startOfWeek(date, options);
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/ISOWeekYearParser.js
var ISOWeekYearParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 130);
    __publicField(this, "incompatibleTokens", [
      "G",
      "y",
      "Y",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token) {
    if (token === "R") {
      return parseNDigitsSigned(4, dateString);
    }
    return parseNDigitsSigned(token.length, dateString);
  }
  set(date, _flags, value) {
    const firstWeekOfYear = constructFrom(date, 0);
    firstWeekOfYear.setFullYear(value, 0, 4);
    firstWeekOfYear.setHours(0, 0, 0, 0);
    return startOfISOWeek(firstWeekOfYear);
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/ExtendedYearParser.js
var ExtendedYearParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 130);
    __publicField(this, "incompatibleTokens", ["G", "y", "Y", "R", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(dateString, token) {
    if (token === "u") {
      return parseNDigitsSigned(4, dateString);
    }
    return parseNDigitsSigned(token.length, dateString);
  }
  set(date, _flags, value) {
    date.setFullYear(value, 0, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/QuarterParser.js
var QuarterParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 120);
    __publicField(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    switch (token) {
      // 1, 2, 3, 4
      case "Q":
      case "QQ":
        return parseNDigits(token.length, dateString);
      // 1st, 2nd, 3rd, 4th
      case "Qo":
        return match.ordinalNumber(dateString, { unit: "quarter" });
      // Q1, Q2, Q3, Q4
      case "QQQ":
        return match.quarter(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.quarter(dateString, {
          width: "narrow",
          context: "formatting"
        });
      // 1, 2, 3, 4 (narrow quarter; could be not numerical)
      case "QQQQQ":
        return match.quarter(dateString, {
          width: "narrow",
          context: "formatting"
        });
      // 1st quarter, 2nd quarter, ...
      case "QQQQ":
      default:
        return match.quarter(dateString, {
          width: "wide",
          context: "formatting"
        }) || match.quarter(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.quarter(dateString, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 4;
  }
  set(date, _flags, value) {
    date.setMonth((value - 1) * 3, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/StandAloneQuarterParser.js
var StandAloneQuarterParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 120);
    __publicField(this, "incompatibleTokens", [
      "Y",
      "R",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    switch (token) {
      // 1, 2, 3, 4
      case "q":
      case "qq":
        return parseNDigits(token.length, dateString);
      // 1st, 2nd, 3rd, 4th
      case "qo":
        return match.ordinalNumber(dateString, { unit: "quarter" });
      // Q1, Q2, Q3, Q4
      case "qqq":
        return match.quarter(dateString, {
          width: "abbreviated",
          context: "standalone"
        }) || match.quarter(dateString, {
          width: "narrow",
          context: "standalone"
        });
      // 1, 2, 3, 4 (narrow quarter; could be not numerical)
      case "qqqqq":
        return match.quarter(dateString, {
          width: "narrow",
          context: "standalone"
        });
      // 1st quarter, 2nd quarter, ...
      case "qqqq":
      default:
        return match.quarter(dateString, {
          width: "wide",
          context: "standalone"
        }) || match.quarter(dateString, {
          width: "abbreviated",
          context: "standalone"
        }) || match.quarter(dateString, {
          width: "narrow",
          context: "standalone"
        });
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 4;
  }
  set(date, _flags, value) {
    date.setMonth((value - 1) * 3, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/MonthParser.js
var MonthParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "L",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
    __publicField(this, "priority", 110);
  }
  parse(dateString, token, match) {
    const valueCallback = (value) => value - 1;
    switch (token) {
      // 1, 2, ..., 12
      case "M":
        return mapValue(
          parseNumericPattern(numericPatterns.month, dateString),
          valueCallback
        );
      // 01, 02, ..., 12
      case "MM":
        return mapValue(parseNDigits(2, dateString), valueCallback);
      // 1st, 2nd, ..., 12th
      case "Mo":
        return mapValue(
          match.ordinalNumber(dateString, {
            unit: "month"
          }),
          valueCallback
        );
      // Jan, Feb, ..., Dec
      case "MMM":
        return match.month(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.month(dateString, { width: "narrow", context: "formatting" });
      // J, F, ..., D
      case "MMMMM":
        return match.month(dateString, {
          width: "narrow",
          context: "formatting"
        });
      // January, February, ..., December
      case "MMMM":
      default:
        return match.month(dateString, { width: "wide", context: "formatting" }) || match.month(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.month(dateString, { width: "narrow", context: "formatting" });
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 11;
  }
  set(date, _flags, value) {
    date.setMonth(value, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/StandAloneMonthParser.js
var StandAloneMonthParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 110);
    __publicField(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    const valueCallback = (value) => value - 1;
    switch (token) {
      // 1, 2, ..., 12
      case "L":
        return mapValue(
          parseNumericPattern(numericPatterns.month, dateString),
          valueCallback
        );
      // 01, 02, ..., 12
      case "LL":
        return mapValue(parseNDigits(2, dateString), valueCallback);
      // 1st, 2nd, ..., 12th
      case "Lo":
        return mapValue(
          match.ordinalNumber(dateString, {
            unit: "month"
          }),
          valueCallback
        );
      // Jan, Feb, ..., Dec
      case "LLL":
        return match.month(dateString, {
          width: "abbreviated",
          context: "standalone"
        }) || match.month(dateString, { width: "narrow", context: "standalone" });
      // J, F, ..., D
      case "LLLLL":
        return match.month(dateString, {
          width: "narrow",
          context: "standalone"
        });
      // January, February, ..., December
      case "LLLL":
      default:
        return match.month(dateString, { width: "wide", context: "standalone" }) || match.month(dateString, {
          width: "abbreviated",
          context: "standalone"
        }) || match.month(dateString, { width: "narrow", context: "standalone" });
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 11;
  }
  set(date, _flags, value) {
    date.setMonth(value, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/setWeek.js
function setWeek(date, week, options) {
  const date_ = toDate(date, options == null ? void 0 : options.in);
  const diff = getWeek(date_, options) - week;
  date_.setDate(date_.getDate() - diff * 7);
  return toDate(date_, options == null ? void 0 : options.in);
}

// ../../node_modules/date-fns/parse/_lib/parsers/LocalWeekParser.js
var LocalWeekParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 100);
    __publicField(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "w":
        return parseNumericPattern(numericPatterns.week, dateString);
      case "wo":
        return match.ordinalNumber(dateString, { unit: "week" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 53;
  }
  set(date, _flags, value, options) {
    return startOfWeek(setWeek(date, value, options), options);
  }
};

// ../../node_modules/date-fns/setISOWeek.js
function setISOWeek(date, week, options) {
  const _date = toDate(date, options == null ? void 0 : options.in);
  const diff = getISOWeek(_date, options) - week;
  _date.setDate(_date.getDate() - diff * 7);
  return _date;
}

// ../../node_modules/date-fns/parse/_lib/parsers/ISOWeekParser.js
var ISOWeekParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 100);
    __publicField(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "I":
        return parseNumericPattern(numericPatterns.week, dateString);
      case "Io":
        return match.ordinalNumber(dateString, { unit: "week" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 53;
  }
  set(date, _flags, value) {
    return startOfISOWeek(setISOWeek(date, value));
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/DateParser.js
var DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
var DAYS_IN_MONTH_LEAP_YEAR = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
];
var DateParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 90);
    __publicField(this, "subPriority", 1);
    __publicField(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "d":
        return parseNumericPattern(numericPatterns.date, dateString);
      case "do":
        return match.ordinalNumber(dateString, { unit: "date" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(date, value) {
    const year = date.getFullYear();
    const isLeapYear = isLeapYearIndex(year);
    const month = date.getMonth();
    if (isLeapYear) {
      return value >= 1 && value <= DAYS_IN_MONTH_LEAP_YEAR[month];
    } else {
      return value >= 1 && value <= DAYS_IN_MONTH[month];
    }
  }
  set(date, _flags, value) {
    date.setDate(value);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/DayOfYearParser.js
var DayOfYearParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 90);
    __publicField(this, "subpriority", 1);
    __publicField(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "E",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "D":
      case "DD":
        return parseNumericPattern(numericPatterns.dayOfYear, dateString);
      case "Do":
        return match.ordinalNumber(dateString, { unit: "date" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(date, value) {
    const year = date.getFullYear();
    const isLeapYear = isLeapYearIndex(year);
    if (isLeapYear) {
      return value >= 1 && value <= 366;
    } else {
      return value >= 1 && value <= 365;
    }
  }
  set(date, _flags, value) {
    date.setMonth(0, value);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/setDay.js
function setDay(date, day, options) {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const defaultOptions = getDefaultOptions();
  const weekStartsOn = (_h = (_g = (_d = (_c = options == null ? void 0 : options.weekStartsOn) != null ? _c : (_b = (_a = options == null ? void 0 : options.locale) == null ? void 0 : _a.options) == null ? void 0 : _b.weekStartsOn) != null ? _d : defaultOptions.weekStartsOn) != null ? _g : (_f = (_e = defaultOptions.locale) == null ? void 0 : _e.options) == null ? void 0 : _f.weekStartsOn) != null ? _h : 0;
  const date_ = toDate(date, options == null ? void 0 : options.in);
  const currentDay = date_.getDay();
  const remainder = day % 7;
  const dayIndex = (remainder + 7) % 7;
  const delta = 7 - weekStartsOn;
  const diff = day < 0 || day > 6 ? day - (currentDay + delta) % 7 : (dayIndex + delta) % 7 - (currentDay + delta) % 7;
  return addDays(date_, diff, options);
}

// ../../node_modules/date-fns/parse/_lib/parsers/DayParser.js
var DayParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 90);
    __publicField(this, "incompatibleTokens", ["D", "i", "e", "c", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      // Tue
      case "E":
      case "EE":
      case "EEE":
        return match.day(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.day(dateString, { width: "short", context: "formatting" }) || match.day(dateString, { width: "narrow", context: "formatting" });
      // T
      case "EEEEE":
        return match.day(dateString, {
          width: "narrow",
          context: "formatting"
        });
      // Tu
      case "EEEEEE":
        return match.day(dateString, { width: "short", context: "formatting" }) || match.day(dateString, { width: "narrow", context: "formatting" });
      // Tuesday
      case "EEEE":
      default:
        return match.day(dateString, { width: "wide", context: "formatting" }) || match.day(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.day(dateString, { width: "short", context: "formatting" }) || match.day(dateString, { width: "narrow", context: "formatting" });
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 6;
  }
  set(date, _flags, value, options) {
    date = setDay(date, value, options);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/LocalDayParser.js
var LocalDayParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 90);
    __publicField(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match, options) {
    const valueCallback = (value) => {
      const wholeWeekDays = Math.floor((value - 1) / 7) * 7;
      return (value + options.weekStartsOn + 6) % 7 + wholeWeekDays;
    };
    switch (token) {
      // 3
      case "e":
      case "ee":
        return mapValue(parseNDigits(token.length, dateString), valueCallback);
      // 3rd
      case "eo":
        return mapValue(
          match.ordinalNumber(dateString, {
            unit: "day"
          }),
          valueCallback
        );
      // Tue
      case "eee":
        return match.day(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.day(dateString, { width: "short", context: "formatting" }) || match.day(dateString, { width: "narrow", context: "formatting" });
      // T
      case "eeeee":
        return match.day(dateString, {
          width: "narrow",
          context: "formatting"
        });
      // Tu
      case "eeeeee":
        return match.day(dateString, { width: "short", context: "formatting" }) || match.day(dateString, { width: "narrow", context: "formatting" });
      // Tuesday
      case "eeee":
      default:
        return match.day(dateString, { width: "wide", context: "formatting" }) || match.day(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.day(dateString, { width: "short", context: "formatting" }) || match.day(dateString, { width: "narrow", context: "formatting" });
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 6;
  }
  set(date, _flags, value, options) {
    date = setDay(date, value, options);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/StandAloneLocalDayParser.js
var StandAloneLocalDayParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 90);
    __publicField(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "e",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match, options) {
    const valueCallback = (value) => {
      const wholeWeekDays = Math.floor((value - 1) / 7) * 7;
      return (value + options.weekStartsOn + 6) % 7 + wholeWeekDays;
    };
    switch (token) {
      // 3
      case "c":
      case "cc":
        return mapValue(parseNDigits(token.length, dateString), valueCallback);
      // 3rd
      case "co":
        return mapValue(
          match.ordinalNumber(dateString, {
            unit: "day"
          }),
          valueCallback
        );
      // Tue
      case "ccc":
        return match.day(dateString, {
          width: "abbreviated",
          context: "standalone"
        }) || match.day(dateString, { width: "short", context: "standalone" }) || match.day(dateString, { width: "narrow", context: "standalone" });
      // T
      case "ccccc":
        return match.day(dateString, {
          width: "narrow",
          context: "standalone"
        });
      // Tu
      case "cccccc":
        return match.day(dateString, { width: "short", context: "standalone" }) || match.day(dateString, { width: "narrow", context: "standalone" });
      // Tuesday
      case "cccc":
      default:
        return match.day(dateString, { width: "wide", context: "standalone" }) || match.day(dateString, {
          width: "abbreviated",
          context: "standalone"
        }) || match.day(dateString, { width: "short", context: "standalone" }) || match.day(dateString, { width: "narrow", context: "standalone" });
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 6;
  }
  set(date, _flags, value, options) {
    date = setDay(date, value, options);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/setISODay.js
function setISODay(date, day, options) {
  const date_ = toDate(date, options == null ? void 0 : options.in);
  const currentDay = getISODay(date_, options);
  const diff = day - currentDay;
  return addDays(date_, diff, options);
}

// ../../node_modules/date-fns/parse/_lib/parsers/ISODayParser.js
var ISODayParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 90);
    __publicField(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "E",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(dateString, token, match) {
    const valueCallback = (value) => {
      if (value === 0) {
        return 7;
      }
      return value;
    };
    switch (token) {
      // 2
      case "i":
      case "ii":
        return parseNDigits(token.length, dateString);
      // 2nd
      case "io":
        return match.ordinalNumber(dateString, { unit: "day" });
      // Tue
      case "iii":
        return mapValue(
          match.day(dateString, {
            width: "abbreviated",
            context: "formatting"
          }) || match.day(dateString, {
            width: "short",
            context: "formatting"
          }) || match.day(dateString, {
            width: "narrow",
            context: "formatting"
          }),
          valueCallback
        );
      // T
      case "iiiii":
        return mapValue(
          match.day(dateString, {
            width: "narrow",
            context: "formatting"
          }),
          valueCallback
        );
      // Tu
      case "iiiiii":
        return mapValue(
          match.day(dateString, {
            width: "short",
            context: "formatting"
          }) || match.day(dateString, {
            width: "narrow",
            context: "formatting"
          }),
          valueCallback
        );
      // Tuesday
      case "iiii":
      default:
        return mapValue(
          match.day(dateString, {
            width: "wide",
            context: "formatting"
          }) || match.day(dateString, {
            width: "abbreviated",
            context: "formatting"
          }) || match.day(dateString, {
            width: "short",
            context: "formatting"
          }) || match.day(dateString, {
            width: "narrow",
            context: "formatting"
          }),
          valueCallback
        );
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 7;
  }
  set(date, _flags, value) {
    date = setISODay(date, value);
    date.setHours(0, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/AMPMParser.js
var AMPMParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 80);
    __publicField(this, "incompatibleTokens", ["b", "B", "H", "k", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "a":
      case "aa":
      case "aaa":
        return match.dayPeriod(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaaa":
        return match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return match.dayPeriod(dateString, {
          width: "wide",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(date, _flags, value) {
    date.setHours(dayPeriodEnumToHours(value), 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/AMPMMidnightParser.js
var AMPMMidnightParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 80);
    __publicField(this, "incompatibleTokens", ["a", "B", "H", "k", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "b":
      case "bb":
      case "bbb":
        return match.dayPeriod(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbbb":
        return match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return match.dayPeriod(dateString, {
          width: "wide",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(date, _flags, value) {
    date.setHours(dayPeriodEnumToHours(value), 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/DayPeriodParser.js
var DayPeriodParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 80);
    __publicField(this, "incompatibleTokens", ["a", "b", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "B":
      case "BB":
      case "BBB":
        return match.dayPeriod(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBBB":
        return match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return match.dayPeriod(dateString, {
          width: "wide",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.dayPeriod(dateString, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(date, _flags, value) {
    date.setHours(dayPeriodEnumToHours(value), 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/Hour1to12Parser.js
var Hour1to12Parser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 70);
    __publicField(this, "incompatibleTokens", ["H", "K", "k", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "h":
        return parseNumericPattern(numericPatterns.hour12h, dateString);
      case "ho":
        return match.ordinalNumber(dateString, { unit: "hour" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 12;
  }
  set(date, _flags, value) {
    const isPM = date.getHours() >= 12;
    if (isPM && value < 12) {
      date.setHours(value + 12, 0, 0, 0);
    } else if (!isPM && value === 12) {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(value, 0, 0, 0);
    }
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/Hour0to23Parser.js
var Hour0to23Parser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 70);
    __publicField(this, "incompatibleTokens", ["a", "b", "h", "K", "k", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "H":
        return parseNumericPattern(numericPatterns.hour23h, dateString);
      case "Ho":
        return match.ordinalNumber(dateString, { unit: "hour" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 23;
  }
  set(date, _flags, value) {
    date.setHours(value, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/Hour0To11Parser.js
var Hour0To11Parser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 70);
    __publicField(this, "incompatibleTokens", ["h", "H", "k", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "K":
        return parseNumericPattern(numericPatterns.hour11h, dateString);
      case "Ko":
        return match.ordinalNumber(dateString, { unit: "hour" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 11;
  }
  set(date, _flags, value) {
    const isPM = date.getHours() >= 12;
    if (isPM && value < 12) {
      date.setHours(value + 12, 0, 0, 0);
    } else {
      date.setHours(value, 0, 0, 0);
    }
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/Hour1To24Parser.js
var Hour1To24Parser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 70);
    __publicField(this, "incompatibleTokens", ["a", "b", "h", "H", "K", "t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "k":
        return parseNumericPattern(numericPatterns.hour24h, dateString);
      case "ko":
        return match.ordinalNumber(dateString, { unit: "hour" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 24;
  }
  set(date, _flags, value) {
    const hours = value <= 24 ? value % 24 : value;
    date.setHours(hours, 0, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/MinuteParser.js
var MinuteParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 60);
    __publicField(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "m":
        return parseNumericPattern(numericPatterns.minute, dateString);
      case "mo":
        return match.ordinalNumber(dateString, { unit: "minute" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 59;
  }
  set(date, _flags, value) {
    date.setMinutes(value, 0, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/SecondParser.js
var SecondParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 50);
    __publicField(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(dateString, token, match) {
    switch (token) {
      case "s":
        return parseNumericPattern(numericPatterns.second, dateString);
      case "so":
        return match.ordinalNumber(dateString, { unit: "second" });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 0 && value <= 59;
  }
  set(date, _flags, value) {
    date.setSeconds(value, 0);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/FractionOfSecondParser.js
var FractionOfSecondParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 30);
    __publicField(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(dateString, token) {
    const valueCallback = (value) => Math.trunc(value * Math.pow(10, -token.length + 3));
    return mapValue(parseNDigits(token.length, dateString), valueCallback);
  }
  set(date, _flags, value) {
    date.setMilliseconds(value);
    return date;
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/ISOTimezoneWithZParser.js
var ISOTimezoneWithZParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 10);
    __publicField(this, "incompatibleTokens", ["t", "T", "x"]);
  }
  parse(dateString, token) {
    switch (token) {
      case "X":
        return parseTimezonePattern(
          timezonePatterns.basicOptionalMinutes,
          dateString
        );
      case "XX":
        return parseTimezonePattern(timezonePatterns.basic, dateString);
      case "XXXX":
        return parseTimezonePattern(
          timezonePatterns.basicOptionalSeconds,
          dateString
        );
      case "XXXXX":
        return parseTimezonePattern(
          timezonePatterns.extendedOptionalSeconds,
          dateString
        );
      case "XXX":
      default:
        return parseTimezonePattern(timezonePatterns.extended, dateString);
    }
  }
  set(date, flags, value) {
    if (flags.timestampIsSet) return date;
    return constructFrom(
      date,
      date.getTime() - getTimezoneOffsetInMilliseconds(date) - value
    );
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/ISOTimezoneParser.js
var ISOTimezoneParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 10);
    __publicField(this, "incompatibleTokens", ["t", "T", "X"]);
  }
  parse(dateString, token) {
    switch (token) {
      case "x":
        return parseTimezonePattern(
          timezonePatterns.basicOptionalMinutes,
          dateString
        );
      case "xx":
        return parseTimezonePattern(timezonePatterns.basic, dateString);
      case "xxxx":
        return parseTimezonePattern(
          timezonePatterns.basicOptionalSeconds,
          dateString
        );
      case "xxxxx":
        return parseTimezonePattern(
          timezonePatterns.extendedOptionalSeconds,
          dateString
        );
      case "xxx":
      default:
        return parseTimezonePattern(timezonePatterns.extended, dateString);
    }
  }
  set(date, flags, value) {
    if (flags.timestampIsSet) return date;
    return constructFrom(
      date,
      date.getTime() - getTimezoneOffsetInMilliseconds(date) - value
    );
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/TimestampSecondsParser.js
var TimestampSecondsParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 40);
    __publicField(this, "incompatibleTokens", "*");
  }
  parse(dateString) {
    return parseAnyDigitsSigned(dateString);
  }
  set(date, _flags, value) {
    return [constructFrom(date, value * 1e3), { timestampIsSet: true }];
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers/TimestampMillisecondsParser.js
var TimestampMillisecondsParser = class extends Parser {
  constructor() {
    super(...arguments);
    __publicField(this, "priority", 20);
    __publicField(this, "incompatibleTokens", "*");
  }
  parse(dateString) {
    return parseAnyDigitsSigned(dateString);
  }
  set(date, _flags, value) {
    return [constructFrom(date, value), { timestampIsSet: true }];
  }
};

// ../../node_modules/date-fns/parse/_lib/parsers.js
var parsers = {
  G: new EraParser(),
  y: new YearParser(),
  Y: new LocalWeekYearParser(),
  R: new ISOWeekYearParser(),
  u: new ExtendedYearParser(),
  Q: new QuarterParser(),
  q: new StandAloneQuarterParser(),
  M: new MonthParser(),
  L: new StandAloneMonthParser(),
  w: new LocalWeekParser(),
  I: new ISOWeekParser(),
  d: new DateParser(),
  D: new DayOfYearParser(),
  E: new DayParser(),
  e: new LocalDayParser(),
  c: new StandAloneLocalDayParser(),
  i: new ISODayParser(),
  a: new AMPMParser(),
  b: new AMPMMidnightParser(),
  B: new DayPeriodParser(),
  h: new Hour1to12Parser(),
  H: new Hour0to23Parser(),
  K: new Hour0To11Parser(),
  k: new Hour1To24Parser(),
  m: new MinuteParser(),
  s: new SecondParser(),
  S: new FractionOfSecondParser(),
  X: new ISOTimezoneWithZParser(),
  x: new ISOTimezoneParser(),
  t: new TimestampSecondsParser(),
  T: new TimestampMillisecondsParser()
};

// ../../node_modules/date-fns/parse.js
var formattingTokensRegExp = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g;
var longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
var escapedStringRegExp = /^'([^]*?)'?$/;
var doubleQuoteRegExp = /''/g;
var notWhitespaceRegExp = /\S/;
var unescapedLatinCharacterRegExp = /[a-zA-Z]/;
function parse(dateStr, formatStr, referenceDate, options) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
  const invalidDate = () => constructFrom((options == null ? void 0 : options.in) || referenceDate, NaN);
  const defaultOptions = getDefaultOptions2();
  const locale = (_b = (_a = options == null ? void 0 : options.locale) != null ? _a : defaultOptions.locale) != null ? _b : enUS;
  const firstWeekContainsDate = (_j = (_i = (_f = (_e = options == null ? void 0 : options.firstWeekContainsDate) != null ? _e : (_d = (_c = options == null ? void 0 : options.locale) == null ? void 0 : _c.options) == null ? void 0 : _d.firstWeekContainsDate) != null ? _f : defaultOptions.firstWeekContainsDate) != null ? _i : (_h = (_g = defaultOptions.locale) == null ? void 0 : _g.options) == null ? void 0 : _h.firstWeekContainsDate) != null ? _j : 1;
  const weekStartsOn = (_r = (_q = (_n = (_m = options == null ? void 0 : options.weekStartsOn) != null ? _m : (_l = (_k = options == null ? void 0 : options.locale) == null ? void 0 : _k.options) == null ? void 0 : _l.weekStartsOn) != null ? _n : defaultOptions.weekStartsOn) != null ? _q : (_p = (_o = defaultOptions.locale) == null ? void 0 : _o.options) == null ? void 0 : _p.weekStartsOn) != null ? _r : 0;
  if (!formatStr)
    return dateStr ? invalidDate() : toDate(referenceDate, options == null ? void 0 : options.in);
  const subFnOptions = {
    firstWeekContainsDate,
    weekStartsOn,
    locale
  };
  const setters = [new DateTimezoneSetter(options == null ? void 0 : options.in, referenceDate)];
  const tokens = formatStr.match(longFormattingTokensRegExp).map((substring) => {
    const firstCharacter = substring[0];
    if (firstCharacter in longFormatters) {
      const longFormatter = longFormatters[firstCharacter];
      return longFormatter(substring, locale.formatLong);
    }
    return substring;
  }).join("").match(formattingTokensRegExp);
  const usedTokens = [];
  for (let token of tokens) {
    if (!(options == null ? void 0 : options.useAdditionalWeekYearTokens) && isProtectedWeekYearToken(token)) {
      warnOrThrowProtectedError(token, formatStr, dateStr);
    }
    if (!(options == null ? void 0 : options.useAdditionalDayOfYearTokens) && isProtectedDayOfYearToken(token)) {
      warnOrThrowProtectedError(token, formatStr, dateStr);
    }
    const firstCharacter = token[0];
    const parser = parsers[firstCharacter];
    if (parser) {
      const { incompatibleTokens } = parser;
      if (Array.isArray(incompatibleTokens)) {
        const incompatibleToken = usedTokens.find(
          (usedToken) => incompatibleTokens.includes(usedToken.token) || usedToken.token === firstCharacter
        );
        if (incompatibleToken) {
          throw new RangeError(
            `The format string mustn't contain \`${incompatibleToken.fullToken}\` and \`${token}\` at the same time`
          );
        }
      } else if (parser.incompatibleTokens === "*" && usedTokens.length > 0) {
        throw new RangeError(
          `The format string mustn't contain \`${token}\` and any other token at the same time`
        );
      }
      usedTokens.push({ token: firstCharacter, fullToken: token });
      const parseResult = parser.run(
        dateStr,
        token,
        locale.match,
        subFnOptions
      );
      if (!parseResult) {
        return invalidDate();
      }
      setters.push(parseResult.setter);
      dateStr = parseResult.rest;
    } else {
      if (firstCharacter.match(unescapedLatinCharacterRegExp)) {
        throw new RangeError(
          "Format string contains an unescaped latin alphabet character `" + firstCharacter + "`"
        );
      }
      if (token === "''") {
        token = "'";
      } else if (firstCharacter === "'") {
        token = cleanEscapedString(token);
      }
      if (dateStr.indexOf(token) === 0) {
        dateStr = dateStr.slice(token.length);
      } else {
        return invalidDate();
      }
    }
  }
  if (dateStr.length > 0 && notWhitespaceRegExp.test(dateStr)) {
    return invalidDate();
  }
  const uniquePrioritySetters = setters.map((setter) => setter.priority).sort((a, b) => b - a).filter((priority, index, array) => array.indexOf(priority) === index).map(
    (priority) => setters.filter((setter) => setter.priority === priority).sort((a, b) => b.subPriority - a.subPriority)
  ).map((setterArray) => setterArray[0]);
  let date = toDate(referenceDate, options == null ? void 0 : options.in);
  if (isNaN(+date)) return invalidDate();
  const flags = {};
  for (const setter of uniquePrioritySetters) {
    if (!setter.validate(date, subFnOptions)) {
      return invalidDate();
    }
    const result = setter.set(date, flags, subFnOptions);
    if (Array.isArray(result)) {
      date = result[0];
      Object.assign(flags, result[1]);
    } else {
      date = result;
    }
  }
  return date;
}
function cleanEscapedString(input) {
  return input.match(escapedStringRegExp)[1].replace(doubleQuoteRegExp, "'");
}

// ../../node_modules/date-fns/isSameYear.js
function isSameYear(laterDate, earlierDate, options) {
  const [laterDate_, earlierDate_] = normalizeDates(
    options == null ? void 0 : options.in,
    laterDate,
    earlierDate
  );
  return laterDate_.getFullYear() === earlierDate_.getFullYear();
}

// ../../node_modules/@toss/hangul/esm/constants.mjs
var COMPLETE_HANGUL_START_CHARCODE = "\uAC00".charCodeAt(0);
var COMPLETE_HANGUL_END_CHARCODE = "\uD7A3".charCodeAt(0);
var DISASSEMBLED_CONSONANTS_BY_CONSONANT = {
  // 종성이 없는 경우 '빈' 초성으로 관리하는 것이 편리하여, 빈 문자열도 포함한다.
  "": "",
  \u3131: "\u3131",
  \u3132: "\u3132",
  \u3133: "\u3131\u3145",
  \u3134: "\u3134",
  \u3135: "\u3134\u3148",
  \u3136: "\u3134\u314E",
  \u3137: "\u3137",
  \u3138: "\u3138",
  \u3139: "\u3139",
  \u313A: "\u3139\u3131",
  \u313B: "\u3139\u3141",
  \u313C: "\u3139\u3142",
  \u313D: "\u3139\u3145",
  \u313E: "\u3139\u314C",
  \u313F: "\u3139\u314D",
  \u3140: "\u3139\u314E",
  \u3141: "\u3141",
  \u3142: "\u3142",
  \u3143: "\u3143",
  \u3144: "\u3142\u3145",
  \u3145: "\u3145",
  \u3146: "\u3146",
  \u3147: "\u3147",
  \u3148: "\u3148",
  \u3149: "\u3149",
  \u314A: "\u314A",
  \u314B: "\u314B",
  \u314C: "\u314C",
  \u314D: "\u314D",
  \u314E: "\u314E"
};
var DISASSEMBLED_VOWELS_BY_VOWEL = {
  \u314F: "\u314F",
  \u3150: "\u3150",
  \u3151: "\u3151",
  \u3152: "\u3152",
  \u3153: "\u3153",
  \u3154: "\u3154",
  \u3155: "\u3155",
  \u3156: "\u3156",
  \u3157: "\u3157",
  \u3158: "\u3157\u314F",
  \u3159: "\u3157\u3150",
  \u315A: "\u3157\u3163",
  \u315B: "\u315B",
  \u315C: "\u315C",
  \u315D: "\u315C\u3153",
  \u315E: "\u315C\u3154",
  \u315F: "\u315C\u3163",
  \u3160: "\u3160",
  \u3161: "\u3161",
  \u3162: "\u3161\u3163",
  \u3163: "\u3163"
};
var HANGUL_CHARACTERS_BY_FIRST_INDEX = ["\u3131", "\u3132", "\u3134", "\u3137", "\u3138", "\u3139", "\u3141", "\u3142", "\u3143", "\u3145", "\u3146", "\u3147", "\u3148", "\u3149", "\u314A", "\u314B", "\u314C", "\u314D", "\u314E"];
var HANGUL_CHARACTERS_BY_MIDDLE_INDEX = Object.values(DISASSEMBLED_VOWELS_BY_VOWEL);
var HANGUL_CHARACTERS_BY_LAST_INDEX = ["", "\u3131", "\u3132", "\u3133", "\u3134", "\u3135", "\u3136", "\u3137", "\u3139", "\u313A", "\u313B", "\u313C", "\u313D", "\u313E", "\u313F", "\u3140", "\u3141", "\u3142", "\u3144", "\u3145", "\u3146", "\u3147", "\u3148", "\u314A", "\u314B", "\u314C", "\u314D", "\u314E"].map(function(consonant) {
  return DISASSEMBLED_CONSONANTS_BY_CONSONANT[consonant];
});

// ../../node_modules/@toss/hangul/esm/disassembleCompleteHangulCharacter.mjs
function disassembleCompleteHangulCharacter(letter) {
  var charCode = letter.charCodeAt(0);
  var isCompleteHangul = COMPLETE_HANGUL_START_CHARCODE <= charCode && charCode <= COMPLETE_HANGUL_END_CHARCODE;
  if (!isCompleteHangul) {
    return void 0;
  }
  var hangulCode = charCode - COMPLETE_HANGUL_START_CHARCODE;
  var lastIndex = hangulCode % 28;
  var middleIndex = (hangulCode - lastIndex) / 28 % 21;
  var firstIndex = Math.floor((hangulCode - lastIndex) / 28 / 21);
  return {
    first: HANGUL_CHARACTERS_BY_FIRST_INDEX[firstIndex],
    middle: HANGUL_CHARACTERS_BY_MIDDLE_INDEX[middleIndex],
    last: HANGUL_CHARACTERS_BY_LAST_INDEX[lastIndex]
  };
}

// ../../node_modules/@toss/hangul/esm/utils.mjs
function hasBatchim(str) {
  var lastChar = str[str.length - 1];
  var disassembled = disassembleCompleteHangulCharacter(lastChar);
  return disassembled != null && disassembled.last !== "";
}

// ../../node_modules/@toss/hangul/esm/josa.mjs
var \uB85C_\uC870\uC0AC = ["\uC73C\uB85C/\uB85C", "\uC73C\uB85C\uC11C/\uB85C\uC11C", "\uC73C\uB85C\uC368/\uB85C\uC368", "\uC73C\uB85C\uBD80\uD130/\uB85C\uBD80\uD130"];
function josa(word, josa2) {
  if (word.length === 0) {
    return word;
  }
  return word + josaPicker(word, josa2);
}
josa.pick = josaPicker;
function josaPicker(word, josa2) {
  var _disassembleCompleteH;
  var has\uBC1B\uCE68 = hasBatchim(word);
  var index = has\uBC1B\uCE68 ? 0 : 1;
  var is\uC885\uC131\u3139 = ((_disassembleCompleteH = disassembleCompleteHangulCharacter(word[word.length - 1])) === null || _disassembleCompleteH === void 0 ? void 0 : _disassembleCompleteH.last) === "\u3139";
  var isCaseOf\uB85C = has\uBC1B\uCE68 && is\uC885\uC131\u3139 && \uB85C_\uC870\uC0AC.includes(josa2);
  if (josa2 === "\uC640/\uACFC" || isCaseOf\uB85C) {
    index = index === 0 ? 1 : 0;
  }
  var isEndsWith\uC774 = word[word.length - 1] === "\uC774";
  if (josa2 === "\uC774\uC5D0\uC694/\uC608\uC694" && isEndsWith\uC774) {
    index = 1;
  }
  return josa2.split("/")[index];
}

// src/services/article/utils.ts
var getArticleMainTradeType = (articleTradeTypes, preferredTradeType, tradeTypeFilters = ["BORROW", "BUY", "MONTH", "SHORT", "YEAR"]) => {
  if (articleTradeTypes.length === 1) {
    return articleTradeTypes[0];
  }
  const filteredTradeTypes = articleTradeTypes.filter(
    (articleTradeType) => tradeTypeFilters.includes(articleTradeType)
  );
  if (filteredTradeTypes.length === 1) {
    return filteredTradeTypes[0];
  }
  if (filteredTradeTypes.length === 0) {
    return preferredTradeType != null ? preferredTradeType : articleTradeTypes[0];
  }
  const preferredFilteredTradeType = filteredTradeTypes.find(
    (tradeType) => tradeType === preferredTradeType
  );
  return preferredFilteredTradeType != null ? preferredFilteredTradeType : filteredTradeTypes[0];
};
var isJejuArticle = (article) => {
  if (article == null ? void 0 : article.address.includes("\uC81C\uC8FC")) return true;
  if (!!(article == null ? void 0 : article.coordinate.lat) && Number(article == null ? void 0 : article.coordinate.lat) < \uC81C\uC8FC\uB3C4_\uAD6C\uBD84_\uC704\uB3C4) return true;
  return false;
};
var isOneOfSalesType = (currentSalesType, salesTypes) => {
  return !!currentSalesType && [salesTypes].flat().includes(currentSalesType);
};
var getArticleSubSalesTypeText = (salesType) => {
  switch (salesType) {
    case "OPEN_ONE_ROOM":
      return "\uC624\uD508\uD615";
    case "SPLIT_ONE_ROOM":
      return "\uBD84\uB9AC\uD615";
    default:
      return "";
  }
};
var getArticleSalesTypeText = (salesType, option) => {
  const { simple = false, subText = false, etcText } = option || {};
  const subTypeText = subText ? getArticleSubSalesTypeText(salesType) : "";
  const getMainText = () => {
    switch (salesType) {
      case "OPEN_ONE_ROOM":
      case "SPLIT_ONE_ROOM":
        return "\uC6D0\uB8F8";
      case "TWO_ROOM":
        return simple ? "\uD22C\uB8F8 \uC774\uC0C1" : "\uBE4C\uB77C(\uD22C\uB8F8 \uC774\uC0C1)";
      case "APART":
        return "\uC544\uD30C\uD2B8";
      case "STORE":
        return "\uC0C1\uAC00";
      case "OFFICETEL":
        return "\uC624\uD53C\uC2A4\uD154";
      case "ETC":
        if (!etcText) {
          return simple ? "\uAE30\uD0C0" : "\uAE30\uD0C0(\uC0AC\uBB34\uC2E4, \uC8FC\uD0DD, \uD1A0\uC9C0 \uB4F1)";
        }
        if (checkIsPossiblyLand(etcText)) {
          return "\uD1A0\uC9C0";
        }
        return `\uAE30\uD0C0(${etcText})`;
      default:
        "\uADF8 \uC678";
    }
  };
  return [subTypeText, getMainText()].filter(truthy).join(" ");
};
var SUBWAY_DISTANCE_METER_PER_MINUTE = 70;
var SalesTypeText = (salesType, options) => {
  switch (salesType) {
    case "ONE_ROOM":
      return "\uC6D0\uB8F8";
    case "TWO_ROOM":
      return (options == null ? void 0 : options.shorten) ? "\uD22C\uB8F8 \uC774\uC0C1" : "\uBE4C\uB77C(\uD22C\uB8F8 \uC774\uC0C1)";
    case "APART":
      return "\uC544\uD30C\uD2B8";
    case "STORE":
      return "\uC0C1\uAC00";
    case "OFFICETEL":
      return "\uC624\uD53C\uC2A4\uD154";
    case "ETC":
      return (options == null ? void 0 : options.shorten) ? "\uAE30\uD0C0" : "\uAE30\uD0C0(\uC0AC\uBB34\uC2E4, \uC8FC\uD0DD, \uD1A0\uC9C0 \uB4F1)";
    default:
      "\uADF8 \uC678";
  }
};
var getFilterSalesTypeByArticleSalesType = (salesType) => {
  if (salesType === "OPEN_ONE_ROOM" || salesType === "SPLIT_ONE_ROOM") {
    return "ONE_ROOM";
  }
  return salesType;
};
var getFloorTypeText = (floor) => {
  switch (floor) {
    case "SEMI_BASEMENT":
      return "\uBC18\uC9C0\uD558";
    case "FIRST":
      return "1\uCE35";
    case "SECOND_TO_FIFTH":
      return "2~5\uCE35";
    case "SIX_TO_NINTH":
      return "6~9\uCE35";
    case "TENTH_OR_MORE":
      return "10\uCE35 \uC774\uC0C1";
    // deprecated
    case "FIRST_TO_FORTH":
      return "1~4\uCE35";
    case "FIFTH_TO_NINTH":
      return "5~9\uCE35";
  }
};
var getArticleStatusText = (articleStatus) => {
  switch (articleStatus) {
    case "ON_GOING":
      return "\uD310\uB9E4\uC911";
    case "RESERVED":
      return "\uC608\uC57D\uC911";
    case "TRADED":
      return "\uAC70\uB798\uC644\uB8CC";
    default:
      return "\uC54C \uC218 \uC5C6\uC74C";
  }
};
var getArticleTradeTypeText = (articleTradeType) => {
  switch (articleTradeType) {
    case "SHORT":
      return "\uB2E8\uAE30";
    case "MONTH":
      return "\uC6D4\uC138";
    case "YEAR":
      return "\uC5F0\uC138";
    case "BORROW":
      return "\uC804\uC138";
    case "BUY":
      return "\uB9E4\uB9E4";
  }
};
var getArticleOptionNameText = (optionName, options) => {
  const {} = options || {};
  switch (optionName) {
    case "MORTGAGE":
      return "\uB300\uCD9C";
    case "PET":
      return "\uBC18\uB824\uB3D9\uBB3C";
    case "PARKING":
      return "\uC8FC\uCC28";
    case "ELEVATOR":
      return "\uC5D8\uB9AC\uBCA0\uC774\uD130";
    case "LOFT":
      return "\uBCF5\uCE35";
    case "ROOFTOP":
      return "\uC625\uD0D1";
    case "WASHER":
      return "\uC138\uD0C1\uAE30";
    case "FRIDGE":
      return "\uB0C9\uC7A5\uACE0";
    case "AIRCON":
      return "\uC5D0\uC5B4\uCEE8";
    case "ELEC_RANGE":
      return "\uC804\uC790\uB80C\uC9C0";
    case "GAS_RANGE":
      return "\uAC00\uC2A4\uB80C\uC9C0";
    case "INDUCTION":
      return "\uC778\uB355\uC158";
    case "BED":
      return "\uCE68\uB300";
    default:
      return "";
  }
};
var getArticleOptionValueText = (articleOptionValue, exist = false) => {
  switch (articleOptionValue) {
    case "YES":
      return exist ? "\uC788\uC74C" : "\uAC00\uB2A5";
    case "NO":
      return exist ? "\uC5C6\uC74C" : "\uBD88\uAC00\uB2A5";
    case "DONT_KNOW":
      return "\uD655\uC778 \uD544\uC694";
    default:
      return "";
  }
};
var getArticleParkingOptionValue = (articleOptionValue, parkingSpots, options) => {
  const { isBroker } = options || {};
  if (!isBroker || articleOptionValue !== "YES") {
    return {
      mainText: getArticleOptionValueText(articleOptionValue),
      subText: null
    };
  }
  const { totalParkingSpots, availableParkingSpots } = parkingSpots;
  return {
    mainText: totalParkingSpots ? `${totalParkingSpots}\uB300 \uAC00\uB2A5` : "-",
    subText: availableParkingSpots ? `(\uC138\uB300\uB2F9 ${availableParkingSpots}\uB300)` : null
  };
};
var getArticleParkingOptionText = (articleOptionValue, parkingSpots, options) => {
  const value = getArticleParkingOptionValue(articleOptionValue, parkingSpots, options);
  return [value.mainText, value.subText].filter(truthy).join(" ");
};
var getSaleTargetText = (tradeTypes) => {
  if (tradeTypes.includes("BUY")) {
    if (tradeTypes.length === 1) {
      return "\uAD6C\uB9E4\uC790";
    } else {
      return "\uC138\uC785\uC790 \uB610\uB294 \uAD6C\uB9E4\uC790";
    }
  } else {
    return "\uC138\uC785\uC790";
  }
};
var getWriterTypeText = (writerType) => {
  switch (writerType) {
    case "TENANT":
      return "\uC138\uC785\uC790";
    case "LESSOR":
      return "\uC9D1\uC8FC\uC778";
    case "BROKER":
      return "\uACF5\uC778\uC911\uAC1C\uC0AC";
    default:
      return "\uC54C \uC218 \uC5C6\uC74C";
  }
};
var getBuildingNameTitle = (salesType) => {
  if (salesType === "APART") {
    return "\uC544\uD30C\uD2B8\uBA85";
  }
  return "\uAC74\uBB3C\uBA85";
};
var getAreaText = (area) => {
  if (isStringNumberFalsy(area)) {
    return "\uD655\uC778 \uD544\uC694";
  }
  return [areaText(area || "", "m\xB2"), areaText(area || "", "\uD3C9")].filter(truthy).join(" \xB7 ");
};
var isStringNumberFalsy = (s) => {
  return !s || isNaN(Number(s)) || Number(s) === 0 || Number(s) === -0;
};
var getRoomText = ({
  roomCnt,
  bathroomCnt
}) => {
  if (!roomCnt && !bathroomCnt) {
    return "";
  }
  return [
    { label: "\uBC29", value: roomCnt },
    { label: "\uC695\uC2E4", value: bathroomCnt }
  ].filter(({ value }) => !!value).map(({ label, value }) => `${label} ${value}\uAC1C`).join(" / ");
};
var getFloorsText = ({
  floor,
  topFloor
}, option) => {
  const { simple = false } = option || {};
  if (!floor && !topFloor) {
    return "";
  }
  if (simple) {
    return floor ? getFloorText(floor) : "";
  }
  if (!floor && topFloor) {
    return `\uCD5C\uACE0\uCE35 ${getFloorText(topFloor)}`;
  }
  if (floor && !topFloor) {
    return getFloorText(floor);
  }
  return [floor, topFloor].filter(Boolean).map((value) => getFloorText(value || "0")).join(" / ");
};
var isSemiBasement = (floor) => floor === SEMI_BASEMENT_FLOOR;
var getMoveInDateText = (moveInDate, option) => {
  const { showImmediatelyText = true } = option || {};
  if (!moveInDate) {
    return "";
  }
  if (showImmediatelyText && checkCanMoveInNow(moveInDate)) {
    return "\uC989\uC2DC \uC785\uC8FC \uAC00\uB2A5";
  }
  const dateFormat = isSameYear(moveInDate, /* @__PURE__ */ new Date()) ? "MM/dd(eee) \uC774\uD6C4" : "yyyy/MM/dd(eee) \uC774\uD6C4";
  return format(new Date(moveInDate), dateFormat, {
    locale: ko
  });
};
var getManageCostPrice = (article) => {
  var _a;
  const { includeManageCostOptionV2, manageCost, etcManageCost, writerType } = article;
  if (writerType !== "BROKER") {
    return manageCost != null ? manageCost : 0;
  }
  const flatPriceOptions = (includeManageCostOptionV2 || []).filter((v) => v.payOption === "FIXED");
  const allPrice = (manageCost != null ? manageCost : 0) + (etcManageCost != null ? etcManageCost : 0) + ((_a = flatPriceOptions == null ? void 0 : flatPriceOptions.reduce((acc, curr) => {
    var _a2;
    return acc + ((_a2 = curr.fixedCost) != null ? _a2 : 0);
  }, 0)) != null ? _a : 0);
  return allPrice;
};
var getBrokerManageCostText = (article) => {
  const { includeManageCostOptionV2, isUnknownManageCost } = article;
  if (isUnknownManageCost) {
    return "\uD655\uC778 \uD544\uC694";
  }
  const actualPriceOptions = (includeManageCostOptionV2 || []).filter(
    (v) => v.payOption === "USED"
  );
  const flatPriceOptions = (includeManageCostOptionV2 || []).filter((v) => v.payOption === "FIXED");
  const price = getManageCostPrice(article);
  const actualPriceList = actualPriceOptions.map((v) => ManageCostOptionText[v.option]);
  const flatPriceList = flatPriceOptions.map((v) => ManageCostOptionText[v.option]);
  const flatPriceText = [
    `${price}\uB9CC\uC6D0`,
    flatPriceList.length > 0 && `(${flatPriceList.join(",")} \uD3EC\uD568)`
  ].filter(truthy).join("");
  const actualPriceText = actualPriceList.length > 0 && `${josa(actualPriceList.join(","), "\uC740/\uB294")} \uC0AC\uC6A9\uB7C9\uC5D0 \uB530\uB77C \uBCC4\uB3C4 \uBD80\uACFC`;
  return [flatPriceText, actualPriceText].filter(truthy).join("\n");
};
var getManageCostText = (article) => {
  if (article.writerType === "BROKER") {
    return getBrokerManageCostText(article);
  }
  const { manageCost, manageCostDescription, includeManageCostOptionV2, excludeManageCostOption } = article;
  const includeText = (includeManageCostOptionV2 || []).length > 0 ? `(${includeManageCostOptionV2.map((v) => ManageCostOptionText[v.option]).join(", ")} \uD3EC\uD568)` : "";
  return [
    manageCost && [`${manageCost}\uB9CC\uC6D0`, includeText].filter(truthy).join(" "),
    (excludeManageCostOption || []).length > 0 && `${excludeManageCostOption.map((v) => ManageCostOptionText[v]).join(", ")} \uBCC4\uB3C4`,
    manageCostDescription
  ].filter(Boolean).join("\n");
};
var getPremiumMoneyText = (premiumMoney) => {
  return premiumMoney ? billionConvert(premiumMoney, { withUnit: true }) : "\uC5C6\uC74C";
};
var QualitativeText = {
  GOOD_SUNLIGHT: "\u2600\uFE0F \uD587\uBE5B\uC774 \uC798 \uB4E4\uC5B4\uC694.",
  CONVENIENT_TRANSPORT: "\u{1F68C} \uAD50\uD1B5\uC774 \uD3B8\uB9AC\uD574\uC694.",
  MANY_AMENITIES: "\u{1F3EA} \uC8FC\uBCC0 \uD3B8\uC758\uC2DC\uC124\uC774 \uB9CE\uC544\uC694.",
  NICE_VIEW: "\u{1F5BC} \uC804\uB9DD\uC774 \uC88B\uC544\uC694.",
  NO_PESTS: "\u{1FAB2} \uBC8C\uB808\uAC00 \uC5C6\uC5B4\uC694.",
  AFFORDABLE_PRICE: "\u{1F4B8} \uC8FC\uBCC0\uBCF4\uB2E4 \uC800\uB834\uD574\uC694.",
  SAFE_FOR_SOLO: "\u26D1 \uD63C\uC790 \uC0B4\uC544\uB3C4 \uC548\uC804\uD574\uC694.",
  WITH_TERRACE: "\u26F3\uFE0F \uD14C\uB77C\uC2A4\uAC00 \uC788\uC5B4\uC694.",
  CLEAN_CONDITION: "\u2728 \uC9D1 \uC0C1\uD0DC\uAC00 \uAE68\uB057\uD574\uC694.",
  GOOD_SOUNDPROOFING: "\u{1F4A4} \uBC29\uC74C\uC774 \uC798\uB3FC\uC694."
};
var getQualitativeText = (qualitative) => {
  return QualitativeText[qualitative] || "";
};
var OrientationText = {
  EAST_FACING: "\uB3D9\uD5A5",
  WEST_FACING: "\uC11C\uD5A5",
  SOUTH_FACING: "\uB0A8\uD5A5",
  NORTH_FACING: "\uBD81\uD5A5",
  SOUTH_EAST_FACING: "\uB0A8\uB3D9\uD5A5",
  SOUTH_WEST_FACING: "\uB0A8\uC11C\uD5A5",
  NORTH_EAST_FACING: "\uBD81\uB3D9\uD5A5",
  NORTH_WEST_FACING: "\uBD81\uC11C\uD5A5"
};
var getOrientationText = (orientation) => {
  return OrientationText[orientation] || "";
};
var BuildingUsageText = {
  SINGLE_FAMILY_HOUSING: "\uB2E8\uB3C5\uC8FC\uD0DD",
  PUBLIC_HOUSING: "\uACF5\uB3D9\uC8FC\uD0DD",
  TYPE_1_NEIGHBORHOOD_LIVING_FACILITY: "\uC81C1\uC885 \uADFC\uB9B0\uC0DD\uD65C\uC2DC\uC124",
  TYPE_2_NEIGHBORHOOD_LIVING_FACILITY: "\uC81C2\uC885 \uADFC\uB9B0\uC0DD\uD65C\uC2DC\uC124",
  CULTURAL_AND_ASSEMBLY_FACILITIES: "\uBB38\uD654 \uBC0F \uC9D1\uD68C\uC2DC\uC124",
  RELIGIOUS_FACILITY: "\uC885\uAD50\uC2DC\uC124",
  SALE_FACILITY: "\uD310\uB9E4\uC2DC\uC124",
  TRANSPORTATION_FACILITY: "\uC6B4\uC218\uC2DC\uC124",
  MEDICAL_FACILITY: "\uC758\uB8CC\uC2DC\uC124",
  EDUCATION_AND_RESEARCH_FACILITY: "\uAD50\uC721\uC5F0\uAD6C\uC2DC\uC124",
  ELDERLY_FACILITY: "\uB178\uC720\uC790\uC2DC\uC124",
  STUDY_FACILITY: "\uC218\uB828\uC2DC\uC124",
  EXERCISE_FACILITY: "\uC6B4\uB3D9\uC2DC\uC124",
  OFFICE_FACILITY: "\uC5C5\uBB34\uC2DC\uC124",
  ACCOMMODATION_FACILITY: "\uC219\uBC15\uC2DC\uC124",
  RECREATION_FACILITY: "\uC704\uB77D\uC2DC\uC124",
  FACTORY: "\uACF5\uC7A5",
  WAREHOUSE_FACILITY: "\uCC3D\uACE0\uC2DC\uC124",
  HAZARDOUS_MATERIAL_FACILITY: "\uC704\uD5D8\uBB3C \uC800\uC7A5 \uBC0F \uCC98\uB9AC \uC2DC\uC124",
  DOOR_AND_PLANT_RELATED_FACILITY: "\uB3D9\uBB3C \uBC0F \uC2DD\uBB3C \uAD00\uB828 \uC2DC\uC124",
  SEWAGE_AND_WASTE_PROCESSING_FACILITY: "\uC790\uC6D0\uC21C\uD658 \uAD00\uB828 \uC2DC\uC124",
  CORRECTION_AND_MILITARY_FACILITY: "\uAD50\uC815\uC2DC\uC124",
  BROADCASTING_AND_COMMUNICATION_FACILITY: "\uBC29\uC1A1\uD1B5\uC2E0\uC2DC\uC124",
  POWER_GENERATION_FACILITY: "\uBC1C\uC804\uC2DC\uC124",
  CEMETERY_RELATED_FACILITY: "\uBB18\uC9C0 \uAD00\uB828 \uC2DC\uC124",
  TOURISM_AND_RECREATION_FACILITY: "\uAD00\uAD11 \uD734\uAC8C\uC2DC\uC124",
  FUNERAL_HOME: "\uC7A5\uB840\uC2DD\uC7A5",
  CAR_RELATED_FACILITY: "\uC790\uB3D9\uCC28 \uAD00\uB828 \uC2DC\uC124",
  TEMPORARY_BUILDING: "\uC57C\uC601\uC7A5\uC2DC\uC124"
};
var getBuildingUsageText = (buildingUsage) => {
  return BuildingUsageText[buildingUsage] || "";
};
var getRequiredOptions = (options) => {
  return options.filter(({ name }) => RequiredArticleOptionNames.some((v) => v === name));
};
var getOptionOptions = (options) => {
  return options.filter((v) => !RequiredArticleOptionNames.some((name) => name === v.name));
};
var getSubwayText = (subwayName, distanceMeter) => {
  if (!subwayName || !distanceMeter) {
    return "";
  }
  const parsedSubwayName = subwayName.replace(/\(.*?\)/g, "");
  const minute = Math.max(Math.ceil(distanceMeter / SUBWAY_DISTANCE_METER_PER_MINUTE), 1);
  return `${parsedSubwayName}\uC5ED \uB3C4\uBCF4 ${minute}\uBD84`;
};
var APPROVAL_YEARS = [2, 5, 10, 15, 25];
var getBuildingApprovalText = (buildingApprovalDate) => {
  if (!buildingApprovalDate) {
    return "";
  }
  const date = new Date(buildingApprovalDate);
  const yearGap = (/* @__PURE__ */ new Date()).getFullYear() - date.getFullYear();
  return `${formatDate(date, "yyyy\uB144 M\uC6D4 d\uC77C" /* BuildingApproval */)} (${yearGap}\uB144\uCC28)`;
};
var getBuildingApprovalDistance = (buildingApprovalDate) => {
  if (!buildingApprovalDate) {
    return;
  }
  const date = new Date(buildingApprovalDate);
  const yearGap = (/* @__PURE__ */ new Date()).getFullYear() - date.getFullYear();
  const roundedYear = APPROVAL_YEARS.find((v) => v >= yearGap);
  return roundedYear;
};
var getBuildingApprovalDistanceText = (buildingApprovalDate) => {
  const distance = getBuildingApprovalDistance(buildingApprovalDate);
  if (distance == void 0) {
    return "";
  }
  return `${distance}\uB144 \uC774\uB0B4`;
};
var getTradeTypeAndPrice = (tradeType, price, monthlyPay, options) => {
  const tradeTypeText = (options == null ? void 0 : options.withoutTradeType) ? "" : `${getArticleTradeTypeText(tradeType)} `;
  const priceText = billionConvert(price, { withUnit: Boolean(options == null ? void 0 : options.withUnit) });
  switch (tradeType) {
    case "MONTH":
    case "SHORT":
    case "YEAR":
      const monthlyPayText = billionConvert(monthlyPay, {
        withUnit: Boolean(options == null ? void 0 : options.withUnit)
      });
      return `${tradeTypeText}${priceText} / ${monthlyPayText}`;
    case "BORROW":
    case "BUY":
      return `${tradeTypeText}${priceText}`;
  }
};
var formatArea = (area, areaUnit = "m\xB2") => {
  return parseFloat(area.toFixed(areaUnit === "m\xB2" ? 2 : 1)).toString();
};
var getAreaUnitText = (unit) => {
  return unit === "\uD3C9" ? "\uD3C9\uC218" : "\uC81C\uACF1\uBBF8\uD130";
};
var areaText = (area, unit, options = { withUnit: true }) => {
  if (!area || Number(area) <= 0) {
    return "";
  }
  if (unit === "m\xB2") {
    return `${formatArea(Number(area), unit)}${options.withUnit ? "m\xB2" : ""}`;
  }
  return `${formatArea(Number(area) / \uD3C9\uB2F9_\uC81C\uACF1\uBBF8\uD130, unit)}${options.withUnit ? "\uD3C9" : ""}`;
};
var parseFloor = (floor) => String(parseInt(floor));
var getFloorText = (floor) => {
  return floor === SEMI_BASEMENT_FLOOR ? "\uBC18\uC9C0\uD558" : `${parseFloor(floor)}\uCE35`;
};
var getRequiredOptionNames = ({ salesType }) => {
  if (salesType === "STORE") {
    return ["PARKING"];
  }
  return RequiredArticleOptionNames;
};
var getBuildingUsageOptions = ({ salesType }) => {
  const mainBuildingUsageOptions = salesType === "STORE" ? ["SALE_FACILITY", ...MainBuildingUsageTypes] : MainBuildingUsageTypes;
  const etcBuildingUsageOptions = EtcBuildingUsageTypes.filter(
    (v) => salesType !== "STORE" || v !== "SALE_FACILITY"
  );
  return {
    main: mainBuildingUsageOptions,
    etc: etcBuildingUsageOptions
  };
};
var LandTypeText = {
  BANK: "\uC81C\uBC29(\u5824\u9632)",
  DITCH: "\uAD6C\uAC70(\u6E9D\u6E20)",
  DRY_PADDY_FIELD: "\uC804",
  FACTORY_SITE: "\uACF5\uC7A5\uC6A9\uC9C0",
  FISH_FARM: "\uC591\uC5B4\uC7A5",
  FORESTRY: "\uC784\uC57C",
  GAS_STATION_SITE: "\uC8FC\uC720\uC18C\uC6A9\uC9C0",
  GRAVEYARD: "\uBB18\uC9C0",
  GYMNASTIC_SITE: "\uCCB4\uC721\uC6A9\uC9C0",
  HISTORIC_SITE: "\uC0AC\uC801\uC9C0",
  MARSH: "\uC720\uC9C0(\uF9CB\u6C60)",
  MINERAL_SPRING_SITE: "\uAD11\uCC9C\uC9C0",
  MISCELLANEOUS_LAND: "\uC7A1\uC885\uC9C0",
  ORCHARD: "\uACFC\uC218\uC6D0",
  PADDY_FIELD: "\uB2F5",
  PARK: "\uACF5\uC6D0",
  PARKING_LOT: "\uC8FC\uCC28\uC7A5",
  PASTURE: "\uBAA9\uC7A5\uC6A9\uC9C0",
  RAILWAY_SITE: "\uCCA0\uB3C4\uC6A9\uC9C0",
  RECREATION_AREA: "\uC720\uC6D0\uC9C0",
  RELIGION_SITE: "\uC885\uAD50\uC6A9\uC9C0",
  RIVER: "\uD558\uCC9C",
  ROAD: "\uB3C4\uB85C",
  SALTERN: "\uC5FC\uC804",
  SCHOOL_SITE: "\uD559\uAD50\uC6A9\uC9C0",
  SITE: "\uB300(\u5788)",
  WAREHOUSE_SITE: "\uCC3D\uACE0\uC6A9\uC9C0",
  WATER_SUPPLY_SITE: "\uC218\uB3C4\uC6A9\uC9C0"
};
var getLandTypeText = (landType) => {
  return LandTypeText[landType] || "";
};
var LandPurposeText = {
  AGRICULTURAL_AND_FOREST_AREA: "\uB18D\uB9BC\uC9C0\uC5ED",
  CONTROL_AREA: "\uAD00\uB9AC\uC9C0\uC5ED",
  NATURAL_CONSERVATION_AREA: "\uC790\uC5F0\uD658\uACBD\uBCF4\uC804\uC9C0\uC5ED",
  URBAN_AREA: "\uB3C4\uC2DC\uC9C0\uC5ED"
};
var getLandPurposeText = (landPurpose) => {
  return LandPurposeText[landPurpose] || "";
};
var checkIsLandSalesType = ({
  salesType,
  etcSalesType
}) => {
  if (salesType === "ETC" && etcSalesType) {
    return checkIsPossiblyLand(etcSalesType);
  }
  return false;
};
var checkIsPossiblyLand = (etcSalesType) => {
  return /(토지|임야|대지|농지|농막|농장|나대지|과수원|전답|부지)|(^(전|답|밭|논|땅|산)$)/.test(
    etcSalesType
  );
};

// src/services/buildingLedger/utils.ts
var findCorAddressMatchingBuildingLedgerTitle = (corAddress, ledgers) => {
  var _a;
  if (ledgers.length === 0) {
    return void 0;
  }
  if (ledgers.length === 1) {
    return ledgers[0];
  }
  const maybeDong = (_a = corAddress.match(/ 제?(\w+)동 /)) == null ? void 0 : _a[1];
  const maybeBuildingInfo = ledgers.find((v) => v.dongNm.includes(maybeDong != null ? maybeDong : ""));
  return maybeBuildingInfo;
};
var buildingLedgerTitleToArticleForm = (ledger) => {
  const rideUseElvt = ledger.rideUseElvtCnt && typeof ledger.rideUseElvtCnt === "number" ? ledger.rideUseElvtCnt : null;
  const grndFlr = ledger.grndFlrCnt && typeof ledger.grndFlrCnt === "number" ? ledger.grndFlrCnt : null;
  const useAprDay = ledger.useAprDay && typeof ledger.useAprDay === "number" ? ledger.useAprDay : null;
  return {
    options: rideUseElvt && rideUseElvt > 0 ? [
      {
        name: "ELEVATOR",
        value: "YES"
      }
    ] : [],
    topFloor: grndFlr ? String(grndFlr) : void 0,
    buildingApprovalDate: useAprDay ? parse(String(useAprDay), "yyyyMMdd", /* @__PURE__ */ new Date()) : void 0
  };
};

// src/services/bigpicture/index.ts
import sha256 from "sha256";
var ENDPOINT = {
  alpha: "https://realty.alpha.kr.karrotmarket.com/graphql",
  production: "https://realty.kr.karrotmarket.com/graphql"
};
var BigPictureUploader = class {
  constructor(config) {
    this.config = config;
  }
  async uploadImage({
    blob,
    file,
    image,
    uploadTarget
  }) {
    const { uploadUrl, originalId, thumbnailUrl } = await this.requestUploadUrl({
      type: uploadTarget,
      width: image.width,
      height: image.height,
      contentType: file.type,
      filepath: this.generateFilePath(file)
    });
    const response = await fetch(uploadUrl, {
      method: "PUT",
      body: blob,
      headers: {
        "Content-Type": file.type,
        "x-amz-meta-id": originalId
      }
    });
    if (!response.ok) {
      throw new Error(
        `Failed to upload file to S3: ${response.status} ${response.statusText} (${uploadTarget})`
      );
    }
    return {
      id: originalId,
      thumbnail: thumbnailUrl
    };
  }
  async requestUploadUrl(input) {
    var _a;
    const auth = await this.config.authProvider();
    const response = await fetch(ENDPOINT[this.config.stage], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ..."authToken" in auth && { "x-auth-token": auth.authToken },
        ..."accessToken" in auth && {
          "x-access-token": auth.accessToken,
          "x-refresh-token": auth.refreshToken
        }
      },
      body: JSON.stringify({
        query: `
          mutation RequestBigPictureImageUpload($input: RequestBigPictureImageUploadInput!) {
            requestBigPictureImageUpload(input: $input) {
              __typename
              ... on BigPictureImageUploadInfo {
                originalId
                uploadUrl
                thumbnailUrl
              }
              ... on ToastError {
                toastError {
                  message
                }
              }
            }
          }
        `,
        variables: {
          input
        }
      })
    });
    if (!response.ok) {
      throw new Error(`Failed to request upload URL: ${response.status} ${response.statusText}`);
    }
    const { data, errors } = await response.json();
    if ((errors == null ? void 0 : errors.length) > 0) {
      throw new Error(errors[0].message);
    }
    const result = data.requestBigPictureImageUpload;
    if (result.__typename === "ToastError") {
      throw new Error(((_a = result.toastError) == null ? void 0 : _a.message) || "Unknown error occurred");
    }
    if (result.__typename !== "BigPictureImageUploadInfo") {
      throw new Error("Unknown typename for requestBigPictureImageUpload");
    }
    if (!result.originalId || !result.uploadUrl || !result.thumbnailUrl) {
      throw new Error("Missing required fields in response");
    }
    return {
      originalId: result.originalId,
      uploadUrl: result.uploadUrl,
      thumbnailUrl: result.thumbnailUrl
    };
  }
  generateFilePath(file) {
    var _a;
    return `realty/articles/${sha256((_a = file.name) != null ? _a : "image")}_${Date.now()}.${file.type.replace("image/", "")}`;
  }
  async uploadImages(images) {
    const uploadedImages = images.map(({ image, file, uploadTarget }) => {
      return new Promise((resolve, reject) => {
        const onReceiveBlob = (blob) => {
          if (!blob) {
            return reject(new Error("[uploadImages] image blob does not exist"));
          }
          this.uploadImage({ blob, file, image, uploadTarget }).then(resolve).catch(reject);
        };
        image.toBlob(onReceiveBlob, file.type);
      });
    });
    return Promise.allSettled(uploadedImages);
  }
};

export {
  parseAddress,
  CoordinateFinder,
  parseAddressDetail,
  getJibunAddress,
  removeDetailFromAddress,
  checkIsDagaguRentalHouse,
  KakaoCategory,
  SEMI_BASEMENT_FLOOR,
  평당_제곱미터,
  ArticleUploadType,
  TradeTypes,
  MonthlyPayableTradeTypes,
  AdjustableTradeTypes,
  ArticleStatuses,
  OneRoomSalesTypes,
  ResidentSalesTypes,
  BuildingNameAvailableSalesTypes,
  SupplyAreaFirstSalesTypes,
  ArticleOptionValues,
  RequiredArticleOptionNames,
  EtcArticleOptionNames,
  WriterTypes,
  MainBuildingUsageTypes,
  EtcBuildingUsageTypes,
  BuildingUsageTypes,
  ManageCostOptions,
  ManageCostOptionText,
  LandTypes,
  LandPurposes,
  제주도_구분_위도,
  getArticleMainTradeType,
  isJejuArticle,
  isOneOfSalesType,
  getArticleSubSalesTypeText,
  getArticleSalesTypeText,
  SalesTypeText,
  getFilterSalesTypeByArticleSalesType,
  getFloorTypeText,
  getArticleStatusText,
  getArticleTradeTypeText,
  getArticleOptionNameText,
  getArticleOptionValueText,
  getArticleParkingOptionValue,
  getArticleParkingOptionText,
  getSaleTargetText,
  getWriterTypeText,
  getBuildingNameTitle,
  getAreaText,
  isStringNumberFalsy,
  getRoomText,
  getFloorsText,
  isSemiBasement,
  getMoveInDateText,
  getManageCostPrice,
  getBrokerManageCostText,
  getManageCostText,
  getPremiumMoneyText,
  getQualitativeText,
  getOrientationText,
  getBuildingUsageText,
  getRequiredOptions,
  getOptionOptions,
  getSubwayText,
  getBuildingApprovalText,
  getBuildingApprovalDistance,
  getBuildingApprovalDistanceText,
  getTradeTypeAndPrice,
  formatArea,
  getAreaUnitText,
  areaText,
  parseFloor,
  getFloorText,
  getRequiredOptionNames,
  getBuildingUsageOptions,
  getLandTypeText,
  getLandPurposeText,
  checkIsLandSalesType,
  checkIsPossiblyLand,
  findCorAddressMatchingBuildingLedgerTitle,
  buildingLedgerTitleToArticleForm,
  BigPictureUploader
};
