import {
  compose,
  truthy
} from "./chunk-VH26F5IY.mjs";
import {
  RealtyClientAPI,
  __commonJS,
  __toESM
} from "./chunk-OU5M4E7S.mjs";

// ../../node_modules/url-pattern/lib/url-pattern.js
var require_url_pattern = __commonJS({
  "../../node_modules/url-pattern/lib/url-pattern.js"(exports, module) {
    "use strict";
    var slice = [].slice;
    (function(root, factory) {
      if ("function" === typeof define && define.amd != null) {
        return define([], factory);
      } else if (typeof exports !== "undefined" && exports !== null) {
        return module.exports = factory();
      } else {
        return root.UrlPattern = factory();
      }
    })(exports, function() {
      var P, UrlPattern2, astNodeContainsSegmentsForProvidedParams, astNodeToNames, astNodeToRegexString, baseAstNodeToRegexString, concatMap, defaultOptions, escapeForRegex, getParam, keysAndValuesToObject, newParser, regexGroupCount, stringConcatMap, stringify;
      escapeForRegex = function(string) {
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
      };
      concatMap = function(array, f) {
        var i, length, results;
        results = [];
        i = -1;
        length = array.length;
        while (++i < length) {
          results = results.concat(f(array[i]));
        }
        return results;
      };
      stringConcatMap = function(array, f) {
        var i, length, result;
        result = "";
        i = -1;
        length = array.length;
        while (++i < length) {
          result += f(array[i]);
        }
        return result;
      };
      regexGroupCount = function(regex) {
        return new RegExp(regex.toString() + "|").exec("").length - 1;
      };
      keysAndValuesToObject = function(keys, values) {
        var i, key, length, object, value;
        object = {};
        i = -1;
        length = keys.length;
        while (++i < length) {
          key = keys[i];
          value = values[i];
          if (value == null) {
            continue;
          }
          if (object[key] != null) {
            if (!Array.isArray(object[key])) {
              object[key] = [object[key]];
            }
            object[key].push(value);
          } else {
            object[key] = value;
          }
        }
        return object;
      };
      P = {};
      P.Result = function(value, rest) {
        this.value = value;
        this.rest = rest;
      };
      P.Tagged = function(tag, value) {
        this.tag = tag;
        this.value = value;
      };
      P.tag = function(tag, parser) {
        return function(input) {
          var result, tagged;
          result = parser(input);
          if (result == null) {
            return;
          }
          tagged = new P.Tagged(tag, result.value);
          return new P.Result(tagged, result.rest);
        };
      };
      P.regex = function(regex) {
        return function(input) {
          var matches, result;
          matches = regex.exec(input);
          if (matches == null) {
            return;
          }
          result = matches[0];
          return new P.Result(result, input.slice(result.length));
        };
      };
      P.sequence = function() {
        var parsers;
        parsers = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        return function(input) {
          var i, length, parser, rest, result, values;
          i = -1;
          length = parsers.length;
          values = [];
          rest = input;
          while (++i < length) {
            parser = parsers[i];
            result = parser(rest);
            if (result == null) {
              return;
            }
            values.push(result.value);
            rest = result.rest;
          }
          return new P.Result(values, rest);
        };
      };
      P.pick = function() {
        var indexes, parsers;
        indexes = arguments[0], parsers = 2 <= arguments.length ? slice.call(arguments, 1) : [];
        return function(input) {
          var array, result;
          result = P.sequence.apply(P, parsers)(input);
          if (result == null) {
            return;
          }
          array = result.value;
          result.value = array[indexes];
          return result;
        };
      };
      P.string = function(string) {
        var length;
        length = string.length;
        return function(input) {
          if (input.slice(0, length) === string) {
            return new P.Result(string, input.slice(length));
          }
        };
      };
      P.lazy = function(fn) {
        var cached;
        cached = null;
        return function(input) {
          if (cached == null) {
            cached = fn();
          }
          return cached(input);
        };
      };
      P.baseMany = function(parser, end, stringResult, atLeastOneResultRequired, input) {
        var endResult, parserResult, rest, results;
        rest = input;
        results = stringResult ? "" : [];
        while (true) {
          if (end != null) {
            endResult = end(rest);
            if (endResult != null) {
              break;
            }
          }
          parserResult = parser(rest);
          if (parserResult == null) {
            break;
          }
          if (stringResult) {
            results += parserResult.value;
          } else {
            results.push(parserResult.value);
          }
          rest = parserResult.rest;
        }
        if (atLeastOneResultRequired && results.length === 0) {
          return;
        }
        return new P.Result(results, rest);
      };
      P.many1 = function(parser) {
        return function(input) {
          return P.baseMany(parser, null, false, true, input);
        };
      };
      P.concatMany1Till = function(parser, end) {
        return function(input) {
          return P.baseMany(parser, end, true, true, input);
        };
      };
      P.firstChoice = function() {
        var parsers;
        parsers = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        return function(input) {
          var i, length, parser, result;
          i = -1;
          length = parsers.length;
          while (++i < length) {
            parser = parsers[i];
            result = parser(input);
            if (result != null) {
              return result;
            }
          }
        };
      };
      newParser = function(options) {
        var U;
        U = {};
        U.wildcard = P.tag("wildcard", P.string(options.wildcardChar));
        U.optional = P.tag("optional", P.pick(1, P.string(options.optionalSegmentStartChar), P.lazy(function() {
          return U.pattern;
        }), P.string(options.optionalSegmentEndChar)));
        U.name = P.regex(new RegExp("^[" + options.segmentNameCharset + "]+"));
        U.named = P.tag("named", P.pick(1, P.string(options.segmentNameStartChar), P.lazy(function() {
          return U.name;
        })));
        U.escapedChar = P.pick(1, P.string(options.escapeChar), P.regex(/^./));
        U["static"] = P.tag("static", P.concatMany1Till(P.firstChoice(P.lazy(function() {
          return U.escapedChar;
        }), P.regex(/^./)), P.firstChoice(P.string(options.segmentNameStartChar), P.string(options.optionalSegmentStartChar), P.string(options.optionalSegmentEndChar), U.wildcard)));
        U.token = P.lazy(function() {
          return P.firstChoice(U.wildcard, U.optional, U.named, U["static"]);
        });
        U.pattern = P.many1(P.lazy(function() {
          return U.token;
        }));
        return U;
      };
      defaultOptions = {
        escapeChar: "\\",
        segmentNameStartChar: ":",
        segmentValueCharset: "a-zA-Z0-9-_~ %",
        segmentNameCharset: "a-zA-Z0-9",
        optionalSegmentStartChar: "(",
        optionalSegmentEndChar: ")",
        wildcardChar: "*"
      };
      baseAstNodeToRegexString = function(astNode, segmentValueCharset) {
        if (Array.isArray(astNode)) {
          return stringConcatMap(astNode, function(node) {
            return baseAstNodeToRegexString(node, segmentValueCharset);
          });
        }
        switch (astNode.tag) {
          case "wildcard":
            return "(.*?)";
          case "named":
            return "([" + segmentValueCharset + "]+)";
          case "static":
            return escapeForRegex(astNode.value);
          case "optional":
            return "(?:" + baseAstNodeToRegexString(astNode.value, segmentValueCharset) + ")?";
        }
      };
      astNodeToRegexString = function(astNode, segmentValueCharset) {
        if (segmentValueCharset == null) {
          segmentValueCharset = defaultOptions.segmentValueCharset;
        }
        return "^" + baseAstNodeToRegexString(astNode, segmentValueCharset) + "$";
      };
      astNodeToNames = function(astNode) {
        if (Array.isArray(astNode)) {
          return concatMap(astNode, astNodeToNames);
        }
        switch (astNode.tag) {
          case "wildcard":
            return ["_"];
          case "named":
            return [astNode.value];
          case "static":
            return [];
          case "optional":
            return astNodeToNames(astNode.value);
        }
      };
      getParam = function(params, key, nextIndexes, sideEffects) {
        var index, maxIndex, result, value;
        if (sideEffects == null) {
          sideEffects = false;
        }
        value = params[key];
        if (value == null) {
          if (sideEffects) {
            throw new Error("no values provided for key `" + key + "`");
          } else {
            return;
          }
        }
        index = nextIndexes[key] || 0;
        maxIndex = Array.isArray(value) ? value.length - 1 : 0;
        if (index > maxIndex) {
          if (sideEffects) {
            throw new Error("too few values provided for key `" + key + "`");
          } else {
            return;
          }
        }
        result = Array.isArray(value) ? value[index] : value;
        if (sideEffects) {
          nextIndexes[key] = index + 1;
        }
        return result;
      };
      astNodeContainsSegmentsForProvidedParams = function(astNode, params, nextIndexes) {
        var i, length;
        if (Array.isArray(astNode)) {
          i = -1;
          length = astNode.length;
          while (++i < length) {
            if (astNodeContainsSegmentsForProvidedParams(astNode[i], params, nextIndexes)) {
              return true;
            }
          }
          return false;
        }
        switch (astNode.tag) {
          case "wildcard":
            return getParam(params, "_", nextIndexes, false) != null;
          case "named":
            return getParam(params, astNode.value, nextIndexes, false) != null;
          case "static":
            return false;
          case "optional":
            return astNodeContainsSegmentsForProvidedParams(astNode.value, params, nextIndexes);
        }
      };
      stringify = function(astNode, params, nextIndexes) {
        if (Array.isArray(astNode)) {
          return stringConcatMap(astNode, function(node) {
            return stringify(node, params, nextIndexes);
          });
        }
        switch (astNode.tag) {
          case "wildcard":
            return getParam(params, "_", nextIndexes, true);
          case "named":
            return getParam(params, astNode.value, nextIndexes, true);
          case "static":
            return astNode.value;
          case "optional":
            if (astNodeContainsSegmentsForProvidedParams(astNode.value, params, nextIndexes)) {
              return stringify(astNode.value, params, nextIndexes);
            } else {
              return "";
            }
        }
      };
      UrlPattern2 = function(arg1, arg2) {
        var groupCount, options, parsed, parser, withoutWhitespace;
        if (arg1 instanceof UrlPattern2) {
          this.isRegex = arg1.isRegex;
          this.regex = arg1.regex;
          this.ast = arg1.ast;
          this.names = arg1.names;
          return;
        }
        this.isRegex = arg1 instanceof RegExp;
        if (!("string" === typeof arg1 || this.isRegex)) {
          throw new TypeError("argument must be a regex or a string");
        }
        if (this.isRegex) {
          this.regex = arg1;
          if (arg2 != null) {
            if (!Array.isArray(arg2)) {
              throw new Error("if first argument is a regex the second argument may be an array of group names but you provided something else");
            }
            groupCount = regexGroupCount(this.regex);
            if (arg2.length !== groupCount) {
              throw new Error("regex contains " + groupCount + " groups but array of group names contains " + arg2.length);
            }
            this.names = arg2;
          }
          return;
        }
        if (arg1 === "") {
          throw new Error("argument must not be the empty string");
        }
        withoutWhitespace = arg1.replace(/\s+/g, "");
        if (withoutWhitespace !== arg1) {
          throw new Error("argument must not contain whitespace");
        }
        options = {
          escapeChar: (arg2 != null ? arg2.escapeChar : void 0) || defaultOptions.escapeChar,
          segmentNameStartChar: (arg2 != null ? arg2.segmentNameStartChar : void 0) || defaultOptions.segmentNameStartChar,
          segmentNameCharset: (arg2 != null ? arg2.segmentNameCharset : void 0) || defaultOptions.segmentNameCharset,
          segmentValueCharset: (arg2 != null ? arg2.segmentValueCharset : void 0) || defaultOptions.segmentValueCharset,
          optionalSegmentStartChar: (arg2 != null ? arg2.optionalSegmentStartChar : void 0) || defaultOptions.optionalSegmentStartChar,
          optionalSegmentEndChar: (arg2 != null ? arg2.optionalSegmentEndChar : void 0) || defaultOptions.optionalSegmentEndChar,
          wildcardChar: (arg2 != null ? arg2.wildcardChar : void 0) || defaultOptions.wildcardChar
        };
        parser = newParser(options);
        parsed = parser.pattern(arg1);
        if (parsed == null) {
          throw new Error("couldn't parse pattern");
        }
        if (parsed.rest !== "") {
          throw new Error("could only partially parse pattern");
        }
        this.ast = parsed.value;
        this.regex = new RegExp(astNodeToRegexString(this.ast, options.segmentValueCharset));
        this.names = astNodeToNames(this.ast);
      };
      UrlPattern2.prototype.match = function(url) {
        var groups, match;
        match = this.regex.exec(url);
        if (match == null) {
          return null;
        }
        groups = match.slice(1);
        if (this.names) {
          return keysAndValuesToObject(this.names, groups);
        } else {
          return groups;
        }
      };
      UrlPattern2.prototype.stringify = function(params) {
        if (params == null) {
          params = {};
        }
        if (this.isRegex) {
          throw new Error("can't stringify patterns generated from a regex");
        }
        if (params !== Object(params)) {
          throw new Error("argument must be an object or undefined");
        }
        return stringify(this.ast, params, {});
      };
      UrlPattern2.escapeForRegex = escapeForRegex;
      UrlPattern2.concatMap = concatMap;
      UrlPattern2.stringConcatMap = stringConcatMap;
      UrlPattern2.regexGroupCount = regexGroupCount;
      UrlPattern2.keysAndValuesToObject = keysAndValuesToObject;
      UrlPattern2.P = P;
      UrlPattern2.newParser = newParser;
      UrlPattern2.defaultOptions = defaultOptions;
      UrlPattern2.astNodeToRegexString = astNodeToRegexString;
      UrlPattern2.astNodeToNames = astNodeToNames;
      UrlPattern2.getParam = getParam;
      UrlPattern2.astNodeContainsSegmentsForProvidedParams = astNodeContainsSegmentsForProvidedParams;
      UrlPattern2.stringify = stringify;
      return UrlPattern2;
    });
  }
});

// src/utils/image.ts
import loadImage from "blueimp-load-image";
var loadImageAsCanvas = async (file) => {
  const data = await loadImage(file, {
    maxWidth: 1920,
    maxHeight: 1920,
    canvas: true
  });
  return data;
};

// src/utils/color.ts
function checkIsLightColor(color) {
  let r, g, b;
  if (color.match(/^rgb/)) {
    const rgb = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (!rgb) return false;
    r = rgb[1];
    g = rgb[2];
    b = rgb[3];
  } else {
    const hexColor = +("0x" + color.slice(1).replace(color.length < 5 ? /./g : "", "$&$&"));
    r = hexColor >> 16;
    g = hexColor >> 8 & 255;
    b = hexColor & 255;
  }
  const hsp = Math.sqrt(
    0.299 * (Number(r) * Number(r)) + 0.587 * (Number(g) * Number(g)) + 0.114 * (Number(b) * Number(b))
  );
  return hsp > 127.5;
}

// src/utils/masking.ts
var maskingPhoneNumber = (content) => {
  return content.replace(/\d{2}[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,11}/, " ***-****-**** ").replace(/[공일]{2}[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,}/, " ***-****-**** ").replace(/(0\d{2})[.-\s](\d{3,4})[.-\s](\d{3,4})/g, " ***-****-**** ");
};
var maskingKakaoId = (content) => {
  return content.replace(/http[s]?:\/\/open\.kakao\.com(?:\/petitions)?(?:\/[a-zA-Z0-9.]+)*/g, "*****").replace(/(?:카톡|카카오|kakao)[\S ]*[a-zA-Z0-9]{5,10}/g, "*****");
};
var maskingSensitiveContent = (content, options) => {
  const { kakaoId = true, phoneNumber = true } = options != null ? options : {};
  return compose(...[kakaoId && maskingKakaoId, phoneNumber && maskingPhoneNumber].filter(truthy))(
    content
  );
};

// src/utils/onelink.ts
var BaseOnelinkUrl = /* @__PURE__ */ ((BaseOnelinkUrl2) => {
  BaseOnelinkUrl2["DEFAULT"] = "https://daangn.onelink.me/HhUa";
  return BaseOnelinkUrl2;
})(BaseOnelinkUrl || {});
var getDynamicOnelink = async (scheme, onelink_url, options) => {
  const realtyClient = new RealtyClientAPI();
  const path = onelink_url != null ? onelink_url : "https://daangn.onelink.me/HhUa" /* DEFAULT */;
  const params = new URLSearchParams({
    af_force_deeplink: "true",
    af_dp: scheme,
    deep_link_value: scheme,
    ...options
  });
  const url = `${path}?${params.toString()}`;
  const shortenUrl = await realtyClient.postShortenUrl(url).then((res) => res.url).catch(() => null);
  return {
    url,
    shortenUrl
  };
};

// src/utils/search.ts
function searchByFrequencyScore(query, items, extractKeyword = (item) => item) {
  if (!query) {
    return items;
  }
  const sanitizedInput = query.toLowerCase().replace(/[^a-z0-9가-힣\s]/g, " ");
  const searchWords = sanitizedInput.split(/\s+/).filter(Boolean);
  const matchedItems = items.map((item) => {
    const keyword = extractKeyword(item);
    const itemText = keyword.toLowerCase().replace(/[^a-z0-9가-힣\s]/g, " ");
    const score = searchWords.reduce((matchCount, word) => {
      return itemText.includes(word) ? matchCount + 1 : matchCount;
    }, 0);
    return { keyword, item, score };
  }).filter((result) => result.score > 0).sort((a, b) => b.score - a.score);
  return matchedItems.map((item) => item.item);
}

// src/constants/realty-webview-path.ts
var Path = {
  Main: "/",
  Articles: "/articles",
  NewArticle: "/articles/new",
  SimpleNewArticle: "/articles/new_simple",
  ArticleDraft: "/articles/draft",
  ArticleStepEdit: "/articles/step_edit/:stepId",
  Article: "/articles/:articleId",
  ArticleImages: "/articles/:articleId/images",
  ArticleCheckList: "/articles/:articleId/checklist",
  ArticleRoadView: "/articles/:articleId/road_view",
  ArticleCheckListMemo: "/articles/:articleId/checklist/memo",
  ArticleTradeTool: "/articles/:articleId/trade_tool",
  ArticleTradeToolDetail: "/articles/:articleId/trade_tool/:type",
  ArticleRequestVerify: "/articles/:articleId/request_verify",
  ArticleVerify: "/articles/:articleId/verify",
  EditArticle: "/articles/:articleId/edit",
  EditArticleImage: "/articles/:articleId/edit_image",
  PullupArticle: "/articles/:articleId/pullup",
  ArticleChannelList: "/articles/:articleId/channel_list",
  ArticleLandTitle: "/articles/:articleId/land_title",
  ArticleLandTitleCertificate: "/articles/:articleId/land_title/certificate",
  ArticleTradeSurvey: "/articles/:articleId/trade_survey",
  ArticleSunlight: "/articles/:articleId/sunlight",
  ArticleChangeCorAddress: "/articles/:articleId/change_cor_address",
  SearchAddress: "/search_address",
  SearchRegion: "/search_region",
  SearchUniversity: "/search_university",
  SearchJusoAddress: "/search_juso_address",
  SearchJusoUnit: "/search_juso_unit",
  Subscribe: "/subscribe",
  SubscribeSetting: "/subscribe_setting",
  SubscribeRegion: "/subscribe_region",
  SubscribeOnboarding: "/subscribe_onboarding",
  SatisfactionSurvey: "/satisfaction_survey",
  My: "/my",
  Review: "/review",
  ReviewNew: "/review/new",
  TradeReviewNew: "/trade_review/new",
  TradeReviewSelectBuyer: "/trade_review/select_buyer",
  SendReviews: "/send_reviews/:articleId",
  SellerReviews: "/seller_reviews",
  Filter: "/filter",
  Guide: "/guide/:salesType/:tradeType",
  ImageGuide: "/image_guide/:salesType",
  AlphaLogin: "/alpha_login",
  SmsIdentification: "/sms_identification/:articleId",
  MySalesHistory: "/my_sales_history",
  VerifyArticles: "/verify_articles",
  Faq: "/faq",
  SettingNotification: "/setting/notification/:type",
  ArticleCompare: "/article_compare",
  AdvertisementCreate: "/advertisement/create/:articleId",
  AIConversion: "/ai_conversion",
  RecentViewedArticles: "/recent_viewed_articles",
  BrokerPreOpenApplication: "/broker_pre_open_application",
  BrokerOnboarding: "/broker_onboarding",
  Complete: "/complete",
  BizProfileApprovalSetting: "/biz_profile/approval_setting",
  BizProfileCreate: "/biz_profile/create",
  BizProfileInfo: "/biz_profile/info",
  BizProfileReviews: "/biz_profile/:bizProfileId/reviews",
  BizProfileVerificationManage: "/biz_profile/verification_manage",
  BizProfileOwnerVerificationFailed: "/biz_profile/:bizProfileId/owner_verification/failed",
  BizProfileOwnerVerification: "/biz_profile/:bizProfileId/owner_verification",
  BizProfileBusinessCertificate: "/biz_profile/:bizProfileId/business_certificate",
  BizProfileArticles: "/biz_profile/:bizProfileId/articles",
  MarketingPriceSearch: "/marketing/price_search",
  MarketingPriceSearchResult: "/marketing/price_search/result",
  MyRealtyFortune: "/my_realty_fortune",
  MyRealtyFortuneResult: "/my_realty_fortune/result",
  Recap: "/recap",
  RecapResult: "/recap/result",
  FormBuildingUsage: "/form/building_usage",
  Map: "/map",
  BrokerHotArticles: "/broker_hot_articles/:region2Id"
};
var PathNames = {
  [Path.Main]: "\uBD80\uB3D9\uC0B0 \uD648",
  [Path.Articles]: "\uBD80\uB3D9\uC0B0 \uD648",
  [Path.NewArticle]: "\uB9E4\uBB3C \uB4F1\uB85D",
  [Path.SimpleNewArticle]: "\uAC04\uD3B8 \uB9E4\uBB3C \uB4F1\uB85D",
  [Path.ArticleDraft]: "\uC784\uC2DC\uC800\uC7A5 \uB9E4\uBB3C",
  [Path.ArticleStepEdit]: "\uB9E4\uBB3C \uB4F1\uB85D > \uB2E8\uACC4\uBCC4 \uC218\uC815",
  [Path.Article]: "\uB9E4\uBB3C \uC0C1\uC138",
  [Path.ArticleImages]: "\uB9E4\uBB3C \uC774\uBBF8\uC9C0 \uB9AC\uC2A4\uD2B8",
  [Path.ArticleCheckList]: "\uB9E4\uBB3C \uCCB4\uD06C\uB9AC\uC2A4\uD2B8",
  [Path.ArticleRoadView]: "\uB9E4\uBB3C \uB85C\uB4DC\uBDF0",
  [Path.ArticleCheckListMemo]: "\uB9E4\uBB3C \uCCB4\uD06C\uB9AC\uC2A4\uD2B8 \uBA54\uBAA8",
  [Path.ArticleTradeTool]: "\uAC70\uB798 \uB3C4\uAD6C",
  [Path.ArticleTradeToolDetail]: "\uB9E4\uBB3C \uC0C1\uC138 > \uAC70\uB798 \uB3C4\uAD6C \uC0C1\uC138",
  [Path.ArticleRequestVerify]: "\uC9D1\uC8FC\uC778 \uC778\uC99D \uC694\uCCAD",
  [Path.ArticleVerify]: "\uC9D1\uC8FC\uC778 \uC778\uC99D",
  [Path.EditArticle]: "\uB9E4\uBB3C \uC218\uC815",
  [Path.EditArticleImage]: "\uB9E4\uBB3C \uC774\uBBF8\uC9C0 \uC218\uC815",
  [Path.PullupArticle]: "\uB9E4\uBB3C \uB04C\uC5B4\uC62C\uB9AC\uAE30",
  [Path.ArticleChannelList]: "\uB9E4\uBB3C \uCC44\uD305 \uBAA9\uB85D",
  [Path.ArticleLandTitle]: "\uB9E4\uBB3C \uB4F1\uAE30\uBD80\uB4F1\uBCF8",
  [Path.ArticleLandTitleCertificate]: "\uB9E4\uBB3C \uB4F1\uAE30\uBD80\uB4F1\uBCF8 \uC6D0\uBCF8",
  [Path.ArticleTradeSurvey]: "\uAC70\uB798 \uD6C4\uAE30",
  [Path.ArticleSunlight]: "\uB9E4\uBB3C 3D \uC9C0\uB3C4",
  [Path.SearchAddress]: "\uC8FC\uC18C \uAC80\uC0C9",
  [Path.ArticleChangeCorAddress]: "\uB9E4\uBB3C \uB4F1\uAE30\uBD80 \uAE30\uBC18 \uC8FC\uC18C \uBCC0\uACBD",
  [Path.SearchRegion]: "\uC9C0\uC5ED \uAC80\uC0C9",
  [Path.SearchUniversity]: "\uB300\uD559\uAD50 \uAC80\uC0C9",
  [Path.SearchJusoAddress]: "juso \uAE30\uBC18 \uC8FC\uC18C \uAC80\uC0C9",
  [Path.SearchJusoUnit]: "juso \uAE30\uBC18 \uC8FC\uC18C unit \uAC80\uC0C9 \uC0C1\uC138",
  [Path.Subscribe]: "\uC0C8\uAE00 \uC54C\uB9BC",
  [Path.SubscribeSetting]: "\uC0C8\uAE00 \uC54C\uB9BC \uC124\uC815",
  [Path.SubscribeRegion]: "\uC0C8\uAE00 \uC54C\uB9BC \uC9C0\uC5ED",
  [Path.SubscribeOnboarding]: "\uC0C8\uAE00 \uC54C\uB9BC \uC628\uBCF4\uB529",
  [Path.SatisfactionSurvey]: "\uB9CC\uC871\uB3C4 \uC870\uC0AC",
  [Path.My]: "\uB9C8\uC774\uD398\uC774\uC9C0",
  [Path.Review]: "\uD6C4\uAE30",
  [Path.ReviewNew]: "\uD6C4\uAE30 \uC791\uC131",
  [Path.TradeReviewNew]: "\uAC70\uB798 \uD6C4\uAE30 \uC791\uC131",
  [Path.TradeReviewSelectBuyer]: "\uAC70\uB798 \uD6C4\uAE30 \uAD6C\uB9E4\uC790 \uC120\uD0DD",
  [Path.SendReviews]: "\uBCF4\uB0B8 \uD6C4\uAE30 \uD655\uC778",
  [Path.SellerReviews]: "\uC9C1\uC811 \uACC4\uC57D\uD55C \uD310\uB9E4\uC790\uB4E4\uC758 \uD6C4\uAE30",
  [Path.Filter]: "\uD544\uD130",
  [Path.Guide]: "\uC9C1\uAC70\uB798 \uAC00\uC774\uB4DC",
  [Path.ImageGuide]: "\uB9E4\uBB3C \uC791\uC131 \uC774\uBBF8\uC9C0 \uAC00\uC774\uB4DC",
  [Path.AlphaLogin]: "\uC54C\uD30C \uB85C\uADF8\uC778",
  [Path.SmsIdentification]: "SMS \uC778\uC99D (\uC9D1\uC8FC\uC778 \uC778\uC99D)",
  [Path.MySalesHistory]: "\uB0B4 \uD310\uB9E4 \uB0B4\uC5ED",
  [Path.VerifyArticles]: "\uC9D1\uC8FC\uC778\uC778\uC99D \uC5EC\uB7EC\uAC1C",
  [Path.Faq]: "FAQ (\uC11C\uBE44\uC2A4 \uAC1C\uC120 \uC81C\uC548 / \uACE0\uAC1D\uC13C\uD130 \uBB38\uC758)",
  [Path.SettingNotification]: "\uC54C\uB9BC \uC124\uC815 (\uB9E4\uBB3C \uCD94\uCC9C \uC54C\uB9BC / \uC774\uC6C3\uB4E4\uC758 \uAD00\uC2EC \uC54C\uB9BC)",
  [Path.ArticleCompare]: "\uB9E4\uBB3C \uBE44\uAD50",
  [Path.AdvertisementCreate]: "\uAD11\uACE0 \uC0DD\uC131",
  [Path.AIConversion]: "AI \uAE00\uC4F0\uAE30",
  [Path.RecentViewedArticles]: "\uCD5C\uADFC \uBCF8 \uB9E4\uBB3C",
  [Path.BrokerPreOpenApplication]: "\uC911\uAC1C\uC0AC \uC0AC\uC804 \uC2E0\uCCAD",
  [Path.BrokerOnboarding]: "\uC911\uAC1C\uC0AC \uC628\uBCF4\uB529",
  [Path.Complete]: "\uC644\uB8CC",
  [Path.BizProfileApprovalSetting]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uB9E4\uBB3C \uAC04\uD3B8 \uC2B9\uC778 \uC124\uC815",
  [Path.BizProfileCreate]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uC0DD\uC131",
  [Path.BizProfileBusinessCertificate]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uC0AC\uC5C5\uC790 \uC778\uC99D",
  [Path.BizProfileInfo]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uC815\uBCF4",
  [Path.BizProfileReviews]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uD6C4\uAE30",
  [Path.BizProfileArticles]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uB9E4\uBB3C \uBAA8\uC544\uBCF4\uAE30",
  [Path.MarketingPriceSearch]: "\uB9C8\uCF00\uD305 > \uC2DC\uC138 \uC870\uD68C",
  [Path.MarketingPriceSearchResult]: "\uB9C8\uCF00\uD305 > \uC2DC\uC138 \uC870\uD68C \uACB0\uACFC",
  [Path.MyRealtyFortune]: "\uD53C\uCC98\uD1A4 > \uBD80\uB3D9\uC0B0 \uC6B4\uC138",
  [Path.MyRealtyFortuneResult]: "\uD53C\uCC98\uD1A4 > \uBD80\uB3D9\uC0B0 \uC6B4\uC138 \uACB0\uACFC",
  [Path.Recap]: "\uD53C\uCC98\uD1A4 > \uC5F0\uB9D0\uACB0\uC0B0",
  [Path.RecapResult]: "\uD53C\uCC98\uD1A4 > \uC5F0\uB9D0\uACB0\uC0B0 \uACB0\uACFC",
  [Path.FormBuildingUsage]: "\uB9E4\uBB3C \uC791\uC131 > \uAC74\uCD95\uBB3C \uC6A9\uB3C4",
  [Path.BizProfileOwnerVerificationFailed]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uB300\uD45C\uC790 \uC778\uC99D \uC2E4\uD328",
  [Path.BizProfileOwnerVerification]: "\uC911\uAC1C\uC0AC \uBE44\uC988\uD504\uB85C\uD544 \uB300\uD45C\uC790 \uC778\uC99D",
  [Path.BizProfileVerificationManage]: "\uC911\uAC1C\uC0AC \uC778\uC99D \uAD00\uB9AC",
  [Path.Map]: "\uC9C0\uB3C4",
  [Path.BrokerHotArticles]: "\uC911\uAC1C\uC0AC \uC778\uAE30\uB9E4\uBB3C"
};
var realty_webview_path_default = Path;

// src/constants/realty-web-path.ts
var Path2 = {
  Root: "/",
  ArticlesVerify: "/articles/:articleId/verify",
  ArticlesEdit: "/articles/edit/:articleId",
  ArticlesNew: "/articles/new",
  AuthLogin: "/auth/login",
  Bridge: "/bridge",
  CeoArticlesEdit: "/ceo/articles/edit/:articleId",
  CeoArticlesNew: "/ceo/articles/new",
  CeoSales: "/ceo/sales",
  Sales: "/sales",
  SalesBiz: "/sales/biz",
  SalesMy: "/sales/my"
};
var realty_web_path_default = Path2;

// src/utils/path.ts
var import_url_pattern = __toESM(require_url_pattern());

// node_modules/@daangn/webview-link-router/dist/index.js
var protocols = {
  alpha: "karrot.alpha",
  production: "karrot"
};
function createScheme({ stage, path, searchParams }) {
  const protocol = protocols[stage];
  const scheme = new URL(`${protocol}://${path}`);
  if (searchParams) scheme.search = searchParams.toString();
  return scheme.toString();
}
function isKorean(char) {
  const match = char.match(/[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/g);
  return char === (match == null ? void 0 : match[0]);
}
function createMinikarrotScheme({ url, stage, navbar = false, scrollable = false, presentTop = false }) {
  const searchParams = new URLSearchParams();
  let koreanEncodedRemote = "";
  for (const c of url) {
    koreanEncodedRemote += isKorean(c) ? encodeURIComponent(c) : c;
  }
  searchParams.set("remote", koreanEncodedRemote);
  searchParams.set("navbar", String(navbar));
  searchParams.set("scrollable", String(scrollable));
  if (presentTop) {
    searchParams.set("present", "top");
  }
  return createScheme({
    stage,
    path: "minikarrot/router",
    searchParams
  });
}
var ecmaUserAgentRegex = RegExp("(?<userAgent>TowneersApp\\/(?<version>\\d+\\.\\d+\\.\\d+)\\s\\((?<versionCode>\\d+);\\s*(?<osType>iOS|Android)\\s(?<osVersion>[^;]+);\\s*(?<appType>\\w+);\\s*(?<buildType>\\w+)\\))");

// src/utils/path.ts
var generatePath = (pattern, params) => {
  const urlPattern = new import_url_pattern.default(pattern);
  const pathname = urlPattern.stringify(params);
  const matchResult = urlPattern.match(pathname);
  const pathParams = matchResult === null ? {} : matchResult;
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (!pathParams[key] && value !== void 0) {
      searchParams.append(key, encodeURIComponent(value));
    }
  });
  const searchString = searchParams.toString();
  return searchString ? `${pathname}?${searchString}` : pathname;
};
var generateWebviewPath = (name, params) => {
  return generatePath(realty_webview_path_default[name], params);
};
var createWebviewScheme = (stage, name, params, navbar, scrollable) => {
  const url = generateWebviewPath(name, params);
  return createMinikarrotScheme({ stage, url, navbar, scrollable });
};
var generateWebPath = (name, params) => {
  return generatePath(realty_web_path_default[name], params);
};
var createWebPath = (stage, name, params) => {
  const HOST = stage === "alpha" ? "https://realty.alpha.daangn.com" : "https://realty.daangn.com";
  return `${HOST}${generateWebPath(name, params)}`;
};

export {
  loadImageAsCanvas,
  checkIsLightColor,
  maskingSensitiveContent,
  BaseOnelinkUrl,
  getDynamicOnelink,
  searchByFrequencyScore,
  generateWebviewPath,
  createWebviewScheme,
  generateWebPath,
  createWebPath
};
