/**
 * @generated SignedSource<<02ecce0622ca9953b60b6b0135fd489d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ArticleInvisibleStatusEnum = "BLOCKED" | "CHECK_DENY" | "NONE";
export type ArticleStatusEnum = "ON_GOING" | "RESERVED" | "TRADED";
import { FragmentRefs } from "relay-runtime";
export type ArticleTitle_article$data = {
  readonly invisibleStatus: ArticleInvisibleStatusEnum;
  readonly isHide: boolean;
  readonly originalId: string;
  readonly status: ArticleStatusEnum;
  readonly " $fragmentSpreads": FragmentRefs<"articleTradeTypeAndPriceText_article">;
  readonly " $fragmentType": "ArticleTitle_article";
};
export type ArticleTitle_article$key = {
  readonly " $data"?: ArticleTitle_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleTitle_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deposit",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyPay",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleTitle_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invisibleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHide",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "articleTradeTypeAndPriceText_article",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "articleMainTrade_article",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "trades",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "preferred",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "ShortTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "MonthTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "yearlyPay",
                      "storageKey": null
                    }
                  ],
                  "type": "YearTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "type": "BorrowTrade",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    }
                  ],
                  "type": "BuyTrade",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "4e3601a2bca6a8f820fea12e1fd9ad8e";

export default node;
