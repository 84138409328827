/**
 * @generated SignedSource<<baae1bf4e09f5400d95aa40c02e1dad5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSearchJusoAddressResult_query$data = {
  readonly searchAddress: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly admCd: string;
        readonly buildingName: string;
        readonly buldMnnm: number;
        readonly buldSlno: number;
        readonly regionAddress: string;
        readonly rnMgtSn: string;
        readonly roadAddress: string;
        readonly roadName: string;
        readonly udrtYn: string;
        readonly zipNo: string;
      };
    }>;
  };
  readonly " $fragmentType": "useSearchJusoAddressResult_query";
};
export type useSearchJusoAddressResult_query$key = {
  readonly " $data"?: useSearchJusoAddressResult_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSearchJusoAddressResult_query">;
};

import useSearchJusoAddressResultRefetchQuery_graphql from './useSearchJusoAddressResultRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "searchAddress"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": useSearchJusoAddressResultRefetchQuery_graphql
    }
  },
  "name": "useSearchJusoAddressResult_query",
  "selections": [
    {
      "alias": "searchAddress",
      "args": [
        {
          "kind": "Variable",
          "name": "data",
          "variableName": "input"
        }
      ],
      "concreteType": "AddressConnection",
      "kind": "LinkedField",
      "name": "__useSearchJusoAddressResult_searchAddress_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AddressEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "zipNo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "regionAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roadAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "buildingName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roadName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "admCd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "buldMnnm",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "buldSlno",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rnMgtSn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "udrtYn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b6f52d83928a97c578bb991019ff033f";

export default node;
