import { editArticleMutation } from '@/__generated__/editArticleMutation.graphql';
import { editArticleQuery } from '@/__generated__/editArticleQuery.graphql';
import useArticleForFormFragment from '@/hooks/useArticleForFormFragment';
import MainEnvironment from '@/relay/environment';
import { getRelayNetworkErrorHandler, handleMutationResponse } from '@/relay/utils';
import ArticleForm from '@/routes/_protected/ceo/articles/-components/ArticleForm';
import type { ArticleFormType } from '@/scheme/article';
import { formToArticleInput, originalArticleToForm } from '@/utils/article';
import { realtyLocalStorage } from '@/utils/localStorage';
import logger from '@/utils/Logger';
import { Button, ProgressCircle } from '@daangn/carotene';
import { createFileRoute, useLoaderData, useNavigate, useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { graphql, loadQuery, useMutation, usePreloadedQuery } from 'react-relay';

export const Route = createFileRoute('/_protected/ceo/articles/edit/$articleId')({
  loader: async ({ params }) => {
    const result = await loadQuery<editArticleQuery>(MainEnvironment, EditArticleQuery, {
      articleId: params.articleId,
    });
    return result;
  },
  component: EditArticlePage,
});

function EditArticlePage() {
  const { articleId } = useParams({ from: Route.id });
  const [editedArticleId, setEditedArticleId] = useState<string | null>(null);
  const queryRef = useLoaderData({ from: Route.id });
  const { articleByOriginalArticleId } = usePreloadedQuery<editArticleQuery>(
    EditArticleQuery,
    queryRef
  );
  const article = useArticleForFormFragment(articleByOriginalArticleId);
  const initialValues = originalArticleToForm(article);

  const navigate = useNavigate();

  const [updateArticle, isInFlight] = useMutation<editArticleMutation>(graphql`
    mutation editArticleMutation($input: EditArticleInputV3!) {
      editArticleV3(input: $input) {
        __typename
        ... on EditArticleOutput_Result {
          result {
            article {
              id
              originalId
              ...useArticleForFormFragment_article
            }
          }
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  const handleClickWriteMore = () => {
    navigate({ to: '/ceo/articles/new', replace: true });
  };

  const handleClickCheckMyArticle = () => {
    navigate({ to: '/sales', replace: true });
  };

  const handleSubmit = (data: ArticleFormType) => {
    const input = formToArticleInput(data, {
      isEdit: true,
    });

    updateArticle({
      variables: {
        input: {
          ...input,
          originalArticleId: articleId,
        },
      },
      onCompleted: (data) => {
        handleMutationResponse(data.editArticleV3, {
          onResult: ({ result }) => {
            realtyLocalStorage.clearTempArticle();

            setEditedArticleId(result.article.originalId);

            logger.track('complete_edit_article', { article_id: result.article.originalId, input });
          },
          onToastError(toastError) {
            window.alert(toastError.toastError.message);
          },
        });
      },
      onError: getRelayNetworkErrorHandler(),
    });
  };

  if (!!editedArticleId) {
    return (
      <div className="flex flex-col items-center justify-center gap-6 pt-5 text-center md:pt-[200px]">
        <img
          style={{ width: 116, height: 120 }}
          src="https://asset-town.krrt.io/production/illust/ccea2e75-8732-4647-b41d-28d2bd5666b1/10b01f333feecd723b9bca674cd544b5e96b8bd4.webp"
        />
        <div className="flex flex-col gap-2">
          <h1 className="heading-large text-2xl">게시글 수정이 완료되었어요</h1>
          <p className="body-medium-default">
            내 게시글은 당근 앱 {'>'} 나의 당근 {'>'} 부동산에서 확인하실 수 있어요.
          </p>
        </div>
        <div className="space-y-3">
          <div>
            <Button size="xlarge" onClick={handleClickWriteMore} style={{ minWidth: 165 }}>
              매물 추가 등록하기
            </Button>
          </div>
          <div>
            <Button
              type="button"
              size="xlarge"
              variant="neutral"
              onClick={handleClickCheckMyArticle}
              style={{ minWidth: 165 }}
            >
              나의 매물 확인하기
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative pb-36 pt-12">
      {isInFlight && (
        <div className="bg-bg-overlay-default fixed bottom-0 left-0 right-0 top-0 z-50 flex h-full w-full items-center justify-center">
          <ProgressCircle />
        </div>
      )}
      <ArticleForm
        isEdit={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isLoading={isInFlight}
      />
    </div>
  );
}

const EditArticleQuery = graphql`
  query editArticleQuery($articleId: String!) {
    articleByOriginalArticleId(originalArticleId: $articleId) {
      ...useArticleForFormFragment_article
      writer {
        id
        originalId
      }
    }
  }
`;
