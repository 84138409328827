/**
 * @generated SignedSource<<479b49415dd62a6a89c2a7885fb7d83e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchAddressesDataInput = {
  query: string;
};
export type useSearchJusoAddressResultQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  input: SearchAddressesDataInput;
};
export type useSearchJusoAddressResultQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSearchJusoAddressResult_query">;
};
export type useSearchJusoAddressResultQuery = {
  response: useSearchJusoAddressResultQuery$data;
  variables: useSearchJusoAddressResultQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSearchJusoAddressResultQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "useSearchJusoAddressResult_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSearchJusoAddressResultQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AddressConnection",
        "kind": "LinkedField",
        "name": "searchAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipNo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regionAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roadAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buildingName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roadName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "admCd",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buldMnnm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buldSlno",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rnMgtSn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "udrtYn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "data"
        ],
        "handle": "connection",
        "key": "useSearchJusoAddressResult_searchAddress",
        "kind": "LinkedHandle",
        "name": "searchAddress"
      }
    ]
  },
  "params": {
    "cacheID": "1844f446532a9be8eeea1cb74e1afb07",
    "id": null,
    "metadata": {},
    "name": "useSearchJusoAddressResultQuery",
    "operationKind": "query",
    "text": "query useSearchJusoAddressResultQuery(\n  $cursor: String\n  $count: Int\n  $input: SearchAddressesDataInput!\n) {\n  ...useSearchJusoAddressResult_query\n}\n\nfragment useSearchJusoAddressResult_query on Query {\n  searchAddress(first: $count, after: $cursor, data: $input) {\n    edges {\n      node {\n        zipNo\n        regionAddress\n        roadAddress\n        buildingName\n        roadName\n        admCd\n        buldMnnm\n        buldSlno\n        rnMgtSn\n        udrtYn\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01e3cbbb90ab1236e89f2eb5a705316a";

export default node;
