import {
  FormStep_sendSmsMutation,
  FormStep_sendSmsMutation$data,
} from '@/__generated__/FormStep_sendSmsMutation.graphql';
import Button from '@/components/base/Button';
import { getRelayNetworkErrorHandler } from '@/relay/utils';
import { FormControl, TextField } from '@daangn/carotene';
import { createRef, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { graphql, useMutation } from 'react-relay';
import { z } from 'zod';
import { Section, Step } from '../$articleId.verify';
import IdCardField from './IdCardField';
import PhoneField from './PhoneField';
import TermsBottomSheet from './TermsBottomSheet';
import { CommonRegex } from '@daangn/realty-sdk';
import { SmsVerifyCarrierEnum } from '@/types/schemaEnums';

export const formSchema = z.object({
  name: z.string().min(1, '필수 항목이에요.'),
  phone: z.string().regex(CommonRegex.PhoneNumber, '올바른 휴대폰 번호를 입력해주세요'),
  telecom: z.nativeEnum(SmsVerifyCarrierEnum, {
    errorMap: () => ({ message: '통신사를 선택해주세요' }),
  }),
  idCard: z
    .object({
      first: z.string(),
      last: z.string(),
    })
    .refine((data) => data.first && data.last, {
      message: '필수 항목이에요.',
    })
    .refine((data) => !(data.first.length < 6), {
      message: '올바른 형식의 주민등록번호를 입력해주세요',
    }),
  termsAgreed: z.boolean(),
});

type FormStepProps = {
  articleId: string;
  form: UseFormReturn<z.infer<typeof formSchema>>;
  onSend: (response: FormStep_sendSmsMutation$data['sendIdentificationOtpSms']) => void;
};

const FormStep = ({ form, onSend, articleId }: FormStepProps) => {
  const [showTermSheet, setShowTermSheet] = useState(false);
  const [formRefs] = useState({
    name: createRef<HTMLInputElement>(),
    idCard: createRef<HTMLInputElement>(),
    telecom: createRef<HTMLButtonElement>(),
  });

  const [sendSms, sendSmsInFlight] = useMutation<FormStep_sendSmsMutation>(graphql`
    mutation FormStep_sendSmsMutation($input: SendIdentificationOtpSmsInput!) {
      sendIdentificationOtpSms(input: $input) {
        ... on SendIdentificationOtpSms_Result {
          result {
            identificationVerifyRequestId
            otpValidDuration
          }
        }
        ... on ToastError {
          toastError {
            message
          }
        }
        ... on PopupError {
          popupError {
            message
          }
        }
      }
    }
  `);

  const handleAgreed = () => {
    form.setValue('termsAgreed', true);
    setShowTermSheet(false);
    handleSubmit();
  };

  const handleSubmit = async () => {
    form.handleSubmit(() => {
      if (!form.getValues('termsAgreed')) {
        setShowTermSheet(true);
        return;
      }

      const { name, phone, telecom, idCard } = form.getValues();

      sendSms({
        variables: {
          input: {
            carrier: telecom,
            first7Jumin: `${idCard.first}${idCard.last}`,
            phoneNumber: `+82${phone.slice(1)}`,
            realName: name,
          },
        },
        onCompleted: (response) => {
          setShowTermSheet(false);
          onSend(response.sendIdentificationOtpSms);
        },
        onError: getRelayNetworkErrorHandler(),
      });
    })();
  };

  const handleNameComplete = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      formRefs.idCard.current?.focus();
    }
  };

  const handleIdCardComplete = () => {
    setTimeout(() => {
      formRefs.telecom.current?.click();
    }, 0);
  };

  const handlePhoneComplete = () => {
    handleSubmit();
  };

  return (
    <Section.Container stepId={`${Step.SmsIdentification}-form`} articleId={articleId}>
      <Section.Header title="집주인 확인을 위한 본인인증이 필요해요" />

      <Section.Content gap={6}>
        <FormControl
          label="집주인 이름"
          invalid={!!form.formState.errors.name}
          errorMessage={form.formState.errors.name?.message}
        >
          <TextField
            autoFocus
            placeholder="이름을 입력해주세요"
            onKeyDown={handleNameComplete}
            {...form.register('name')}
          />
        </FormControl>

        <Controller
          control={form.control}
          name="idCard"
          render={({ field }) => (
            <>
              <IdCardField
                ref={formRefs.idCard}
                value={field.value}
                onChange={field.onChange}
                invalid={!!form.formState.errors.idCard}
                errorMessage={form.formState.errors.idCard?.message}
                onComplete={handleIdCardComplete}
              />
            </>
          )}
        />

        <Controller
          control={form.control}
          name="phone"
          render={({ field: phoneField }) => (
            <Controller
              control={form.control}
              name="telecom"
              render={({ field: telecomField }) => (
                <PhoneField
                  ref={formRefs.telecom}
                  phone={phoneField.value}
                  telecom={telecomField.value}
                  onChangePhone={phoneField.onChange}
                  onChangeTelecom={telecomField.onChange}
                  invalid={!!form.formState.errors.telecom || !!form.formState.errors.phone}
                  errorMessage={
                    form.formState.errors.telecom?.message || form.formState.errors.phone?.message
                  }
                  onComplete={handlePhoneComplete}
                />
              )}
            />
          )}
        />
      </Section.Content>

      <Section.BottomFixed>
        <Button
          variant="brand"
          size="xlarge"
          className="w-full"
          onClick={handleSubmit}
          loading={sendSmsInFlight}
        >
          인증번호 받기
        </Button>
      </Section.BottomFixed>

      <TermsBottomSheet
        isOpen={showTermSheet}
        onClose={() => setShowTermSheet(false)}
        onAgree={handleAgreed}
      />
    </Section.Container>
  );
};

export default FormStep;
