/**
 * @generated SignedSource<<29faabf03553b86cd17bd9f856adcf5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VerifyArticleByKoreaIdentificationInput = {
  articleId: string;
  requestId: string;
  smsOtp: string;
};
export type VerificationStep_verifySmsMutation$variables = {
  input: VerifyArticleByKoreaIdentificationInput;
};
export type VerificationStep_verifySmsMutation$data = {
  readonly verifyArticleByKoreaIdentification: {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    readonly __typename: "VerifyArticleByKoreaIdentificationOutput_Result";
    readonly result: {
      readonly article: {
        readonly id: string;
        readonly isWriterVerify: boolean;
      };
    };
  } | {
    readonly __typename: "VerifyArticleByKoreaIdentificationUnmatch";
    readonly _DO_NOT_USE_THIS: boolean | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type VerificationStep_verifySmsMutation = {
  response: VerificationStep_verifySmsMutation$data;
  variables: VerificationStep_verifySmsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "verifyArticleByKoreaIdentification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VerifyArticleByKoreaIdentificationOutput_ResultPayload",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "article",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isWriterVerify",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "VerifyArticleByKoreaIdentificationOutput_Result",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_DO_NOT_USE_THIS",
            "storageKey": null
          }
        ],
        "type": "VerifyArticleByKoreaIdentificationUnmatch",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToastErrorPayload",
            "kind": "LinkedField",
            "name": "toastError",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ToastError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerificationStep_verifySmsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerificationStep_verifySmsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "92c1f96e4a7c3fcd991ca944347b4bed",
    "id": null,
    "metadata": {},
    "name": "VerificationStep_verifySmsMutation",
    "operationKind": "mutation",
    "text": "mutation VerificationStep_verifySmsMutation(\n  $input: VerifyArticleByKoreaIdentificationInput!\n) {\n  verifyArticleByKoreaIdentification(input: $input) {\n    __typename\n    ... on VerifyArticleByKoreaIdentificationOutput_Result {\n      result {\n        article {\n          id\n          isWriterVerify\n        }\n      }\n    }\n    ... on VerifyArticleByKoreaIdentificationUnmatch {\n      _DO_NOT_USE_THIS\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ce5f020cde49e74b6c0f41af839ccb88";

export default node;
