import Field from '@/routes/_protected/ceo/articles/-components/Field';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { SalesTypeEnum } from '@/types/schemaEnums';
import { useController } from 'react-hook-form';
import { getArticleSalesTypeText } from '@daangn/realty-sdk';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { Radio, RadioGroup } from '@daangn/carotene';

const SalesTypes = [
  SalesTypeEnum.OpenOneRoom,
  SalesTypeEnum.SplitOneRoom,
  SalesTypeEnum.TwoRoom,
  SalesTypeEnum.Apart,
  SalesTypeEnum.Officetel,
  SalesTypeEnum.Store,
  SalesTypeEnum.Etc,
];

const SalesTypeField = () => {
  const { control } = useArticleForm();
  const salesType = useController({ control, name: 'salesType' });
  const etcSalesType = useController({ control, name: 'etcSalesType' });

  const handleValueChange = (value: SalesTypeEnum) => {
    salesType.field.onChange(value);
    if (value === SalesTypeEnum.Etc) {
      etcSalesType.field.onChange('토지');
    }
  };

  return (
    <Field label="매물 종류" {...salesType.fieldState}>
      <RadioGroup
        {...salesType.field}
        value={salesType.field.value ?? undefined}
        onValueChange={(v) => handleValueChange(v as SalesTypeEnum)}
      >
        <div className="grid w-full grid-cols-6 gap-6">
          {SalesTypes.map((type) => (
            <FieldLogger key={type} name="sales_type" eventParams={{ type }}>
              <Radio
                value={type}
                label={getArticleSalesTypeText(type, {
                  subText: type !== SalesTypeEnum.Etc,
                  etcText: type === SalesTypeEnum.Etc ? '토지' : undefined,
                })}
              />
            </FieldLogger>
          ))}
        </div>
      </RadioGroup>
    </Field>
  );
};

export default SalesTypeField;
