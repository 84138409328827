import { MonthlyPayableTypes } from '@/constants/article';
import Divider from '@/components/base/Divider';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import Section from '@/routes/_protected/ceo/articles/-components/Section';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { MAX_TRADE_DESCRIPTION_LENGTH } from '@/scheme/article';
import { TradeTypeEnum } from '@/types/schemaEnums';
import { removeDecimal } from '@/utils/number';
import { replaceEmoji } from '@/utils/validate';
import { billionConvert, getArticleTradeTypeText } from '@daangn/realty-sdk';
import { omit } from 'lodash-es';
import { Fragment } from 'react';
import { Controller, useController } from 'react-hook-form';
import { Checkbox, TextField, FormControl, MultilineTextField } from '@daangn/carotene';

const AdjustableTradeTypes = [TradeTypeEnum.Month, TradeTypeEnum.Year];

const PriceField = () => {
  const { control } = useArticleForm();

  const { field } = useController({ control, name: 'trades' });

  if (!field.value?.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-10">
      {field.value.map((trade, index) => {
        const isMonthlyPayable = MonthlyPayableTypes.includes(
          trade.tradeType as (typeof MonthlyPayableTypes)[number]
        );
        const isAdjustable = AdjustableTradeTypes.includes(trade.tradeType);
        return (
          <Fragment key={trade.tradeType}>
            <Section title={`${getArticleTradeTypeText(trade.tradeType)} 가격 정보`}>
              <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2 *:flex-grow">
                    <Controller
                      control={control}
                      name={`trades.${index}.price`}
                      render={({ field, fieldState }) => (
                        <FieldLogger name="price" eventParams={{ type: trade.tradeType }}>
                          <FormControl
                            label={trade.tradeType === TradeTypeEnum.Buy ? '가격' : '보증금'}
                            invalid={!!fieldState.error}
                            errorMessage={fieldState.error?.message}
                            description={
                              !isNaN(Number(field.value ?? 0)) && (field.value ?? 0) > 9999
                                ? billionConvert(field.value ?? 0, {
                                    withUnit: true,
                                  })
                                : undefined
                            }
                          >
                            <TextField
                              {...field}
                              placeholder="0"
                              suffix="만원"
                              type="number"
                              value={String(field.value)}
                              onChange={(e) =>
                                field.onChange(removeDecimal(Number(e.target.value)))
                              }
                            />
                          </FormControl>
                        </FieldLogger>
                      )}
                    />
                    {isMonthlyPayable && (
                      <Controller
                        control={control}
                        name={`trades.${index}.monthlyPay`}
                        render={({ field, fieldState }) => (
                          <FieldLogger name="monthly_pay" eventParams={{ type: trade.tradeType }}>
                            <FormControl
                              label={trade.tradeType === TradeTypeEnum.Year ? '연세' : '월세'}
                              invalid={!!fieldState.error}
                              errorMessage={fieldState.error?.message}
                            >
                              <TextField
                                {...field}
                                placeholder="0"
                                suffix="만원"
                                type="number"
                                value={String(field.value)}
                                onChange={(e) =>
                                  field.onChange(removeDecimal(Number(e.target.value)))
                                }
                              />
                            </FormControl>
                          </FieldLogger>
                        )}
                      />
                    )}
                  </div>
                  {isAdjustable && (
                    <Controller
                      control={control}
                      name={`trades.${index}.adjustable`}
                      render={({ field, fieldState }) => (
                        <>
                          <div>
                            <FieldLogger name="adjustable" eventParams={{ type: trade.tradeType }}>
                              <Checkbox
                                {...omit(field, 'value', 'onChange')}
                                onCheckedChange={(checked) => field.onChange(checked)}
                                checked={!!field.value}
                                invalid={!!fieldState.error}
                                label="보증금 조정 가능"
                              />
                            </FieldLogger>
                          </div>
                          {!!field.value && (
                            <Controller
                              control={control}
                              name={`trades.${index}.description`}
                              render={({ field, fieldState }) => (
                                <FieldLogger
                                  name="trade_description"
                                  eventParams={{ type: trade.tradeType }}
                                >
                                  <FormControl
                                    invalid={!!fieldState.error}
                                    errorMessage={fieldState.error?.message}
                                  >
                                    <TextField
                                      {...field}
                                      placeholder="보증금 조정시 금액을 설명해주세요."
                                      value={field.value ?? ''}
                                      onChange={(e) => field.onChange(replaceEmoji(e.target.value))}
                                      maxLength={MAX_TRADE_DESCRIPTION_LENGTH}
                                    />
                                  </FormControl>
                                </FieldLogger>
                              )}
                            />
                          )}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              {trade.tradeType === TradeTypeEnum.Short && (
                <Controller
                  control={control}
                  name={`trades.${index}.description`}
                  render={({ field, fieldState }) => (
                    <FormControl
                      label="조건"
                      invalid={!!fieldState.error}
                      errorMessage={fieldState.error?.message}
                    >
                      <MultilineTextField
                        {...field}
                        placeholder="단기임대 기간, 조건에 대해 설명해 주세요."
                        value={field.value ?? ''}
                        onChange={(v) => field.onChange(replaceEmoji(v.target.value))}
                        maxLength={100}
                      />
                    </FormControl>
                  )}
                />
              )}
            </Section>
            {index < field.value.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default PriceField;
