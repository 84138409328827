import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { useController } from 'react-hook-form';
import { omit } from 'lodash-es';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { Checkbox, FormControl, TextField } from '@daangn/carotene';

const ManageCostField = () => {
  const { control } = useArticleForm();

  const manageCost = useController({ control, name: 'manageCost' });
  const etcManageCost = useController({ control, name: 'etcManageCost' });
  const includeManageCostOption = useController({
    control,
    name: 'includeManageCostOption',
  });
  const isUnknownManageCost = useController({
    control,
    name: 'isUnknownManageCost',
  });

  const handleCheckIsUnknownManageCost = (isChecked: boolean) => {
    isUnknownManageCost.field.onChange(isChecked);
    if (isChecked) {
      manageCost.field.onChange(undefined);
      etcManageCost.field.onChange(undefined);
      includeManageCostOption.field.onChange(undefined);
    }
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-4">
        <div className="flex gap-6 *:grow">
          <FieldLogger name="manage_cost" eventParams={{}}>
            <FormControl
              label="공용 관리비"
              invalid={!!manageCost.fieldState.error}
              errorMessage={manageCost.fieldState.error?.message}
            >
              <TextField
                {...manageCost.field}
                suffix="만원"
                value={String(manageCost.field.value ?? '')}
                onChange={(e) => manageCost.field.onChange(Number(e.target.value))}
                type="number"
                placeholder="0"
                disabled={!!isUnknownManageCost.field.value}
              />
            </FormControl>
          </FieldLogger>
          <FieldLogger name="etc_manage_cost" eventParams={{}}>
            <FormControl
              label="기타 관리비"
              invalid={!!etcManageCost.fieldState.error}
              indicator="(선택)"
              errorMessage={etcManageCost.fieldState.error?.message}
            >
              <TextField
                {...etcManageCost.field}
                suffix="만원"
                value={String(etcManageCost.field.value ?? '')}
                onChange={(e) => etcManageCost.field.onChange(Number(e.target.value))}
                type="number"
                placeholder="0"
                disabled={!!isUnknownManageCost.field.value}
              />
            </FormControl>
          </FieldLogger>
        </div>
        <Checkbox
          {...omit(isUnknownManageCost.field, 'value')}
          onCheckedChange={(checked) => handleCheckIsUnknownManageCost(checked)}
          label="확인이 어려워요"
          checked={!!isUnknownManageCost.field.value}
          invalid={!!isUnknownManageCost.fieldState.error}
        />
      </div>
    </div>
  );
};

export default ManageCostField;
