import {
  FormProvider,
  Message,
  useForm,
  type DefaultValues,
  type UseFormReturn,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArticleFormInputType, articleFormScheme, type ArticleFormType } from '@/scheme/article';
import SalesTypeField from '@/routes/_protected/ceo/articles/-components/SalesTypeField';
import TradeTypeField from '@/routes/_protected/ceo/articles/-components/TradeTypeField';
import AddressInfoField from '@/routes/_protected/ceo/articles/-components/AddressInfoField';
import PriceField from '@/routes/_protected/ceo/articles/-components/PriceField';
import OrientationField from '@/routes/_protected/ceo/articles/-components/OrientationField';
import AreaField from '@/routes/_protected/ceo/articles/-components/AreaField';
import RoomFloorField from '@/routes/_protected/ceo/articles/-components/RoomFloorField';
import ManageCostField from '@/routes/_protected/ceo/articles/-components/ManageCostField';
import RequiredOptionField from '@/routes/_protected/ceo/articles/-components/RequiredOptionField';
import IncludeManageCostOptionField from '@/routes/_protected/ceo/articles/-components/IncludeManageCostOptionField';
import OptionField from '@/routes/_protected/ceo/articles/-components/OptionField';
import QualitativeField from '@/routes/_protected/ceo/articles/-components/QualitativeField';
import ContentField from '@/routes/_protected/ceo/articles/-components/ContentField';
import AddressField from '@/routes/_protected/ceo/articles/-components/AddressField';
import ImageField from '@/routes/_protected/ceo/articles/-components/ImageField';
import VideoField from '@/routes/_protected/ceo/articles/-components/VideoField';
import MoveInDateField from '@/routes/_protected/ceo/articles/-components/MoveInDateField';
import BuildingApprovalDate from '@/routes/_protected/ceo/articles/-components/BuildingApprovalDateField';
import Divider from '@/components/base/Divider';
import Section from './Section';
import BrokerInfo from './BrokerInfo';
import { Button } from '@daangn/carotene';
import BuildingUsageField from '@/routes/_protected/ceo/articles/-components/BuildingUsageField';
import ContactTargetNumberField from '@/routes/_protected/ceo/articles/-components/ContactTargetNumberField';
import { useImperativeHandle, useRef, useState, forwardRef, Suspense, lazy } from 'react';
import type { z } from 'zod';
import logger from '@/utils/Logger';
import { getElementOffsetTop } from '@/utils/dom';
import { STAGE } from '@/config';
import useAppQuery from '@/hooks/useAppQuery';
import { SalesTypeEnum } from '@/types/schemaEnums';
import PremiumMoneyField from '@/routes/_protected/ceo/articles/-components/PremiumMoneyField';
import AiConversionDialog from '@/routes/_protected/ceo/articles/-components/AiConversionDialog';
import { checkIsLandSalesType } from '@daangn/realty-sdk/services';
import LandTypeField from '@/routes/_protected/ceo/articles/-components/LandTypeField';
import LandPurposeField from '@/routes/_protected/ceo/articles/-components/LandPurposeField';

export type ArticleFormRef = UseFormReturn<ArticleFormInputType>;

type ArticleFormProps = {
  isEdit?: boolean;
  isLoading?: boolean;
  onSubmit: (data: ArticleFormType) => void;
  initialValues?: DefaultValues<ArticleFormInputType>;
};

export type ArticleFormFieldError = {
  [K in keyof ArticleFormInputType]?: {
    type: string;
    message?: Message;
  };
};

const ArticleForm = forwardRef<ArticleFormRef, ArticleFormProps>(
  ({ onSubmit, initialValues, isEdit, isLoading }, ref) => {
    const containerRef = useRef<HTMLFormElement>(null);
    const { viewer } = useAppQuery();
    const [isAIConversionComplete, setIsAIConversionComplete] = useState(false);
    const form = useForm<ArticleFormInputType>({
      resolver: zodResolver(articleFormScheme),
      defaultValues: {
        ...initialValues,
        contactTargetPhoneNumber:
          initialValues?.contactTargetPhoneNumber ?? viewer?.bizProfileBroker?.userPhoneNumber,
      },
    });
    const isStore = form.watch('salesType') === SalesTypeEnum.Store;
    const isLand = checkIsLandSalesType({
      salesType: form.watch('salesType'),
      etcSalesType: form.watch('etcSalesType'),
    });

    useImperativeHandle(ref, () => form);

    const handleAIConversionComplete = () => {
      setIsAIConversionComplete(true);
    };

    const handleClickSubmit = async () => {
      logger.track('click_submit', {});

      form.handleSubmit(
        async (values: unknown) => {
          const form = values as z.infer<typeof articleFormScheme>;
          onSubmit(form);
        },
        (errors) => {
          const param = Object.fromEntries(
            Object.entries(errors).map(([name, { message, type }]) => [name, { message, type }])
          );

          logger.track('failed_submit', {
            errors: param as ArticleFormFieldError,
          });

          const firstError = Object.entries(errors).sort((a, b) => {
            const aElement = containerRef.current?.querySelector<HTMLElement>(`[name=${a[0]}]`);
            const bElement = containerRef.current?.querySelector<HTMLElement>(`[name=${b[0]}]`);

            if (aElement && bElement) {
              return getElementOffsetTop(aElement) - getElementOffsetTop(bElement);
            }
            return 0;
          })[0];

          if (firstError) {
            const [name] = firstError;
            const element = containerRef.current?.querySelector<HTMLElement>(`[name=${name}]`);
            element?.focus();
            element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      )();
    };

    return (
      <div>
        <FormProvider {...form}>
          <form ref={containerRef} className="flex flex-col gap-10" onSubmit={handleClickSubmit}>
            <div className="flex flex-col gap-12">
              <Section title="중개사 정보">
                <BrokerInfo />
              </Section>
              <Divider />
              {!isEdit}
              <Section
                title="기본 정보"
                action={
                  !isEdit && !isAIConversionComplete ? (
                    <AiConversionDialog onConversionComplete={handleAIConversionComplete} />
                  ) : null
                }
              >
                <SalesTypeField />
                <TradeTypeField />
              </Section>
              <Divider />
              <Section title="매물 소재지/면적">
                <AddressField disabled={isEdit} />
                <AreaField />
              </Section>
              <Divider />
              <Section title="매물 정보">
                <PropertyInfoSection isLand={isLand} />
              </Section>
              <Divider />
              <PriceField />
              <Divider />
              {!isLand && <ManagementSection isStore={isStore} />}
              <Divider />
              <DetailInfoSection isStore={isStore} isLand={isLand} />
            </div>
          </form>
          <Suspense fallback={null}>
            <DevFormHelper />
          </Suspense>
        </FormProvider>

        <div className="border-stroke-decorative bg-bg-layerDefault fixed bottom-0 left-0 right-0 border-t">
          <div className="mx-auto flex h-24 max-w-screen-md items-center px-2 py-6 md:px-0">
            <p className="body-xsmall-default text-fg-placeholder">
              매물 등록 시에 공인중개사법 및 하위 법령, 중개대상물의 표시·광고 명시사항 세부기준
              등을 준수해야 하며,
              <br />
              이를 준수하지 않아 발생하는 불이익에 대해 당사는 책임지지 않습니다.
            </p>
            <div className="spacer" />
            <Button type="submit" size="xlarge" onClick={handleClickSubmit} disabled={isLoading}>
              {isEdit ? '매물 수정' : '매물 등록'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default ArticleForm;

const PropertyInfoSection = ({ isLand }: { isLand: boolean }) => {
  if (isLand) {
    return <LandTypeField />;
  }

  return (
    <>
      <BuildingApprovalDate />
      <RoomFloorField />
      <OrientationField />
      <BuildingUsageField />
      <div className="grid grid-cols-2 gap-6">
        <RequiredOptionField />
      </div>
    </>
  );
};

const ManagementSection = ({ isStore }: { isStore: boolean }) => {
  if (isStore) {
    return (
      <Section title="권리금">
        <PremiumMoneyField />
      </Section>
    );
  }

  return (
    <>
      <Section title="관리비">
        <ManageCostField />
        <div className="grid grid-cols-2 gap-6">
          <IncludeManageCostOptionField />
        </div>
      </Section>
      <Divider />
      <Section title="내외부 시설">
        <OptionField />
      </Section>
    </>
  );
};

const DetailInfoSection = ({ isStore, isLand }: { isStore: boolean; isLand: boolean }) => {
  return (
    <Section title="매물 상세 정보">
      <div className="flex justify-between gap-6">
        {!isLand && <MoveInDateField />}
        <AddressInfoField />
      </div>
      {!isStore && !isLand && <QualitativeField />}
      {isLand && <LandPurposeField />}
      <ContentField />
      <ImageField />
      <VideoField />
      <Divider />
      <ContactTargetNumberField />
    </Section>
  );
};

const DevFormHelper =
  STAGE === 'production'
    ? () => null
    : lazy(() => import('@/routes/_protected/ceo/articles/-components/DevFormHelper'));
