/**
 * @generated SignedSource<<159bf9bd527f0ce2d758788589dce0e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CheckIrosQuery$variables = Record<PropertyKey, never>;
export type CheckIrosQuery$data = {
  readonly isIrosMaintenance: boolean;
};
export type CheckIrosQuery = {
  response: CheckIrosQuery$data;
  variables: CheckIrosQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isIrosMaintenance",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckIrosQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CheckIrosQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8d66361e24c89db3e99bc74d1fc9fdaa",
    "id": null,
    "metadata": {},
    "name": "CheckIrosQuery",
    "operationKind": "query",
    "text": "query CheckIrosQuery {\n  isIrosMaintenance\n}\n"
  }
};
})();

(node as any).hash = "29c49a7ddee65c08f08fb35c3d59b3ec";

export default node;
