import { CheckIros_IrosAlarmMutation } from '@/__generated__/CheckIros_IrosAlarmMutation.graphql';
import type { CheckIrosQuery } from '@/__generated__/CheckIrosQuery.graphql';
import { getRelayNetworkErrorHandler } from '@/relay/utils';
import { useEffect } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';

type Props = {
  type: 'write' | 'verify';
  onMaintenance?: () => void;
};

const messages = {
  write:
    '시스템 점검으로 게시글을 작성할 수 없어요. 매물 작성이 가능해지면 당근 앱으로 알림을 보내드려요.',
  verify: '등기소 점검으로 집주인 인증을 할 수 없어요. 점검이 완료된 후 다시 시도해주세요.',
};

const CheckIros = ({ onMaintenance, type }: Props) => {
  const { isIrosMaintenance } = useLazyLoadQuery<CheckIrosQuery>(
    graphql`
      query CheckIrosQuery {
        isIrosMaintenance
      }
    `,
    {}
  );
  const [setIrosMaintenanceAlarm] = useMutation<CheckIros_IrosAlarmMutation>(graphql`
    mutation CheckIros_IrosAlarmMutation($input: SetIrosMaintenanceFinishAlarmInput!) {
      setIrosMaintenanceFinishAlarm(input: $input) {
        __typename
        ... on SetIrosMaintenanceFinishAlarmOutput_Result {
          result
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  const message = messages[type];

  useEffect(() => {
    if (isIrosMaintenance) {
      window.alert(message);

      onMaintenance?.();

      type === 'write' &&
        setIrosMaintenanceAlarm({
          variables: {
            input: {
              requestType: 'CREATE_ARTICLE',
            },
          },
          onError: getRelayNetworkErrorHandler(),
        });
    }
  }, [isIrosMaintenance]);

  return null;
};

export default CheckIros;
