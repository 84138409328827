export const STAGE = import.meta.env.VITE_STAGE || 'alpha';
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;
export const IS_LOCAL = !!import.meta.env.DEV;
export const IS_PROD = STAGE === 'production';

export const OVERRIDE_API_HOST = !IS_PROD
  ? new URLSearchParams(window.location.search).get('override_api_host')
  : null;

export const config = {
  alpha: {
    sentryDSN: 'https://81295634f05cfb443273e58ec6cf368b@o24217.ingest.sentry.io/4506795846467584',
    apiUrl: OVERRIDE_API_HOST || 'https://realty.alpha.kr.karrotmarket.com',
    webUrl: 'https://realty.alpha.daangn.com',
    webviewUrl: 'https://realty.alpha.karrotwebview.com',
    publicWebUrl: 'https://alpha.daangn.com',
    brokerFaqUrl: 'https://daangn.notion.site/be13356fa2764ec78ed9f76cbd71442a',
    chatUrl: 'https://chat.alpha.daangn.com',
    karrotAuthPublic: 'wss://karrotauth-http.alpha.kr.karrotmarket.com',
    kakaoRestKey: 'e82ad5c12aa65c75b43c459f997f3ac6',
    googleMapKey: 'AIzaSyDNP1ThOuaBYy-AtA4HtR1c8ZWhgAF1ZcU',
    amplitudeKey: 'fbd0a01c4695bd025a1e58a34836dd4b',
    posthogKey: 'phc_adxmPtXo8J57QisYZcTpqdHthrxxeV3MDUypGpNm7Vq',
    cloudflareCode: 'dsdzwhnfwd5uowce',
  },
  production: {
    sentryDSN: 'https://b471ff7fcd1b7c098da9075adc7ce7a7@o24217.ingest.sentry.io/4506795875237888',
    apiUrl: 'https://realty.kr.karrotmarket.com',
    webUrl: 'https://realty.daangn.com',
    webviewUrl: 'https://realty.karrotwebview.com',
    publicWebUrl: 'https://www.daangn.com',
    brokerFaqUrl: 'https://daangn.notion.site/be13356fa2764ec78ed9f76cbd71442a',
    chatUrl: 'https://chat.daangn.com',
    karrotAuthPublic: 'wss://karrotauth-http.kr.karrotmarket.com',
    kakaoRestKey: 'e82ad5c12aa65c75b43c459f997f3ac6',
    googleMapKey: 'AIzaSyA5Z05cAjtzuvMSxPWrWlc1tM_4so0Guow',
    posthogKey: 'phc_30qAYjX6n2ybIrSYXAIsqRM4fShFEiCpDszRyg3lCKc',
    amplitudeKey: '4c750d27c4c0090fe508b32103e56985',
    cloudflareCode: 'dsdzwhnfwd5uowce',
  },
}[STAGE];
