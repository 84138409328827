import logger from '@/utils/Logger';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected/articles/new')({
  beforeLoad: () => {
    logger.track('page_redirect', { from: window.location.href, to: '/ceo/articles/new' });
    throw redirect({
      to: '/ceo/articles/new',
    });
  },
});
