import * as Dialog from '@radix-ui/react-dialog';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { twMerge } from 'tailwind-merge';

interface BottomSheetProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  footer?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  showCloseButton?: boolean;

  children: React.ReactNode;
}

const BottomSheet = ({
  title,
  description,
  footer,
  isOpen,
  onClose,
  showCloseButton = false,
  children,
}: BottomSheetProps) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={twMerge(
            'bg-bg-overlay fixed inset-0 z-50',
            'data-[state=open]:animate-fadeIn',
            'data-[state=closed]:animate-fadeOut'
          )}
        />
        <Dialog.Content
          className={twMerge(
            'fixed bottom-0 left-0 right-0 z-50',
            'bg-bg-layerDefault flex max-h-[85vh] flex-col',
            'rounded-t-[20px]',
            'data-[state=open]:animate-slideUp',
            'data-[state=closed]:animate-slideDown'
          )}
        >
          <div className="flex items-center justify-between px-4">
            {title && (
              <div className="flex flex-col gap-1.5 pb-4 pt-6">
                <Dialog.Title className="text-fg-neutral heading-medium">{title}</Dialog.Title>

                {description && (
                  <Dialog.Description className="text-fg-neutralSubtle body-medium-default">
                    {description}
                  </Dialog.Description>
                )}
              </div>
            )}

            {showCloseButton && (
              <Dialog.Close className="ml-auto">
                <IconXmarkLine width={24} height={24} />
              </Dialog.Close>
            )}
          </div>

          <div className="overflow-y-auto">{children}</div>

          {footer && (
            <div className="px-4 pb-[calc(8px+env(safe-area-inset-bottom))] pt-3">{footer}</div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default BottomSheet;
