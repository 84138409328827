import { Button } from '@daangn/carotene';
import { createFileRoute, useSearch } from '@tanstack/react-router';
import { useEffect } from 'react';
import { z } from 'zod';

const SearchPageParamSchema = z.object({
  url: z.string(),
  fallback_url: z.string(),
});

export const Route = createFileRoute('/bridge/')({
  component: Page,
  validateSearch: (search) => {
    return SearchPageParamSchema.parse(search);
  },
});

function Page() {
  const { url, fallback_url } = useSearch({ from: '/bridge/' });

  useEffect(() => {
    goToApp();

    setTimeout(() => {
      if (document.visibilityState === 'visible') {
        goToWeb();
      }
    }, 1000);
  }, []);

  const goToApp = () => {
    window.location.href = url;
  };

  const goToWeb = () => {
    window.location.href = fallback_url;
  };

  return (
    <div className="px-5 py-5 md:px-0 md:py-32">
      <div className="mt-32 flex flex-col items-center gap-6">
        <img
          src="https://assetstorage.krrt.io/1420322515413545053/4fe03d11-7542-42da-bf08-752b6d1b9ba5/width=421.8,height=421.8.webp"
          alt="당근(당근부동산)"
          className="h-[120px] w-[120px]"
        />

        <section className="flex flex-col items-center gap-2">
          <p className="heading-medium">당근(당근부동산) 앱으로 이동 중이에요.</p>
          <p className="body-small-default text-fg-neutralSubtle">
            (자동으로 연결이 안되면, 아래 버튼을 눌러주세요.)
          </p>
        </section>

        <section className="flex flex-col items-center gap-3 p-2">
          <Button variant="brand" size="large" onClick={goToApp}>
            앱에서 보기
          </Button>
          <Button variant="neutral" size="large" onClick={goToWeb}>
            웹에서 보기
          </Button>
        </section>
      </div>
    </div>
  );
}
