/**
 * @generated SignedSource<<62fbb9c0feeff9b3aaaa6bbfccf80c5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
export type SearchAddressesCorInput = {
  dong?: string | null;
  ho?: string | null;
  query: string;
  salesType?: SalesTypeEnum | null;
};
export type useSearchCorAddressResultRefetchQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  input: SearchAddressesCorInput;
};
export type useSearchCorAddressResultRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSearchCorAddressResult_query">;
};
export type useSearchCorAddressResultRefetchQuery = {
  response: useSearchCorAddressResultRefetchQuery$data;
  variables: useSearchCorAddressResultRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 9,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSearchCorAddressResultRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "kind": "FragmentSpread",
        "name": "useSearchCorAddressResult_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSearchCorAddressResultRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddressCorConnection",
        "kind": "LinkedField",
        "name": "searchCorAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressCorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CorAddress",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "corRealtyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRentalHouse",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "data"
        ],
        "handle": "connection",
        "key": "useSearchCorAddressResult_searchCorAddress",
        "kind": "LinkedHandle",
        "name": "searchCorAddress"
      }
    ]
  },
  "params": {
    "cacheID": "00963a7f9a9ad1e138a6c39a4c9ea613",
    "id": null,
    "metadata": {},
    "name": "useSearchCorAddressResultRefetchQuery",
    "operationKind": "query",
    "text": "query useSearchCorAddressResultRefetchQuery(\n  $count: Int = 9\n  $cursor: String\n  $input: SearchAddressesCorInput!\n) {\n  ...useSearchCorAddressResult_query_YCAiB\n}\n\nfragment useSearchCorAddressResult_query_YCAiB on Query {\n  searchCorAddress(first: $count, after: $cursor, data: $input) {\n    edges {\n      node {\n        address\n        corRealtyId\n        kind\n        isRentalHouse\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c29e443e8bbe639fc1a46a7f7b5d95dc";

export default node;
