import type { JusoAddressForm } from '@/components/Article/Field/Address/SearchJusoAddress';
import SearchJusoAddress from '@/components/Article/Field/Address/SearchJusoAddress';
import type { AddressFormState } from '@/routes/_protected/ceo/articles/-components/AddressField';
import { truthy } from '@/utils/misc';
import { Button, Divider, ProgressCircle } from '@daangn/carotene';
import { Spacer } from '@daangn/realty-react/ui';
import { parseAddressDetail } from '@daangn/realty-sdk';
import { useState } from 'react';

type Props = {
  onClickCorMode: () => void;
  onComplete: (address: AddressFormState) => void;
};

const JusoAddressForm = ({ onComplete, onClickCorMode }: Props) => {
  const [isFetchingUnit, setIsFetchingUnit] = useState(false);
  const [jusoAddress, setJusoAddress] = useState<JusoAddressForm | null>(null);
  const [errors, setErrors] = useState<{
    address?: string;
    detailAddress?: string;
  }>();

  const handleJusoAddressChange = (jusoAddress: JusoAddressForm | null) => {
    setJusoAddress(jusoAddress);
    setErrors(undefined);
  };

  const handleComplete = (form: JusoAddressForm) => {
    if (!form) {
      setErrors({
        address: '주소를 입력해주세요.',
      });
      return;
    }

    const { baseAddress, detailAddress } = form;

    if (
      !detailAddress ||
      (detailAddress.type === 'unit' && !detailAddress.unit) ||
      (detailAddress.type === 'manual' && !!detailAddress.isRequired && !detailAddress.address)
    ) {
      setErrors({
        detailAddress: '동, 호수를 입력해주세요.',
      });
      return;
    }

    const detail =
      detailAddress.type === 'unit'
        ? [detailAddress.unit?.dongNm, detailAddress.unit?.floorNm, detailAddress.unit?.hoNm]
            .filter(truthy)
            .join(' ')
        : detailAddress.address;
    const { eupmyeondongName, leeName, bun, ji } = parseAddressDetail({
      address: baseAddress.regionAddress,
    });
    const jiBunAddress = [bun, ji].filter(Boolean).join('-');
    const subAddress = [leeName || eupmyeondongName, jiBunAddress].filter(Boolean).join(' ');
    const fullAddress = [baseAddress.roadAddress, detail, `[${subAddress}]`]
      .filter(Boolean)
      .join(' ');

    onComplete({
      address: baseAddress.roadAddress,
      fullAddress,
      coordinate: baseAddress.coordinate,
    });
  };

  return (
    <>
      <div className="p-5">
        <SearchJusoAddress
          errors={errors}
          jusoAddress={jusoAddress}
          onJusoAddressChange={handleJusoAddressChange}
          onClickCorMode={onClickCorMode}
          onComplete={handleComplete}
          setIsFetchingUnit={setIsFetchingUnit}
        />
      </div>
      {isFetchingUnit && (
        <div className="flex flex-1 items-center justify-center">
          <div className="flex gap-1.5">
            <ProgressCircle size={24} />
            <span className="body-medium-strong">주소 정보를 불러오고 있어요</span>
          </div>
        </div>
      )}
      {!isFetchingUnit && !!jusoAddress?.baseAddress && (
        <div>
          <Divider />
          <div className="flex px-7 pb-5 pt-4">
            <Spacer />
            <Button
              variant="brand"
              size="large"
              onClick={() => {
                handleComplete(jusoAddress);
              }}
            >
              입력하기
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default JusoAddressForm;
