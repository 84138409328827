import { createFileRoute } from '@tanstack/react-router';
import { Suspense, useEffect, useRef, useState } from 'react';
import { graphql, useMutation } from 'react-relay';
import { newPageMutation } from '@/__generated__/newPageMutation.graphql';
import { getRelayNetworkErrorHandler, handleMutationResponse } from '@/relay/utils';
import { ActionHistoryKey, realtyLocalStorage } from '@/utils/localStorage';
import logger from '@/utils/Logger';
import { ErrorBoundary } from '@sentry/react';
import RealtyHomeBanner from '@/components/Banner/RealtyHomeBanner';
import BrokerFaqBanner from '@/routes/_protected/ceo/articles/-components/BrokerNoticeBanner';
import { ProgressCircle } from '@daangn/carotene';
import ArticleForm, {
  type ArticleFormRef,
} from '@/routes/_protected/ceo/articles/-components/ArticleForm';
import type { ArticleFormType } from '@/scheme/article';
import ReportBanner from '@/components/Banner/ReportBanner';
import { formToArticleInput } from '@/utils/article';
import ArticleCreateComplete from '@/routes/_protected/ceo/articles/-components/ArticleCreateComplete';

export const Route = createFileRoute('/_protected/ceo/articles/new')({
  component: Page,
});

function Page() {
  const form = useRef<ArticleFormRef>(null);
  const [createdArticle, setCreatedArticle] = useState<{
    id: string;
    originalId: string;
    corRealtyId: string | null;
  } | null>(null);
  const [showRealtyHomeBanner, setShowRealtyHomeBanner] = useState(
    () => !realtyLocalStorage.getActionHistory(ActionHistoryKey.SeenRealtyHomeBanner)
  );

  const [createArticle, isInFlight] = useMutation<newPageMutation>(graphql`
    mutation newPageMutation($input: CreateArticleInputV3!) {
      createArticleV3(input: $input) {
        __typename
        ... on CreateArticleOutput_Result {
          result {
            article {
              id
              originalId
              corRealtyId
            }
          }
        }
        ... on PopupError {
          popupError {
            message
            messageKey
          }
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  const handleClickSubmit = async (form: ArticleFormType) => {
    logger.track('create_article', {});

    const input = formToArticleInput(form, {
      isEdit: false,
    });

    createArticle({
      variables: {
        input,
      },
      onCompleted: (data) => {
        handleMutationResponse(data.createArticleV3, {
          onResult: ({ result }) => {
            realtyLocalStorage.clearTempArticle();
            realtyLocalStorage.setActionHistory(ActionHistoryKey.SeenRealtyHomeBanner);

            setCreatedArticle(result.article);

            logger.track('complete_submit', {
              input,
            });
          },
          onToastError(toastError) {
            window.alert(toastError.toastError.message);
          },
        });
      },
      onError: getRelayNetworkErrorHandler(),
    });
  };

  const handleClickWriteMore = () => {
    logger.track('click_write_more', {});

    form.current?.reset();
    setShowRealtyHomeBanner(false);
    setCreatedArticle(null);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    form.current?.watch((values) => {
      realtyLocalStorage.setTempArticle(values);
    });
  }, [form.current]);

  useEffect(() => {
    const tempArticle = realtyLocalStorage.getTempArticle();

    if (tempArticle) {
      const confirm = window.confirm('작성 중인 글이 있어요. 글을 이어서 쓸까요?');

      if (confirm) {
        logger.track('confirm_continue_writing', {});
        form.current?.reset(tempArticle);
      } else {
        realtyLocalStorage.clearTempArticle();
      }
    }
  }, []);

  if (createdArticle) {
    return (
      <ArticleCreateComplete createdArticle={createdArticle} onWriteMore={handleClickWriteMore} />
    );
  }

  return (
    <div className="relative pb-36 pt-12">
      {isInFlight && (
        <div className="bg-bg-overlay-default fixed bottom-0 left-0 right-0 top-0 z-50 flex h-full w-full items-center justify-center">
          <ProgressCircle />
        </div>
      )}
      <div className="flex flex-col gap-10">
        <ErrorBoundary>
          <Suspense>
            <BrokerFaqBanner />
          </Suspense>
        </ErrorBoundary>
        <ArticleForm
          ref={form}
          onSubmit={handleClickSubmit}
          isEdit={false}
          isLoading={isInFlight}
        />
        <div className="mt-24 flex flex-col gap-7">
          {showRealtyHomeBanner && <RealtyHomeBanner />}
          <ReportBanner formRef={form} section="new" />
        </div>
      </div>
    </div>
  );
}
