import { FormStep_sendSmsMutation$data } from '@/__generated__/FormStep_sendSmsMutation.graphql';
import { VerificationStep_resendSmsMutation$data } from '@/__generated__/VerificationStep_resendSmsMutation.graphql';
import { VerificationStep_verifySmsMutation$data } from '@/__generated__/VerificationStep_verifySmsMutation.graphql';
import Button from '@/components/base/Button';
import CheckLabel from '@/components/base/CheckLabel';
import PageError from '@/components/PageError';
import { config, STAGE } from '@/config';
import MainEnvironment from '@/relay/environment';
import { RelayErrorCode } from '@/relay/fetcher';
import { checkIsRelayErrorCode } from '@/relay/utils';
import { SmsVerifyCarrierEnum } from '@/types/schemaEnums';
import { Stack } from '@daangn/carotene';
import { getDynamicOnelink } from '@daangn/realty-sdk';
import { createMinikarrotScheme } from '@daangn/webview-link-router';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute, useLoaderData, useParams } from '@tanstack/react-router';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  graphql,
  loadQuery,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { match } from 'ts-pattern';
import { z } from 'zod';
import ArticleSection from './-components/ArticleSection';
import BenefitSection from './-components/BenefitSection';
import FormStep, { formSchema } from './-components/FormStep';
import VerificationStep from './-components/VerificationStep';
import { ArticleIdVerifyQuery } from '@/__generated__/ArticleIdVerifyQuery.graphql';
import { ArticleIdVerifyFailStep_article$key } from '@/__generated__/ArticleIdVerifyFailStep_article.graphql';
import Logger from '@/utils/Logger';
import posthog from 'posthog-js';
import CheckIros from '@/routes/_protected/ceo/articles/-components/CheckIros';

const EXPIRED_ERROR_MESSAGE = '삭제 또는 숨김 처리되었거나 이미 집주인 인증이 완료된 매물이에요.';

class VerificationExpiredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'VerificationExpiredError';
  }
}

export const VerifyArticleQuery = graphql`
  query ArticleIdVerifyQuery($articleId: String!) {
    articleByOriginalArticleId(originalArticleId: $articleId) {
      isWriterVerify
      isHide
      status
      originalId
      ...ArticleSection_article
      ...ArticleIdVerifyFailStep_article
    }
  }
`;

export enum Step {
  Verify = 'verify',
  SmsIdentification = 'smsIdentification',
  Success = 'success',
  Failure = 'failure',
}

export const Route = createFileRoute('/articles/$articleId/verify')({
  component: Page,
  beforeLoad: () => {
    posthog.startSessionRecording({ sampling: true });
  },
  loader: async ({ params: { articleId } }) => {
    return loadQuery<ArticleIdVerifyQuery>(MainEnvironment, VerifyArticleQuery, {
      articleId,
    });
  },
  errorComponent: (props) => {
    const error = props.error as Error;

    if (checkIsRelayErrorCode(error, RelayErrorCode.RecordNotFound)) {
      return <ExpiredErrorComponent message={EXPIRED_ERROR_MESSAGE} />;
    }

    if (error.name === 'VerificationExpiredError') {
      return <ExpiredErrorComponent message={error.message} />;
    }

    return <PageError />;
  },
  staticData: {
    headerStyle: {
      borderless: true,
      hideMenu: true,
    },
  },
});

const ExpiredErrorComponent = ({ message }: { message: string }) => {
  return (
    <Stack gap={6} className="h-full items-center justify-center px-4">
      <img
        width={76}
        height={68}
        className="mb-2"
        src="https://assetstorage.krrt.io/1420322515413545053/92aba9e1-091e-497c-b475-c124ca2bf47f/width=266,height=238.webp"
      />
      <Stack gap={1.5} className="items-center text-center">
        <h1 className="heading-large">집주인 인증이 불가능해요</h1>
        <p className="body-medium-default text-fg-neutralMuted">{message}</p>
      </Stack>
    </Stack>
  );
};

function Page() {
  const { articleId } = useParams({ from: Route.id });
  const appQueryRef = useLoaderData({ from: Route.id });
  const [queryRef] = useQueryLoader<ArticleIdVerifyQuery>(VerifyArticleQuery, appQueryRef);

  const [step, setStep] = useState<Step>(Step.Verify);

  if (!queryRef) throw new VerificationExpiredError('게시글 정보를 불러오지 못했어요.');

  const { articleByOriginalArticleId: article } = usePreloadedQuery(VerifyArticleQuery, queryRef);

  if (article.isHide || article.status === 'TRADED' || article.isWriterVerify) {
    throw new VerificationExpiredError(EXPIRED_ERROR_MESSAGE);
  }

  const handleAgree = () => {
    setStep(Step.SmsIdentification);
  };

  const handleSuccess = () => {
    setStep(Step.Success);
  };

  const handleFail = () => {
    setStep(Step.Failure);
  };

  const handleReset = () => {
    setStep(Step.Verify);
  };

  return (
    <div className="bg-bg-default pt-1.5">
      <CheckIros type="verify" />
      {step === Step.Verify && (
        <VerifyStep onAgree={handleAgree} queryRef={queryRef} articleId={articleId} />
      )}
      {step === Step.SmsIdentification && (
        <SmsIdentificationStep
          onSuccess={handleSuccess}
          onFail={handleFail}
          articleId={articleId}
        />
      )}
      {step === Step.Success && <VerifySuccessStep queryRef={queryRef} />}
      {step === Step.Failure && <VerifyFailStep queryRef={queryRef} onReset={handleReset} />}
    </div>
  );
}

const VerifyStep = ({
  onAgree,
  queryRef,
  articleId,
}: {
  onAgree: () => void;
  queryRef: PreloadedQuery<ArticleIdVerifyQuery>;
  articleId: string;
}) => {
  const [checked, setChecked] = useState(true);
  const [isAutoCheckingLoading, setIsAutoCheckingLoading] = useState(false);

  const handleAgree = () => {
    Logger.track('click_article_verify_agree_confirm', { article_id: articleId, checked });

    if (!checked) {
      setChecked(true);
      setIsAutoCheckingLoading(true);

      setTimeout(() => {
        setIsAutoCheckingLoading(false);
        onAgree();
      }, 300);
      return;
    }

    onAgree();
  };

  return (
    <Section.Container stepId={Step.Verify} articleId={articleId}>
      <Section.Header title="부동산 매물에 대한 집주인 인증을 진행해 주세요" />

      <Section.Content gap={6}>
        <ArticleSection queryRef={queryRef} />
        <BenefitSection />
      </Section.Content>

      <Section.BottomFixed gap={5}>
        <CheckLabel
          label="집주인 인증에 동의합니다"
          checked={checked}
          onCheckedChange={setChecked}
        />
        <Button
          variant="brand"
          size="xlarge"
          className="w-full"
          onClick={handleAgree}
          loading={isAutoCheckingLoading}
        >
          동의하고 인증하기
        </Button>
      </Section.BottomFixed>
    </Section.Container>
  );
};

export type VerificationData = {
  identificationVerifyRequestId: string;
  otpValidDuration: string;
};

type SmsIdentificationStepProps = {
  articleId: string;
  onSuccess: () => void;
  onFail: () => void;
};

const SmsIdentificationStep = ({ articleId, onSuccess, onFail }: SmsIdentificationStepProps) => {
  const [sendResult, setSendResult] = useState<VerificationData>();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      phone: '',
      telecom: '' as SmsVerifyCarrierEnum,
      idCard: {
        first: '',
        last: '',
      },
      termsAgreed: false,
    },
  });

  const handleSend = (
    response:
      | FormStep_sendSmsMutation$data['sendIdentificationOtpSms']
      | VerificationStep_resendSmsMutation$data['resendIdentificationOtpSms']
  ) => {
    const { result, toastError, popupError } = response;

    if (result) {
      setSendResult(result);
      toast('인증번호를 발송했어요.');
    } else if (toastError) {
      toast(toastError.message);
    } else if (popupError) {
      window.alert(popupError.message);
      setSendResult(undefined);
    }
  };

  const logCheckArticleVerify = (type: 'success' | 'unmatch' | 'fail', reason?: string) => {
    Logger.track('check_article_verify', {
      article_id: articleId,
      type,
      reason,
    });
  };

  const handleVerify = (
    response: VerificationStep_verifySmsMutation$data['verifyArticleByKoreaIdentification']
  ) => {
    match(response)
      .with({ __typename: 'VerifyArticleByKoreaIdentificationOutput_Result' }, () => {
        logCheckArticleVerify('success');
        onSuccess();
      })
      .with({ __typename: 'VerifyArticleByKoreaIdentificationUnmatch' }, () => {
        logCheckArticleVerify('unmatch');
        onFail();
      })
      .with({ __typename: 'ToastError' }, ({ toastError }) => {
        logCheckArticleVerify('fail', toastError.message);
        toast(toastError.message);
      }).exhaustive;
  };

  return (
    <div>
      {!sendResult ? (
        <FormStep form={form} onSend={handleSend} articleId={articleId} />
      ) : (
        <VerificationStep
          articleId={articleId}
          verificationData={sendResult}
          onVerify={handleVerify}
          onResend={handleSend}
        />
      )}
    </div>
  );
};

const VerifySuccessStep = ({ queryRef }: { queryRef: PreloadedQuery<ArticleIdVerifyQuery> }) => {
  const { articleByOriginalArticleId: article } = usePreloadedQuery(VerifyArticleQuery, queryRef);

  const handleClick = async () => {
    Logger.track('click_article_verify_success_detail', { article_id: article.originalId });

    const scheme = createMinikarrotScheme({
      url: `${config.webviewUrl}/articles/${article.originalId}?referrer=realty-web`,
      stage: STAGE,
    });

    const { url } = await getDynamicOnelink(scheme);
    window.open(url, '_blank');
  };

  return (
    <Section.Container stepId={Step.Success} articleId={article.originalId}>
      <Section.Header
        title="집주인 인증이 완료되었어요"
        description="안전한 부동산 거래에 함께해주셔서 감사해요!"
      />

      <Section.Content gap={6}>
        <ArticleSection queryRef={queryRef} />
        <BenefitSection />
      </Section.Content>

      <Section.BottomFixed>
        <Button variant="neutral" size="xlarge" className="w-full" onClick={handleClick}>
          당근앱에서 보기
        </Button>
      </Section.BottomFixed>
    </Section.Container>
  );
};

const VerifyFailStep = ({
  queryRef,
  onReset,
}: {
  queryRef: PreloadedQuery<ArticleIdVerifyQuery>;
  onReset: () => void;
}) => {
  const { articleByOriginalArticleId: articleRef } = usePreloadedQuery(
    VerifyArticleQuery,
    queryRef
  );

  const article = useFragment<ArticleIdVerifyFailStep_article$key>(
    graphql`
      fragment ArticleIdVerifyFailStep_article on Article {
        address
        originalId
      }
    `,
    articleRef
  );

  const handleRetry = () => {
    Logger.track('click_article_verify_fail_retry', { article_id: article.originalId });
    onReset();
  };

  const handleFaq = () => {
    Logger.track('click_article_verify_fail_faq', { article_id: article.originalId });
    window.open('https://www.daangn.com/wv/faqs/7129', '_blank');
  };

  return (
    <Section.Container stepId={Step.Failure} articleId={article.originalId}>
      <Section.Header
        title="집주인 인증에 실패했어요"
        description="등기부등본 주소 매칭에 실패했어요. 작성된 매물의 주소를 다시 확인해 주세요."
      />

      <Section.Content gap={6}>
        <Stack gap={1.5} className="bg-bg-neutral text-fg-neutral rounded-2.5 p-4">
          <p className="heading-small">게시글에 등록된 주소</p>
          <p className="body-small-default">{article.address}</p>
        </Stack>
      </Section.Content>

      <Section.BottomFixed gap={3}>
        <Button variant="brand" size="xlarge" className="w-full" onClick={handleFaq}>
          고객센터에 문의하기
        </Button>
        <button className="body-small-strong text-fg-neutral" onClick={handleRetry}>
          다시 시도하기
        </button>
      </Section.BottomFixed>
    </Section.Container>
  );
};

export const Section = {
  Container: ({
    children,
    articleId,
    stepId,
  }: {
    children: React.ReactNode;
    articleId: string;
    stepId: string;
  }) => {
    useEffect(() => {
      Logger.track('show_article_verify_step', {
        step_id: stepId,
        article_id: articleId,
      });
    }, []);

    return <>{children}</>;
  },
  Header: ({ title, description }: { title: string; description?: string }) => (
    <Stack gap={1.5} className="text-fg-neutral px-4">
      <h3 className="heading-large">{title}</h3>
      {description && <p className="body-medium-default">{description}</p>}
    </Stack>
  ),
  Content: ({
    children,
    className,
    ...props
  }: { children?: React.ReactNode; className?: string } & React.ComponentProps<typeof Stack>) => (
    <Stack className={classNames('px-4 py-5', className)} {...props}>
      {children}
    </Stack>
  ),
  BottomFixed: ({
    children,
    className,
    ...props
  }: { children: React.ReactNode; className?: string } & React.ComponentProps<typeof Stack>) => (
    <Stack
      className={classNames(
        'fixed bottom-0 left-0 right-0 px-4 pb-[calc(8px+env(safe-area-inset-bottom))] pt-3',
        className
      )}
      {...props}
    >
      {children}
    </Stack>
  ),
};
