import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { useController } from 'react-hook-form';
import logger from '@/utils/Logger';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import {
  ArticleOptionNameEnum,
  ArticleOptionValueEnum,
  type BuildingUsageEnum,
} from '@/types/schemaEnums';
import { LinkButton, TextField, FormControl } from '@daangn/carotene';
import { getBuildingLedger } from '@/graphql/getBuildingLedger';
import { unionBy } from 'lodash-es';
import SearchAddressDialog from '@/components/Dialog/SearchAddressDialog';
import type { ArticleFormInputType } from '@/scheme/article';
import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon';
import { useOverlay } from '@toss/use-overlay';

type Props = {
  disabled?: boolean;
};

export type AddressFormState = Pick<
  ArticleFormInputType,
  'address' | 'fullAddress' | 'coordinate' | 'corRealtyId'
>;

const AddressField = ({ disabled }: Props) => {
  const { control, watch, setValue, getValues } = useArticleForm();
  const address = useController({ control, name: 'address' });
  const fullAddress = useController({ control, name: 'fullAddress' });
  const coordinate = useController({ control, name: 'coordinate' });
  const corRealtyId = useController({ control, name: 'corRealtyId' });
  const overlay = useOverlay();

  const handleSelectAddress = (addressForm: AddressFormState) => {
    logger.track('select_address', {
      address_form: addressForm,
    });

    address.field.onChange(addressForm.address);
    fullAddress.field.onChange(addressForm.fullAddress);
    coordinate.field.onChange(addressForm.coordinate);
    corRealtyId.field.onChange(addressForm.corRealtyId);

    if (addressForm.fullAddress) {
      getBuildingLedger(addressForm.fullAddress).then((ledger) => {
        if (ledger) {
          ledger.buildingUsage &&
            setValue(
              'buildingUsage',
              (ledger.buildingUsage as BuildingUsageEnum) ?? watch('buildingUsage')
            );
          ledger.area && setValue('area', ledger.area.toString() ?? watch('area'));
          ledger.buildingApprovalDate &&
            setValue('buildingApprovalDate', ledger.buildingApprovalDate);
          ledger.elevator &&
            ledger.elevator > 0 &&
            setValue(
              'options',
              unionBy(
                watch('options') ?? [],
                [{ name: ArticleOptionNameEnum.Elevator, value: ArticleOptionValueEnum.Yes }],
                'name'
              )
            );
          ledger.floor && setValue('floor', ledger.floor.toString() ?? watch('floor'));
          ledger.topFloor && setValue('topFloor', ledger.topFloor.toString() ?? watch('topFloor'));
        }
      });
    }
  };

  const fullAddressValue = watch('fullAddress');

  const handleOpenAddressDialog = () => {
    overlay.open(({ close }) => (
      <SearchAddressDialog
        salesType={getValues('salesType')}
        etcSalesType={getValues('etcSalesType') ?? null}
        onComplete={handleSelectAddress}
        onClose={close}
      />
    ));
  };

  return (
    <div className="flex flex-col gap-1.5">
      {fullAddressValue ? (
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <div className="body-small-strong">매물 주소</div>
            <div className="spacer" />
            <LinkButton variant="neutral" size="small" onClick={handleOpenAddressDialog}>
              수정하기
            </LinkButton>
          </div>
          <div className="body-small-default bg-bg-neutral rounded-1.5 text-fg-neutral flex flex-col gap-1 p-4">
            <div>{fullAddressValue}</div>
          </div>
        </div>
      ) : (
        <FieldLogger name="address" eventParams={{}}>
          <FormControl
            label="매물 주소"
            invalid={!!address.fieldState.error}
            errorMessage={address.fieldState.error?.message}
          >
            <TextField
              {...address.field}
              value={address.field.value}
              onChange={() => {}}
              readOnly
              disabled={disabled}
              placeholder="주소를 입력하세요"
              width="100%"
              onClick={handleOpenAddressDialog}
              suffix={<IconMagnifyingglassLine size={22} />}
            />
          </FormControl>
        </FieldLogger>
      )}
    </div>
  );
};

export default AddressField;
