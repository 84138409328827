import { SearchResultRenderer } from '@/components/SearchResult';
import { CorFormState } from '@/hooks/useCheckWritableAddress';
import SearchCorAddressResult from '@/components/Article/Field/Address/SearchCorAddressResult';
import { useSearch } from '@daangn/realty-react/hooks';
import { IconMagnifyingglassLine, IconXmarkLine } from '@daangn/react-monochrome-icon';
import { TextField } from '@daangn/carotene';
import type { AddressFormState } from '@/routes/_protected/ceo/articles/-components/AddressField';
import { AddressGuide } from '@/components/Article/Field/Address/AddressGuide';
import type { SalesTypeEnum } from '@/types/schemaEnums';
import CheckIros from '@/routes/_protected/ceo/articles/-components/CheckIros';
import { Suspense } from 'react';

type Props = {
  selectedSalesType: SalesTypeEnum;
  onComplete: (address: AddressFormState) => void;
  onMaintenance: () => void;
};

const CorAddressForm = ({ selectedSalesType, onComplete, onMaintenance }: Props) => {
  const { input, query, setInput, reset } = useSearch('');

  const handleSelectAddress = (address: CorFormState) => {
    onComplete(address);
  };

  return (
    <div className="group p-4">
      <TextField
        placeholder="예) 한누리대로 411, 상암동 1595"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        autoFocus={true}
        suffix={
          input ? (
            <IconXmarkLine size={22} onMouseDown={() => reset()} />
          ) : (
            <IconMagnifyingglassLine size={22} />
          )
        }
      />
      {!input && (
        <div className="mt-5">
          <AddressGuide />
        </div>
      )}
      {!!input && (
        <div className="mt-2">
          <SearchResultRenderer input={input} query={query} minInputLength={2}>
            <SearchCorAddressResult
              query={query}
              onSelectItem={handleSelectAddress}
              selectedSalesType={selectedSalesType}
            />
          </SearchResultRenderer>
        </div>
      )}
      <Suspense fallback={null}>
        <CheckIros onMaintenance={onMaintenance} type="write" />
      </Suspense>
    </div>
  );
};

export default CorAddressForm;
