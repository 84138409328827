export const AddressGuide = () => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="heading-small">이렇게 검색해 보세요</h3>
      {[
        { title: '도로명 + 건물번호', description: '예: 테헤란로 4길 14' },
        { title: '동/읍/면/리 + 번지', description: '예: 역삼동 825-22' },
        { title: '건물명, 아파트명', description: '예: 교보타워' },
      ].map(({ title, description }, i) => (
        <div className="body-small-default flex flex-col gap-0.5" key={i}>
          <div>{title}</div>
          <div className="text-fg-neutralSubtle">{description}</div>
        </div>
      ))}
    </div>
  );
};
