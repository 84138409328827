import { IconXmarkFill } from '@daangn/react-monochrome-icon';
import { Spacer } from '@daangn/realty-react/ui';
import classNames from 'classnames';
import toast, { Toaster, resolveValue, type ToasterProps } from 'react-hot-toast';

type Props = Omit<ToasterProps, 'children'>;

export const actionToast = ({
  message,
  action,
}: {
  message: string;
  action: { label: string; onClick: () => void };
}) => {
  toast(
    (t) => (
      <ToastWithAction message={message} action={action} onClose={() => toast.dismiss(t.id)} />
    ),
    { duration: Infinity }
  );
};

const ToastWithAction = ({
  message,
  action,
  onClose,
}: {
  message: string;
  action: {
    label: string;
    onClick: () => void;
  };
  onClose: () => void;
}) => {
  const handleAction = () => {
    action.onClick();
    onClose();
  };

  return (
    <div className="flex items-center">
      <div>{message}</div>
      <Spacer />
      <button onClick={handleAction} className="px-2 py-1 font-bold">
        {action.label}
      </button>
      <div className="bg-fg-neutralMuted ml-1 h-4 w-[1px] shrink-0" />
      <button
        onClick={onClose}
        className="flex h-8 w-8 items-center justify-center pl-1.5 font-bold"
      >
        <IconXmarkFill className="text-fg-neutralSubtle" size={20} />
      </button>
    </div>
  );
};

const DefaultToaster = ({ ...props }: Props) => {
  return (
    <Toaster
      position="bottom-center"
      {...props}
      containerStyle={{
        ...props.containerStyle,
        bottom: 116,
      }}
      toastOptions={{
        ...props.toastOptions,
        duration: 3000,
      }}
    >
      {(t) => (
        <div
          className={classNames(
            'bg-bg-neutralInverted text-fg-neutralInverted min-w-[359px] rounded-[10px] py-3 pl-4 pr-2 opacity-95 [&>div]:m-0',

            {
              'animate-enter': t.visible,
              'animate-leave': !t.visible,
            }
          )}
        >
          {resolveValue(t.message, t)}
        </div>
      )}
    </Toaster>
  );
};

export default DefaultToaster;
