import logger from '@/utils/Logger';
import { realtyLocalStorage } from '@/utils/localStorage';
import { Callout, CalloutDescription, CalloutTitle } from '@daangn/carotene';
import { InViewChecker } from '@daangn/realty-react/components';
import { IconILowercaseSerifCircleFill, IconMegaphoneFill } from '@daangn/react-monochrome-icon';
import { useMemo, useRef } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  BrokerNoticeBannerQuery,
  BrokerNoticeBannerQuery$data,
} from '@/__generated__/BrokerNoticeBannerQuery.graphql';
import { truthy } from '@/utils/misc';
import 'swiper/css';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import SwiperPagination from '@/components/base/Swiper/SwiperPagination';
import SwiperNavigation from '@/components/base/Swiper/SwiperNavigation';

const BrokerNoticeBanner = () => {
  const { activeBrokerNotices } = useLazyLoadQuery<BrokerNoticeBannerQuery>(
    graphql`
      query BrokerNoticeBannerQuery {
        activeBrokerNotices {
          type
          originalId
          title
          content
          targetUri
          maxShowCount
          maxClickCount
          linkText
          pcTargetUri
          targetUri
        }
      }
    `,
    {}
  );

  const swiperRef = useRef<SwiperClass>();
  const banners = useMemo(() => {
    return activeBrokerNotices.filter(truthy).filter((banner) => {
      const hasExceededShowCount = banner.maxShowCount
        ? realtyLocalStorage.getActionCount(`broker_notice_impression_${banner.originalId}`) >=
          banner.maxShowCount
        : false;

      const hasClicked = banner.maxClickCount
        ? realtyLocalStorage.getActionCount(`broker_notice_click_${banner.originalId}`) >=
          banner.maxClickCount
        : false;

      return !hasExceededShowCount && !hasClicked;
    });
  }, [activeBrokerNotices]);

  if (!banners.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Swiper spaceBetween={16} onInit={(swiper) => (swiperRef.current = swiper)}>
          {banners.map((banner) => (
            <SwiperSlide key={banner.originalId} className="flex !h-auto gap-2">
              <SwiperNavigation direction="prev" />
              <Banner {...banner} />
              <SwiperNavigation direction="next" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {banners.length > 1 && <SwiperPagination swiperRef={swiperRef} />}
    </div>
  );
};

export default BrokerNoticeBanner;

export type BrokerNoticeBanner = BrokerNoticeBannerQuery$data['activeBrokerNotices'][number];

const Banner = (banner: NonNullable<BrokerNoticeBanner>) => {
  const {
    type,
    originalId,
    content,
    title,
    pcTargetUri,
    targetUri,
    linkText,
    maxShowCount,
    maxClickCount,
  } = banner;

  const handleImpression = () => {
    maxShowCount && realtyLocalStorage.addActionCount(`broker_notice_impression_${originalId}`);
    logger.track('show_broker_notice', banner);
  };

  const handleClick = () => {
    window.open(pcTargetUri ?? targetUri, '_blank');
    maxClickCount && realtyLocalStorage.addActionCount(`broker_notice_click_${originalId}`);
    logger.track('click_broker_notice', banner);
  };

  return (
    <InViewChecker
      options={{ threshold: 0.5, once: true }}
      onInView={handleImpression}
      onClick={handleClick}
      className="flex h-full flex-1 items-center"
    >
      <BannerCallout type={type}>
        <CalloutTitle>{title}</CalloutTitle>
        <CalloutDescription>
          {content} {linkText && <button className="underline">{linkText}</button>}
        </CalloutDescription>
      </BannerCallout>
    </InViewChecker>
  );
};

const BannerCallout = ({
  type,
  children,
}: {
  type: BrokerNoticeBanner['type'];
  children: React.ReactNode;
}) => {
  /* TODO: ActionableCallout 없어서 임의로 구현 */
  if (type === 'action') {
    return (
      <Callout variant="neutral" className="active:bg-bg-neutralPressed w-full cursor-pointer">
        {children}
      </Callout>
    );
  }

  /* TODO: info variant 지원되면 수정 */
  if (type === 'info') {
    return (
      <Callout variant="informative" className="w-full" icon={<IconMegaphoneFill />}>
        {children}
      </Callout>
    );
  }

  return (
    <Callout variant="neutral" className="w-full" icon={<IconILowercaseSerifCircleFill />}>
      {children}
    </Callout>
  );
};
