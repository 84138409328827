import { CeoHistoryArticles_CeoProfileArticles$key } from '@/__generated__/CeoHistoryArticles_CeoProfileArticles.graphql';
import { CeoHistoryArticlesQuery } from '@/__generated__/CeoHistoryArticlesQuery.graphql';
import { CeoProfileArticlesRefetchQuery } from '@/__generated__/CeoProfileArticlesRefetchQuery.graphql';
import ArticleEmpty from '@/components/Article/ArticleEmpty';
import ArticleItem from '@/components/Article/ArticleItem';
import InfiniteLoading from '@/components/InfinteLoading';
import { BizProfileArticleStatusInputEnum } from '@/types/schemaEnums';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';

type CeoHistoryArticlesProps = {
  selectedStatus: `${BizProfileArticleStatusInputEnum}`;
  searchKeyword?: string;
};
const CeoHistoryArticles = ({ selectedStatus, searchKeyword }: CeoHistoryArticlesProps) => {
  const queryRef = useLazyLoadQuery<CeoHistoryArticlesQuery>(
    graphql`
      query CeoHistoryArticlesQuery(
        $first: Int = 20
        $after: String
        $input: BizProfileArticlesInput
      ) {
        ...CeoHistoryArticles_CeoProfileArticles
          @arguments(first: $first, after: $after, input: $input)
      }
    `,
    { input: { status: selectedStatus, addressKeyword: searchKeyword } },
    { fetchPolicy: 'store-and-network' }
  );

  const { data, hasNext, loadNext } = usePaginationFragment<
    CeoProfileArticlesRefetchQuery,
    CeoHistoryArticles_CeoProfileArticles$key
  >(
    graphql`
      fragment CeoHistoryArticles_CeoProfileArticles on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 20 }
        after: { type: String }
        input: { type: "BizProfileArticlesInput" }
      )
      @refetchable(queryName: "CeoProfileArticlesRefetchQuery") {
        bizProfileArticles(first: $first, after: $after, input: $input)
          @connection(key: "CeoHistoryArticles__bizProfileArticles") {
          edges {
            node {
              id
              originalId
              status
              isHide
              invisibleStatus
              ...ArticleItem_article
            }
            cursor
          }
        }
      }
    `,
    queryRef
  );

  const { edges } = data.bizProfileArticles;
  const articles = edges.map((v) => v.node);

  if (articles.length < 1) {
    return <ArticleEmpty searchKeyword={searchKeyword} selectedStatus={selectedStatus} />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="grid w-full grid-cols-2 gap-8">
        {articles.map((article, index) => (
          <ArticleItem
            key={article.id}
            articleRef={article}
            index={index}
            showRealtyId
            searchKeyword={searchKeyword}
          />
        ))}
      </div>

      <InfiniteLoading hasNext={hasNext} onLoadMore={() => loadNext(20)} />
    </div>
  );
};

export default CeoHistoryArticles;
