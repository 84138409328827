import { readArticleSalesTypeText, readArticleTradeTypeAndPriceText } from '@/utils/article';
import { Stack } from '@daangn/carotene';
import { Image } from '@daangn/realty-react/ui';
import { graphql, PreloadedQuery, useFragment, usePreloadedQuery } from 'react-relay';
import { VerifyArticleQuery } from '../$articleId.verify';
import { ArticleSection_article$key } from '@/__generated__/ArticleSection_article.graphql';
import { ArticleIdVerifyQuery } from '@/__generated__/ArticleIdVerifyQuery.graphql';

const ArticleSection = ({ queryRef }: { queryRef: PreloadedQuery<ArticleIdVerifyQuery> }) => {
  const { articleByOriginalArticleId: articleRef } = usePreloadedQuery(
    VerifyArticleQuery,
    queryRef
  );

  const article = useFragment<ArticleSection_article$key>(
    graphql`
      fragment ArticleSection_article on Article {
        id
        addressWithDong
        imageThumbnailUrl
        ...articleSalesTypeText_article
        ...articleTradeTypeAndPriceText_article
      }
    `,
    articleRef
  );

  return (
    <div className="bg-bg-neutral rounded-2.5 flex w-full items-center gap-3 p-4">
      <Image
        src={article.imageThumbnailUrl}
        className="rounded-1.5 h-12 w-12 shrink-0 object-cover"
      />

      <Stack gap={0.5}>
        <p className="heading-small text-fg-neutral">
          {readArticleSalesTypeText(article)}, {readArticleTradeTypeAndPriceText(article)}
        </p>
        <p className="body-small-default text-fg-neutral">{article.addressWithDong}</p>
      </Stack>
    </div>
  );
};

export default ArticleSection;
