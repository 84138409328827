import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { SEMI_BASEMENT_FLOOR } from '@daangn/realty-sdk';
import { useController } from 'react-hook-form';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { Checkbox, FormControl, TextField } from '@daangn/carotene';
import { removeDecimal } from '@/utils/number';
import { SalesTypeEnum } from '@/types/schemaEnums';

const AreaInfoField = () => {
  const { control, watch } = useArticleForm();

  const isStore = watch('salesType') === SalesTypeEnum.Store;
  const roomCnt = useController({ control, name: 'roomCnt' });
  const bathroomCnt = useController({ control, name: 'bathroomCnt' });
  const floor = useController({ control, name: 'floor' });
  const topFloor = useController({ control, name: 'topFloor' });

  const handleSemiBasementChange = (isChecked: boolean) => {
    if (isChecked) {
      floor.field.onChange(SEMI_BASEMENT_FLOOR);
    } else {
      floor.field.onChange('');
    }
  };

  return (
    <>
      {!isStore && (
        <div className="flex gap-6 *:flex-grow">
          <FieldLogger name="room" eventParams={{}}>
            <FormControl
              label="방 개수"
              invalid={!!roomCnt.fieldState.error}
              errorMessage={roomCnt.fieldState.error?.message}
            >
              <TextField
                {...roomCnt.field}
                value={String(roomCnt.field.value ?? '')}
                onChange={(e) => roomCnt.field.onChange(removeDecimal(Number(e.target.value)))}
                placeholder="0"
                type="number"
              />
            </FormControl>
          </FieldLogger>
          <FieldLogger name="bathroom" eventParams={{}}>
            <FormControl
              label="욕실 개수"
              invalid={!!bathroomCnt.fieldState.error}
              errorMessage={bathroomCnt.fieldState.error?.message}
            >
              <TextField
                {...bathroomCnt.field}
                value={String(bathroomCnt.field.value ?? '')}
                onChange={(e) => bathroomCnt.field.onChange(removeDecimal(Number(e.target.value)))}
                placeholder="0"
                type="number"
              />
            </FormControl>
          </FieldLogger>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="flex gap-6 *:flex-grow">
          <FieldLogger name="top_floor" eventParams={{}}>
            <FormControl
              label="건물 전체 층"
              invalid={!!topFloor.fieldState.error}
              errorMessage={topFloor.fieldState.error?.message}
            >
              <TextField
                {...topFloor.field}
                value={String(topFloor.field.value ?? '')}
                placeholder="0"
                type="number"
              />
            </FormControl>
          </FieldLogger>
          <FieldLogger name="floor" eventParams={{}}>
            <FormControl
              label="해당 층"
              invalid={!!floor.fieldState.error}
              errorMessage={floor.fieldState.error?.message}
            >
              <TextField
                {...floor.field}
                value={
                  floor.field.value === SEMI_BASEMENT_FLOOR ? '' : String(floor.field.value ?? '')
                }
                disabled={floor.field.value === SEMI_BASEMENT_FLOOR}
                placeholder="0"
                type="number"
              />
            </FormControl>
          </FieldLogger>
        </div>
        <FieldLogger name="semi_basement" eventParams={{}}>
          <Checkbox
            checked={floor.field.value === SEMI_BASEMENT_FLOOR}
            onCheckedChange={handleSemiBasementChange}
            label="반지하"
          />
        </FieldLogger>
      </div>
    </>
  );
};

export default AreaInfoField;
