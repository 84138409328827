/**
 * @generated SignedSource<<1e7b7a3bfa2f380186bc213bb0d085df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BrokerNoticeBannerQuery$variables = Record<PropertyKey, never>;
export type BrokerNoticeBannerQuery$data = {
  readonly activeBrokerNotices: ReadonlyArray<{
    readonly content: string;
    readonly linkText: string | null;
    readonly maxClickCount: number | null;
    readonly maxShowCount: number | null;
    readonly originalId: string;
    readonly pcTargetUri: string | null;
    readonly targetUri: string;
    readonly title: string | null;
    readonly type: string | null;
  }>;
};
export type BrokerNoticeBannerQuery = {
  response: BrokerNoticeBannerQuery$data;
  variables: BrokerNoticeBannerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetUri",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxShowCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxClickCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkText",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pcTargetUri",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrokerNoticeBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrokerNotice",
        "kind": "LinkedField",
        "name": "activeBrokerNotices",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BrokerNoticeBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrokerNotice",
        "kind": "LinkedField",
        "name": "activeBrokerNotices",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d7406e5f9cdae50195cd0283697c2d9",
    "id": null,
    "metadata": {},
    "name": "BrokerNoticeBannerQuery",
    "operationKind": "query",
    "text": "query BrokerNoticeBannerQuery {\n  activeBrokerNotices {\n    type\n    originalId\n    title\n    content\n    targetUri\n    maxShowCount\n    maxClickCount\n    linkText\n    pcTargetUri\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c5cf0b12fe291ec7936a50784fe1eeb";

export default node;
