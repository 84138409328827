import Field from '@/routes/_protected/ceo/articles/-components/Field';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { ArticleOptionNameEnum, ArticleOptionValueEnum } from '@/types/schemaEnums';
import {
  getArticleOptionNameText,
  getArticleOptionValueText,
  getRequiredOptionNames,
} from '@daangn/realty-sdk';
import { useController } from 'react-hook-form';
import { unionBy } from 'lodash-es';
import { ArticleOptionValues, RequiredArticleOptionNames } from '@/constants/article';
import { ButtonRadioGroup, ButtonRadioGroupItem } from '@/components/base/ButtonRadio';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { FormControl, TextField } from '@daangn/carotene';
import { removeDecimal } from '@/utils/number';

export type RequiredArticleOptionEnum = (typeof RequiredArticleOptionNames)[number];

const getOptionValues = (name: ArticleOptionNameEnum) => {
  if (name === ArticleOptionNameEnum.Parking) {
    return [ArticleOptionValueEnum.Yes, ArticleOptionValueEnum.No];
  }

  return ArticleOptionValues;
};

const RequiredOptionField = () => {
  const { control, watch, resetField } = useArticleForm();

  const requiredOptions = getRequiredOptionNames({ salesType: watch('salesType') }).map(
    (name) => name as ArticleOptionNameEnum
  );

  const { field, fieldState } = useController({
    control,
    name: 'requiredOptions',
  });

  const totalParkingSpots = useController({
    control,
    name: 'availableTotalParkingSpots',
  });

  const parkingSpots = useController({
    control,
    name: 'availableParkingSpotsV2',
  });

  const handleChangeOptionValue = (name: ArticleOptionNameEnum, value: ArticleOptionValueEnum) => {
    field.onChange(unionBy([{ name, value }], watch('requiredOptions') ?? [], 'name'));
  };

  return (
    <Field label="필수 항목" {...fieldState}>
      <div>
        {requiredOptions.map((name) => {
          const option = field.value?.find((option) => option.name === name);
          return (
            <div key={name} className="flex flex-col gap-3 py-5">
              <div className="flex gap-12">
                <span className="body-medium-default">
                  {getArticleOptionNameText(name, { isBroker: true })}
                </span>
                <div className="spacer" />
                <div className="flex flex-col items-end gap-2">
                  <ButtonRadioGroup
                    name={field.name}
                    value={option?.value ?? ''}
                    onValueChange={(v) => {
                      const value = v as ArticleOptionValueEnum;
                      handleChangeOptionValue(name, value);

                      if (
                        name === ArticleOptionNameEnum.Parking &&
                        value === ArticleOptionValueEnum.No
                      ) {
                        resetField('availableParkingSpotsV2');
                      }
                    }}
                  >
                    {getOptionValues(name).map((value) => (
                      <ButtonRadioGroupItem key={value} value={value}>
                        <FieldLogger
                          name="required_option"
                          eventParams={{ option: name, type: value }}
                        >
                          {getArticleOptionValueText(value)}
                        </FieldLogger>
                      </ButtonRadioGroupItem>
                    ))}
                  </ButtonRadioGroup>
                </div>
              </div>
              {name === ArticleOptionNameEnum.Parking &&
                option?.value === ArticleOptionValueEnum.Yes && (
                  <div className="flex flex-col gap-4">
                    <FieldLogger name="parking_spots" eventParams={{}}>
                      <FormControl
                        label="총 주차대수"
                        indicator="(필수)"
                        invalid={!!totalParkingSpots.fieldState.error}
                        errorMessage={totalParkingSpots.fieldState.error?.message}
                      >
                        <TextField
                          {...totalParkingSpots.field}
                          width="100%"
                          type="number"
                          placeholder="0"
                          suffix="대"
                          value={String(totalParkingSpots.field.value ?? '')}
                          onChange={(e) => {
                            totalParkingSpots.field.onChange(
                              e.target.value ? removeDecimal(Number(e.target.value)) : undefined
                            );
                          }}
                        />
                      </FormControl>
                    </FieldLogger>
                    <FieldLogger name="parking_spots" eventParams={{}}>
                      <FormControl
                        label="세대당 주차대수"
                        indicator="(선택)"
                        invalid={!!parkingSpots.fieldState.error}
                        errorMessage={parkingSpots.fieldState.error?.message}
                        description="세대당 가능한 주차대수를 알려주세요."
                      >
                        <TextField
                          {...parkingSpots.field}
                          width="100%"
                          type="number"
                          placeholder="0"
                          suffix="대"
                          value={String(parkingSpots.field.value ?? '')}
                          onChange={(e) => {
                            parkingSpots.field.onChange(
                              e.target.value ? Number(e.target.value) : undefined
                            );
                          }}
                        />
                      </FormControl>
                    </FieldLogger>
                  </div>
                )}
            </div>
          );
        })}
      </div>
    </Field>
  );
};

export default RequiredOptionField;
