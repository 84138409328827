import { MyHistoryArticles_ViewerWriteArticles$key } from '@/__generated__/MyHistoryArticles_ViewerWriteArticles.graphql';
import { MyHistoryArticlesQuery } from '@/__generated__/MyHistoryArticlesQuery.graphql';
import ArticleItem from '@/components/Article/ArticleItem';
import InfiniteLoading from '@/components/InfinteLoading';
import { ViewWriteArticleStatusInputEnum } from '@/types/schemaEnums';
import { Button } from '@daangn/carotene';
import { useNavigate } from '@tanstack/react-router';
import { graphql, useFragment, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import logger from '@/utils/Logger';
import ArticleEmpty from '@/components/Article/ArticleEmpty';
import ChannelListDialog from '@/components/Dialog/ChannelListDialog';
import { MyHistoryArticlesItemAction_article$key } from '@/__generated__/MyHistoryArticlesItemAction_article.graphql';

type MyHistoryArticlesProps = {
  searchKeyword?: string;
  selectedStatus: `${ViewWriteArticleStatusInputEnum}`;
};
const MyHistoryArticles = ({ searchKeyword, selectedStatus }: MyHistoryArticlesProps) => {
  const queryRef = useLazyLoadQuery<MyHistoryArticlesQuery>(
    graphql`
      query MyHistoryArticlesQuery(
        $first: Int = 20
        $after: String
        $input: ViewerWriteArticlesInput
      ) {
        ...MyHistoryArticles_ViewerWriteArticles
          @arguments(first: $first, after: $after, input: $input)
      }
    `,
    { input: { status: selectedStatus, addressKeyword: searchKeyword } },
    { fetchPolicy: 'store-and-network' }
  );

  const { data, hasNext, loadNext } = usePaginationFragment<
    MyHistoryArticlesQuery,
    MyHistoryArticles_ViewerWriteArticles$key
  >(
    graphql`
      fragment MyHistoryArticles_ViewerWriteArticles on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 20 }
        after: { type: String }
        input: { type: "ViewerWriteArticlesInput" }
      )
      @refetchable(queryName: "ViewerWriteArticlesRefetchQuery") {
        viewerWriteArticles(first: $first, after: $after, input: $input)
          @connection(key: "ViewerWriteArticles_viewerWriteArticles") {
          edges {
            node {
              id
              originalId
              viewChatChannel {
                unreadCount
              }
              ...ArticleItem_article
              ...MyHistoryArticlesItemAction_article
            }
            cursor
          }
        }
      }
    `,
    queryRef
  );

  const { edges } = data.viewerWriteArticles;
  const articles = edges.map((v) => v.node);

  if (articles.length < 1) {
    return <ArticleEmpty searchKeyword={searchKeyword} selectedStatus={selectedStatus} />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="grid w-full grid-cols-2 gap-8">
        {articles.map((article, index) => (
          <ArticleItem
            key={article.id}
            articleRef={article}
            index={index}
            showRealtyId
            action={
              <MyHistoryArticleItemAction searchKeyword={searchKeyword} articleRef={article} />
            }
          />
        ))}
      </div>

      <InfiniteLoading hasNext={hasNext} onLoadMore={() => loadNext(20)} />
    </div>
  );
};
export default MyHistoryArticles;

const MyHistoryArticleItemAction = ({
  searchKeyword,
  articleRef,
}: {
  searchKeyword?: string;
  articleRef: MyHistoryArticlesItemAction_article$key;
}) => {
  const navigate = useNavigate();

  const article = useFragment<MyHistoryArticlesItemAction_article$key>(
    graphql`
      fragment MyHistoryArticlesItemAction_article on Article {
        originalId
        viewChatChannel {
          unreadCount
        }
        ...ChannelListDialog_article
      }
    `,
    articleRef
  );

  const { originalId: articleId } = article;

  const eventParams = {
    article_id: articleId,
    search_keyword: searchKeyword,
  };

  const handleChat = () => {
    logger.track('click_article_action_chat', eventParams);
  };

  const handleModify = () => {
    logger.track('click_article_action_modify', eventParams);

    navigate({
      to: '/ceo/articles/edit/$articleId',
      params: {
        articleId: articleId,
      },
    });
  };

  return (
    <>
      <div className="mt-4 flex w-full gap-2">
        <ChannelListDialog articleRef={article} onChatClick={handleChat} />
        <Button variant="neutral" size="large" className="flex-1" onClick={handleModify}>
          수정하기
        </Button>
      </div>
    </>
  );
};
