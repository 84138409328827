import Field from '@/routes/_protected/ceo/articles/-components/Field';
import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { useController } from 'react-hook-form';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { BuildingUsageTypes, getBuildingUsageText } from '@daangn/realty-sdk';
import { Radio, RadioGroup } from '@daangn/carotene';
import { useMemo, useState } from 'react';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { getBuildingUsageOptions } from '@daangn/realty-sdk/services';

export type BuildgUsageType = (typeof BuildingUsageTypes)[number];
const BuildingUsageField = () => {
  const { control, watch } = useArticleForm();
  const buildingUsage = useController({ control, name: 'buildingUsage' });
  const [isEtcUsageOpen, setIsEtcUsageOpen] = useState(false);
  const salesType = watch('salesType');
  const buildingUsageOptions = useMemo(() => getBuildingUsageOptions({ salesType }), [salesType]);

  if (
    !isEtcUsageOpen &&
    buildingUsageOptions.etc.some((type) => type === buildingUsage.field.value)
  ) {
    setIsEtcUsageOpen(true);
  }

  return (
    <Field label="건축물 용도" {...buildingUsage.fieldState}>
      <RadioGroup
        {...buildingUsage.field}
        value={buildingUsage.field.value ?? undefined}
        onValueChange={buildingUsage.field.onChange}
      >
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-3 justify-between gap-6">
            {buildingUsageOptions.main.map((type) => (
              <FieldLogger key={type} name={`building_usage`} eventParams={{ type }}>
                <Radio value={type} label={getBuildingUsageText(type)} />
              </FieldLogger>
            ))}
            {isEtcUsageOpen &&
              buildingUsageOptions.etc.map((type) => (
                <FieldLogger key={type} name={`building_usage`} eventParams={{ type }}>
                  <Radio value={type} label={getBuildingUsageText(type)} />
                </FieldLogger>
              ))}
          </div>
          {!isEtcUsageOpen && (
            <button
              className="flex min-h-8 items-center gap-0.5"
              onClick={() => setIsEtcUsageOpen(true)}
            >
              <span className="body-small-default text-fg-neutral">
                찾고 계신 건축물 용도가 없으신가요?
              </span>
              <IconChevronRightLine size={16} />
            </button>
          )}
        </div>
      </RadioGroup>
    </Field>
  );
};

export default BuildingUsageField;
