import { Button as CaroteneButton, ProgressCircle } from '@daangn/carotene';

type ButtonProps = React.ComponentProps<typeof CaroteneButton> & {
  loading?: boolean;
};

const Button = ({ loading, children, onClick, ...props }: ButtonProps) => {
  if (loading) {
    return (
      <CaroteneButton {...props}>
        <ProgressCircle tone="staticWhite" size={24} />
      </CaroteneButton>
    );
  }

  return (
    <CaroteneButton onClick={onClick} {...props}>
      {children}
    </CaroteneButton>
  );
};

export default Button;
