import logger from '@/utils/Logger';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected/articles/edit/$articleId')({
  beforeLoad: ({ params }) => {
    logger.track('page_redirect', {
      from: window.location.href,
      to: `/ceo/articles/edit/${params.articleId}`,
    });
    throw redirect({
      to: '/ceo/articles/edit/$articleId',
      params: {
        articleId: params.articleId,
      },
    });
  },
});
