/**
 * @generated SignedSource<<fc9f70dac416c891426bddb956a71712>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type TradeTypeEnum = "BORROW" | "BUY" | "MONTH" | "SHORT" | "YEAR";
import { FragmentRefs } from "relay-runtime";
export type articleMainTrade_article$data = {
  readonly trades: ReadonlyArray<{
    readonly deposit?: number;
    readonly description?: string | null;
    readonly monthlyPay?: number;
    readonly preferred: boolean;
    readonly price?: number;
    readonly type: TradeTypeEnum;
    readonly yearlyPay?: number;
  }>;
  readonly " $fragmentType": "articleMainTrade_article";
};
export type articleMainTrade_article$key = {
  readonly " $data"?: articleMainTrade_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleMainTrade_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "articleMainTrade_article"
};

(node as any).hash = "95af14790a74dfb4392f33967b9024b5";

export default node;
