import { IS_LOCAL, STAGE, config } from '@/config';

import { init, replayIntegration } from '@sentry/react';
import logger from './utils/Logger';
import posthog from 'posthog-js';

if (!IS_LOCAL) {
  init({
    dsn: config.sentryDSN,
    integrations: [
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

logger.init();

posthog.init(config.posthogKey, {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  debug: STAGE !== 'production',
});

// 숫자 input 스크롤 disable
document.addEventListener('wheel', (e) => {
  if (e.target instanceof HTMLInputElement && e.target.type === 'number') {
    e.target.blur();
  }
});
