import { articleCostText_article$key } from '@/__generated__/articleCostText_article.graphql';
import { articleSalesTypeText_article$key } from '@/__generated__/articleSalesTypeText_article.graphql';
import { articleSummaryInfo_article$key } from '@/__generated__/articleSummaryInfo_article.graphql';
import type { EditArticleInputV3 } from '@/__generated__/editArticleMutation.graphql';
import type { CreateArticleInputV3 } from '@/__generated__/newPageMutation.graphql';
import type { useArticleForFormFragment_article$data } from '@/__generated__/useArticleForFormFragment_article.graphql';
import type { ArticleFormInputType, ArticleFormType } from '@/scheme/article';
import {
  ArticleBuildingOrientationEnum,
  ArticleManageCostOptionEnum,
  ArticleOptionNameEnum,
  ArticleOptionValueEnum,
  ArticleQualitativeItemEnum,
  BuildingUsageEnum,
  ManageCostPayOptionEnum,
  TradeTypeEnum,
} from '@/types/schemaEnums';
import {
  areaText,
  billionConvert,
  getArticleSalesTypeText,
  getFloorsText,
  getManageCostPrice,
  RequiredArticleOptionNames,
  ResidentSalesTypes,
  SupplyAreaFirstSalesTypes,
  getArticleMainTradeType,
  getTradeTypeAndPrice,
} from '@daangn/realty-sdk';
import type { DefaultValues } from 'react-hook-form';
import { graphql, readInlineData } from 'relay-runtime';
import { truthy } from './misc';
import type { articleMainTrade_article$key } from '@/__generated__/articleMainTrade_article.graphql';
import { articleTradeTypeAndPriceText_article$key } from '@/__generated__/articleTradeTypeAndPriceText_article.graphql';
import { omit } from 'lodash-es';

export const originalArticleToForm = (
  article: useArticleForFormFragment_article$data
): DefaultValues<ArticleFormInputType> => {
  return {
    ...article,
    area: article.area ?? undefined,
    supplyArea: article.supplyArea ?? undefined,
    buildingApprovalDate: article.buildingApprovalDate ?? undefined,
    bathroomCnt: article.bathroomCnt ?? undefined,
    buildingOrientation:
      (article.buildingOrientation as ArticleBuildingOrientationEnum) ?? undefined,
    roomCnt: article.roomCnt ?? undefined,
    salesType: (article.salesTypeV2?.type as any) ?? undefined,
    etcSalesType: article.salesTypeV2?.type === 'ETC' ? article.salesTypeV2?.etcType : undefined,
    topFloor: article.topFloor ?? undefined,
    manageCost: article.manageCost ?? undefined,
    content: article.content ?? undefined,
    options: article.options
      .filter((option) => !RequiredArticleOptionNames.find((name) => name === option.name))
      .map((option) => ({
        name: option.name as ArticleOptionNameEnum,
        value: option.value as ArticleOptionValueEnum,
      })),
    requiredOptions: article.options
      .filter((option) => RequiredArticleOptionNames.find((name) => name === option.name))
      .map((option) => ({
        name: option.name as ArticleOptionNameEnum,
        value: option.value as ArticleOptionValueEnum,
      })),
    trades: article.trades.map((trade) => ({
      ...trade,
      adjustable: trade.adjustable,
      description: trade.description,
      preferred: trade.preferred,
      price: trade.price ?? trade.deposit ?? 0,
      tradeType: trade.type as TradeTypeEnum,
      monthlyPay: trade.monthlyPay || trade.yearlyPay || undefined,
    })),
    qualitativeItems: article.qualitativeItems.map((item) => item as ArticleQualitativeItemEnum),
    includeManageCostOption: article.includeManageCostOptionV2.map((option) => ({
      fixedCost: option.fixedCost,
      option: option.option as ArticleManageCostOptionEnum,
      payOption: option.payOption as ManageCostPayOptionEnum,
    })),
    excludeManageCostOption: article.excludeManageCostOption.map(
      (option) => option as ArticleManageCostOptionEnum
    ),
    moveInDate: article.moveInDate ?? undefined,
    buildingUsage: article.buildingUsage as BuildingUsageEnum,
    images: article.images.map((image) => ({
      id: image.originalId,
      thumbnail: image.url,
    })),
    videos: article.videos.map((video) => ({
      id: video.originalVideoId,
      thumbnail: video.thumbnailUrl,
      filename: video.filename || '업로드된 동영상',
      bigStreamId: video.bigStreamId ?? undefined,
    })),
    isContactTargetEnabled: !!article.contactTargetPhoneNumber,
    contactTargetPhoneNumber: article.contactTargetPhoneNumber,
  };
};

export const formToArticleInput = (
  form: ArticleFormType,
  {
    isEdit,
  }: {
    isEdit: boolean;
  }
): CreateArticleInputV3 | EditArticleInputV3 => {
  const { fullAddress, trades, images, videos, isContactTargetEnabled, ...input } = omit(form, [
    'requiredOptions',
    'options',
  ]) as Omit<ArticleFormType, 'requiredOptions' | 'options'>;

  const requiredOptions = 'requiredOptions' in form ? form.requiredOptions : [];
  const options = 'options' in form ? form.options : [];

  return {
    ...input,
    writerType: 'BROKER',
    trades,
    imageIds: images?.map((image) => image.id) ?? [],
    originalVideoIds: videos?.map((video) => video.id) ?? [],
    originalBigStreamIds: videos?.map((video) => video.bigStreamId) ?? [],
    options: [...requiredOptions, ...(options || [])],
    contactTargetPhoneNumber: isContactTargetEnabled ? input.contactTargetPhoneNumber : null,
    ...(!isEdit && { fullAddress }),
  };
};

export const readArticleSummaryInfo = (
  articleRef: articleSummaryInfo_article$key,
  option?: {
    areaUnit?: '평' | 'm²';
    simple?: boolean;
    showCostText?: boolean;
  }
) => {
  const { areaUnit = 'm²', simple = false, showCostText = true } = option ?? {};

  const article = readInlineData(
    graphql`
      fragment articleSummaryInfo_article on Article @inline {
        ...articleSalesTypeText_article
        ...articleCostText_article
        salesTypeV2 {
          type
        }
        area
        supplyArea
        addressInfo
        floor
        topFloor
      }
    `,
    articleRef
  );

  const isSupplyAreaFirstType = SupplyAreaFirstSalesTypes.some(
    (v) => v === article.salesTypeV2.type
  );
  const salesTypeText = readArticleSalesTypeText(article);
  const allAreaText = [
    isSupplyAreaFirstType &&
      article.supplyArea &&
      areaText(article.supplyArea, areaUnit, { withUnit: false }),
    article.area && areaText(article.area, areaUnit, { withUnit: false }),
  ]
    .filter(truthy)
    .join('/');
  const allAreaTextWithUnit = allAreaText ? `${allAreaText}${areaUnit}` : '';

  if (simple) {
    return [salesTypeText, allAreaTextWithUnit, getFloorsText(article, { simple: true })]
      .filter(truthy)
      .join(' · ');
  }

  const costText = readArticleCostText(article);

  return [
    salesTypeText,
    allAreaTextWithUnit,
    article.addressInfo,
    getFloorsText(article, { simple: true }),
    showCostText && costText,
  ]
    .filter(truthy)
    .join(' · ');
};

const readArticleCostText = (articleRef: articleCostText_article$key) => {
  const article = readInlineData(
    graphql`
      fragment articleCostText_article on Article @inline {
        writerType
        salesTypeV2 {
          type
        }
        manageCost
        etcManageCost
        includeManageCostOptionV2 {
          option
          payOption
          fixedCost
        }
        isUnknownManageCost
        premiumMoney
      }
    `,
    articleRef
  );

  const showManageCost = ResidentSalesTypes.some((type) => type === article.salesTypeV2.type);
  const showPremiumMoney = article.premiumMoney && article.salesTypeV2.type === 'STORE';

  if (showManageCost) {
    const priceText = article.isUnknownManageCost
      ? '확인 필요'
      : !article.manageCost
        ? '없음'
        : billionConvert(getManageCostPrice(article), { withUnit: true });

    return ['관리비', priceText].join(' ');
  }

  if (showPremiumMoney) {
    return `권리금 ${billionConvert(article.premiumMoney, { withUnit: true })}`;
  }

  return '';
};

export const readArticleSalesTypeText = (
  articleRef: articleSalesTypeText_article$key,
  option?: {
    subText?: boolean;
  }
) => {
  const { subText = true } = option ?? {};

  const article = readInlineData(
    graphql`
      fragment articleSalesTypeText_article on Article @inline {
        salesTypeV2 {
          type
          ... on EtcSalesType {
            etcType
          }
        }
      }
    `,
    articleRef
  );

  const showEtcTypeText = article.salesTypeV2.type === 'ETC' && article.salesTypeV2.etcType;
  const salesTypeText = getArticleSalesTypeText(article.salesTypeV2.type, {
    simple: true,
    subText,
    etcText: showEtcTypeText ? article.salesTypeV2.etcType : undefined,
  });

  return salesTypeText;
};

export const readArticleMainTrade = (articleRef: articleMainTrade_article$key) => {
  const article = readInlineData<articleMainTrade_article$key>(
    graphql`
      fragment articleMainTrade_article on Article @inline {
        trades {
          type
          preferred
          ... on ShortTrade {
            deposit
            description
            monthlyPay
          }
          ... on MonthTrade {
            deposit
            description
            monthlyPay
          }
          ... on YearTrade {
            deposit
            description
            yearlyPay
          }
          ... on BorrowTrade {
            deposit
            description
          }
          ... on BuyTrade {
            price
          }
        }
      }
    `,
    articleRef
  );

  const mainTradeType = getArticleMainTradeType(article.trades.map((trade) => trade.type));
  const mainTrade = article.trades.find((trade) => trade.type === mainTradeType)!;
  const otherTrades = article.trades.filter((trade) => trade.type !== mainTradeType);

  return { mainTrade, otherTrades };
};

export const readArticleTradeTypeAndPriceText = (
  articleRef: articleTradeTypeAndPriceText_article$key
) => {
  const article = readInlineData<articleTradeTypeAndPriceText_article$key>(
    graphql`
      fragment articleTradeTypeAndPriceText_article on Article @inline {
        ...articleMainTrade_article
      }
    `,
    articleRef
  );

  const { mainTrade } = readArticleMainTrade(article);

  return getTradeTypeAndPrice(
    mainTrade.type,
    mainTrade?.deposit || mainTrade?.price || 0,
    mainTrade?.monthlyPay || mainTrade?.yearlyPay || 0
  );
};
