import { SalesHistoryStatus } from '@/components/Sales/ChipList';

const Label: Record<SalesHistoryStatus, string> = {
  TRADED: '거래 완료된',
  ON_SALES: '판매 중인',
  HIDDEN: '숨긴',
};
const ArticleEmpty = ({
  searchKeyword,
  selectedStatus,
}: {
  searchKeyword?: string;
  selectedStatus: SalesHistoryStatus;
}) => {
  if (searchKeyword) {
    return (
      <div className="flex flex-col gap-3 text-center">
        <p className="body-medium-default">
          앗! <b>‘{searchKeyword}’</b>
          <br />
          검색 결과가 없어요.
        </p>
        <p className="body-small-default text-fg-neutralSubtle">
          도로명 + 건물번호, 지번 + 건물번호는 띄어쓰기 해주세요.
        </p>
      </div>
    );
  }

  return (
    <div className="text-gray600 h-full w-full p-32 text-center">
      <div>{Label[selectedStatus]} 게시글이 없어요.</div>
    </div>
  );
};

export default ArticleEmpty;
