import { useArticleForm } from '@/routes/_protected/ceo/articles/-contexts';
import { FieldError, useController } from 'react-hook-form';
import { unionBy } from 'lodash-es';
import { ArticleManageCostOptionEnum, ManageCostPayOptionEnum } from '@/types/schemaEnums';
import { ManageCostOptionText } from '@daangn/realty-sdk';
import Field from '@/routes/_protected/ceo/articles/-components/Field';
import { ButtonRadioGroup, ButtonRadioGroupItem } from '@/components/base/ButtonRadio';
import FieldLogger from '@/routes/_protected/ceo/articles/-components/FieldLogger';
import { FormControl, TextField } from '@daangn/carotene';

const ManageCostPayOptionText = {
  NEED_TO_CHECK: '확인 필요',
  USED: '쓴 만큼',
  FIXED: '고정 금액',
  NONE: '없음',
} satisfies Record<ManageCostPayOptionEnum, string>;

const IncludeManageCostOptionField = () => {
  const { control, watch } = useArticleForm();

  const includeManageCostOption = useController({
    control,
    name: 'includeManageCostOption',
  });
  const isUnknownManageCost = watch('isUnknownManageCost');

  const handleChangePayOption = (
    manageCost: ArticleManageCostOptionEnum,
    payOption: ManageCostPayOptionEnum
  ) => {
    includeManageCostOption.field.onChange(
      unionBy([{ option: manageCost, payOption }], watch('includeManageCostOption') ?? [], 'option')
    );
  };

  const handleChangeFixedCost = (manageCost: ArticleManageCostOptionEnum, fixedCost: string) => {
    includeManageCostOption.field.onChange(
      unionBy(
        [
          {
            option: manageCost,
            payOption: ManageCostPayOptionEnum.Fixed,
            fixedCost: !fixedCost ? undefined : Number(fixedCost),
          },
        ],
        watch('includeManageCostOption') ?? [],
        'option'
      )
    );
  };

  return (
    <Field label="관리비 항목" {...includeManageCostOption.fieldState}>
      <div>
        {Object.values(ArticleManageCostOptionEnum).map((option) => {
          const field = includeManageCostOption.field.value?.find((v) => v.option === option);
          const fieldError = (
            includeManageCostOption.fieldState.error as FieldError & {
              [key: string]: { [key: string]: FieldError };
            }
          )?.[option];

          return (
            <div key={option} className="py-5">
              <div className="flex flex-col gap-3">
                <div className="flex w-full gap-10">
                  <div className="body-medium-default">{ManageCostOptionText[option]}</div>
                  <div className="spacer" />
                  <ButtonRadioGroup
                    name={includeManageCostOption.field.name}
                    value={field?.payOption ?? ''}
                    onValueChange={(value) => {
                      handleChangePayOption(option, value as ManageCostPayOptionEnum);
                    }}
                    disabled={!!isUnknownManageCost}
                  >
                    {[
                      ManageCostPayOptionEnum.None,
                      ManageCostPayOptionEnum.NeedToCheck,
                      ManageCostPayOptionEnum.Used,
                      ManageCostPayOptionEnum.Fixed,
                    ].map((payOption) => (
                      <ButtonRadioGroupItem key={payOption} value={payOption}>
                        <FieldLogger
                          name="include_manage_cost_pay_option"
                          eventParams={{
                            option,
                            payOption,
                          }}
                        >
                          {ManageCostPayOptionText[payOption]}
                        </FieldLogger>
                      </ButtonRadioGroupItem>
                    ))}
                  </ButtonRadioGroup>
                </div>
                {field?.payOption === ManageCostPayOptionEnum.Fixed && (
                  <FieldLogger
                    name={`include_manage_cost_option_fixed_cost`}
                    eventParams={{
                      option,
                    }}
                  >
                    <FormControl
                      invalid={!!fieldError?.fixedCost}
                      errorMessage={fieldError?.fixedCost?.message}
                    >
                      <TextField
                        width="100%"
                        name={`${includeManageCostOption.field.name}.${option}.fixedCost`}
                        type="number"
                        value={String(field.fixedCost ?? '')}
                        onChange={(e) => handleChangeFixedCost(option, e.target.value)}
                        suffix="만원"
                        placeholder="0"
                      />
                    </FormControl>
                  </FieldLogger>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Field>
  );
};

export default IncludeManageCostOptionField;
