/**
 * @generated SignedSource<<097ef7bc3f2b132726699ad59c2cde14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SetIrosMaintenanceFinishAlarmRequestType = "CREATE_ARTICLE" | "OWNER_VERIFY";
export type SetIrosMaintenanceFinishAlarmInput = {
  articleId?: string | null;
  requestType?: SetIrosMaintenanceFinishAlarmRequestType | null;
};
export type CheckIros_IrosAlarmMutation$variables = {
  input: SetIrosMaintenanceFinishAlarmInput;
};
export type CheckIros_IrosAlarmMutation$data = {
  readonly setIrosMaintenanceFinishAlarm: {
    readonly __typename: "SetIrosMaintenanceFinishAlarmOutput_Result";
    readonly result: boolean;
  } | {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CheckIros_IrosAlarmMutation = {
  response: CheckIros_IrosAlarmMutation$data;
  variables: CheckIros_IrosAlarmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "setIrosMaintenanceFinishAlarm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "result",
            "storageKey": null
          }
        ],
        "type": "SetIrosMaintenanceFinishAlarmOutput_Result",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToastErrorPayload",
            "kind": "LinkedField",
            "name": "toastError",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ToastError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckIros_IrosAlarmMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckIros_IrosAlarmMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3337225844dac4cef21ff0bc6d7728b3",
    "id": null,
    "metadata": {},
    "name": "CheckIros_IrosAlarmMutation",
    "operationKind": "mutation",
    "text": "mutation CheckIros_IrosAlarmMutation(\n  $input: SetIrosMaintenanceFinishAlarmInput!\n) {\n  setIrosMaintenanceFinishAlarm(input: $input) {\n    __typename\n    ... on SetIrosMaintenanceFinishAlarmOutput_Result {\n      result\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84f5c2ed9de313ca2c68e3caa3c357ac";

export default node;
