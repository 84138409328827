/**
 * @generated SignedSource<<a126dd1ce9fa8aecfcc52a414ccdeb34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SendRequestForArticleVerificationInput = {
  articleId: string;
  phoneNumber: string;
};
export type RequestVerifyDialogMutation$variables = {
  input: SendRequestForArticleVerificationInput;
};
export type RequestVerifyDialogMutation$data = {
  readonly sendRequestForArticleVerification: {
    readonly __typename: "SendRequestForArticleVerificationOutput_Result";
    readonly result: {
      readonly __typename: "SendRequestForArticleVerificationOutput_ResultPayload";
      readonly isAfterFailedResponse: boolean;
    };
  } | {
    readonly __typename: "ToastError";
    readonly toastError: {
      readonly message: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type RequestVerifyDialogMutation = {
  response: RequestVerifyDialogMutation$data;
  variables: RequestVerifyDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "sendRequestForArticleVerification",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SendRequestForArticleVerificationOutput_ResultPayload",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAfterFailedResponse",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "SendRequestForArticleVerificationOutput_Result",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ToastErrorPayload",
            "kind": "LinkedField",
            "name": "toastError",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ToastError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestVerifyDialogMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestVerifyDialogMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "499512b57c3ab521896a290e5664462d",
    "id": null,
    "metadata": {},
    "name": "RequestVerifyDialogMutation",
    "operationKind": "mutation",
    "text": "mutation RequestVerifyDialogMutation(\n  $input: SendRequestForArticleVerificationInput!\n) {\n  sendRequestForArticleVerification(input: $input) {\n    __typename\n    ... on SendRequestForArticleVerificationOutput_Result {\n      result {\n        __typename\n        isAfterFailedResponse\n      }\n    }\n    ... on ToastError {\n      toastError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7263fadd915aca1ad6e448731f17f9a";

export default node;
