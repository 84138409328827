import logger from '@/utils/Logger';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected/sales/biz')({
  beforeLoad: () => {
    logger.track('page_redirect', { from: window.location.href, to: '/ceo/sales' });
    throw redirect({
      to: '/ceo/sales',
    });
  },
});
